import React from 'react';
import { useParams } from 'react-router-dom';
import { useArticle } from '../hooks/useArticles';
import { Box, Chip, CircularProgress, Typography } from '@mui/material';
import { ArticleCardsCol, } from './ArticleCards';
import CTASection from './CTASection';
import { ArrowRight } from './Icons';
import { Link } from 'react-router-dom';

const ArticleDetail = () => {
  const { id } = useParams(); // Récupère l'ID de l'article depuis l'URL
  const { article, loading, error } = useArticle(id); // Utilise le hook pour récupérer les données de l'article

  if (loading) return <Box textAlign="center"><CircularProgress /></Box>;
  if (error) return <Typography color="error" textAlign="center">{error}</Typography>;

  return (
    <Box className='flex col hcenter'  sx={{ padding:'7.5vw 5vw'}}>

        <div className='flex row wrap hcenter gap4' style={{maxWidth:'1400px', padding:'0 0 10vw 0'}}>
            <div className='flex col hstart vcenter gap4' style={{maxWidth:'500px'}}>
                <h2 style={{fontSize:'36px'}}>{article.titre}</h2>
                <div>
                    <Box sx={{ mb: 2 }}>
                        {article.tags.split(',').map((tag, index) => (
                        <Chip className='bs-2' key={index} label={tag.trim()} sx={{ mr: 1, mb: 1 }} color="primary"/>
                        ))}
                    </Box>
                    <p style={{textAlign:'justify', fontSize:'14px'}}>{article.abstract}</p>
                </div>
            </div>
            <div className='bg-2 bs-3 br-l' style={{
                width:'30vw',
                minWidth:'300px',
                height:'30vw',
                backgroundImage:`url(${article.imageCouverture})`
            }}/>

        </div>



        <div className='flex row wrap hcenter gap10' >
            <Box
                sx={{
                    maxWidth:'500px',
                    lineHeight: 1.8,
                    '& p': { mb: 2 },
                    '& h2': { mt: 4, mb: 2 },
                    '& h3': { mt: 3, mb: 1.5 },
                    '& li': { mt: 1.5, mb: 1.5, mr:0, ml:2 },
                    '& img': { maxWidth: '100%', height: 'auto', mb: 2 }
                }}
                dangerouslySetInnerHTML={{ __html: article.contenu }}
            />
            <div style={{maxWidth:'300px'}}>
                <div className='flex row hsb  gap1 ' style={{width: '100%', maxWidth:'1000px'}}>
                    <h3>Dernières actualités</h3>
                    {/* <a href="/blog#all-articles" className='fnt-1 flex row hcenter vcenter gap1' >
                        Toutes nos actualités <ArrowRight fill='#007bff'/>
                    </a> */}
                    <Link to="/blog#all-articles" className='fnt-1 flex row hcenter vcenter gap1' >
                        Toutes nos actualités <ArrowRight fill='#007bff'/>
                    </Link>
                </div>
                <div>
                    <ArticleCardsCol currentArticleId={article.id} />
                </div>
            </div>
        </div>


        <div style={{marginTop:'100px'}}>
            <CTASection/>
        </div>
    </Box>
    );
};

export default ArticleDetail;
