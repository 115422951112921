

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
// import { ContactIcon } from './Icons';
import { UserIcon } from './Icons';
// import UserLogo  from '../icons/user1.png';
// import './Navbar.scss';

const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDarkBackground, setIsDarkBackground] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleScroll = () => {
    // const scrollPosition = window.scrollY;
    // setIsDarkBackground(scrollPosition > 400); // Change this value as needed
    const navbarHeight = document.querySelector('nav').offsetHeight;
    const sections = document.querySelectorAll('section');
    let darkBackground = false;

    sections.forEach(section => {
      const rect = section.getBoundingClientRect();
      const isAboveNavbar = rect.top <= navbarHeight && rect.bottom >= 0;
      if (isAboveNavbar && section.classList.contains('dark-section')) {
        darkBackground = true;
      }
    });
    setIsDarkBackground(darkBackground);
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    
    <header className='navbar'>
      <Link className={`brand name ${isDarkBackground ? 'dark-mode' : 'light-mode'}`} to="/"><div>ALVY</div></Link>
      <nav className={`menu ${isOpen ? 'open' : ''} ${isDarkBackground ? 'dark-mode' : 'light-mode'}`}>
        <ul>
          <li><Link to="/services" onClick={toggleMenu}>Services</Link></li>
          <li><Link to="/blog" onClick={toggleMenu}>Missions</Link></li>
          <li><Link to="/quote-form" onClick={toggleMenu}>Estimation</Link></li>
          <li><Link to="/about" onClick={toggleMenu}>Nous</Link></li>
          <li><Link to="/commitments" onClick={toggleMenu}>Engagements</Link></li>
          <li><Link to="/contact-form" onClick={toggleMenu}>Contact</Link></li>
          <li className='notshow'><Link to="/login" onClick={toggleMenu}>Se connecter</Link></li>
          {/* <li style={{paddingTop:'5px'}}><ContactIcon fill="#000000" onClick={toggleMenu} href="/contact-form"/>
               
          </li> */}

          
          {/* <li><Link to="/careers" onClick={toggleMenu}>Carrière</Link></li> */}
          {/* <li>
            <Link to="/client" onClick={toggleMenu}>
            <img className="icon" src={UserLogo} alt="" />
            </Link>
          </li> */}
        </ul>
      </nav>
      <Link className={`brand contact  ${isDarkBackground ? 'dark-mode' : 'light-mode'}`} to="/login">
        <div className='flex col hcenter vcenter'>
          <UserIcon fill={isDarkBackground ? "#ffffff" : "#000000"}/>
        </div>
      </Link>

      <div className={`toggle ${isDarkBackground ? 'dark-mode' : 'light-mode'}`} onClick={toggleMenu}>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isOpen ? 'open' : ''}`}></div>
      </div>
    </header>
  );
};

export default NavBar;

