import React, { useState, useEffect } from 'react';
import { useArticles } from '../hooks/useArticles';
import { Link } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel, Box, Chip, Grid, Typography } from '@mui/material';


const ArticlesList = () => {
  const { articles, loading, error } = useArticles();
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');

  useEffect(() => {
    if (articles.length > 0) {
      // Trier les articles par datePublication (du plus récent au plus ancien)
      const sortedArticles = [...articles].sort((a, b) => {
        return new Date(b.datePublication) - new Date(a.datePublication);
      });
      // Filtrer les articles par tag sélectionné
      if (selectedTag) {
        setFilteredArticles(
          sortedArticles.filter(article =>
            article.tags.split(',').map(tag => tag.trim()).includes(selectedTag)
          )
        );
      } else {
        setFilteredArticles(sortedArticles);
      }
    }
  }, [articles, selectedTag]);

  const handleTagChange = (e) => {
    setSelectedTag(e.target.value);
  };

  if (loading) return <p>Chargement des articles...</p>;
  if (error) return <p>Erreur : {error}</p>;

  // Extraire tous les tags uniques pour le filtre
  const allTags = Array.from(new Set(articles.flatMap(article => article.tags.split(',').map(tag => tag.trim()))));

  return (
    <div className="flex col gap2" style={{padding: '40px 0', maxWidth:"1000px"}}>
      <Box className='flex row wrap hsb gap1' sx={{ mb: 3 }}>
        <h3>Toutes nos actualités</h3>
        <FormControl fullWidth sx={{maxWidth:'300px'}}>
          <InputLabel id="tag-filter-label">Filtrer par tag</InputLabel>
          <Select
            labelId="tag-filter-label"
            id="tag-filter"
            value={selectedTag}
            label="Filtrer par tag"
            onChange={handleTagChange}
          >
            <MenuItem value="">
              <em>Tous les tags</em>
            </MenuItem>
            {allTags.map((tag, index) => (
              <MenuItem key={index} value={tag}>
                {tag}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box> 

      <div className='flex row wrap gap2 hcenter scrollbar-1' style={{maxHeight:'1000px', overflow:'scroll', padding:'10px 20px'}}>
        {filteredArticles.map((article) => (
            <Grid item xs={12} sm={4} key={article.id}>
                <Box className='bs-3'
                    component={Link}
                    to={`/articles/${article.id}`}
                    sx={{
                        display: 'block',
                        height: '100%',
                        maxWidth:'300px',
                        textDecoration: 'none',
                        color: 'inherit',
                        borderRadius: '8px',
                        padding: '10px 20px'


                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            position: 'relative',

                            borderRadius: '8px',
                            '&:hover': {
                                transform: 'scale(1.02)',
                                transition: 'transform 0.3s ease-in-out',
                            },
                        }}
                    >
                        <Box
                            className='flex col vsb gap1'
                            sx={{
                                color: 'black',
                                height: '100%',
                                width: '100%', 
                                p: 2,
                                borderRadius: '0 0 8px 8px',

                            }}
                        >

                            <Typography variant="h6" component="h3" sx={{color:'black'}}>
                                {article.titre}
                            </Typography>
                            <p style={{color:'GrayText', overflow: 'hidden', textOverflow:'ellipsis',}}>
                                {article.abstract}
                            </p>
                            <Box className='flex row wrap gap1' sx={{ mt: 1 }} >
                                {article.tags.split(',').map((tag, index) => (
                                    <div key={index} 
                                    style={{backgroundColor:'gray', padding:'3px 6px', borderRadius:'30px', color:'#fff', fontSize:'8px'}}
                                    >{tag}</div>
                                ))}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            ))}
        </div>
    </div>
  );
};

export default ArticlesList;
