import apiClient from './apiClient';

const ARTICLES_ENDPOINT = '/articles';

export const fetchAllArticles = async () => {
  try {
    return await apiClient.get(ARTICLES_ENDPOINT);
  } catch (error) {
    console.error('Erreur lors de la récupération des articles:', error);
    throw error;
  }
};

export const fetchArticleById = async (id) => {
  try {
    return await apiClient.get(`${ARTICLES_ENDPOINT}/${id}`);
  } catch (error) {
    console.error(`Erreur lors de la récupération de l'article avec ID ${id}:`, error);
    throw error;
  }
};

export default {
  fetchAllArticles,
  fetchArticleById,
};
