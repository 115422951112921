import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Autocomplete } from '@mui/material';
import { post, get } from '../../services/apiClient';

function MissionCreator({ open, onClose, mission, onMissionUpdated }) {
  const [clientIDs, setClientIDs] = useState([]);
  const [quoteIDs, setQuoteIDs] = useState([]);
  const [planningIDs, setPlanningIDs] = useState([]);
  const [representativeEmails, setRepresentativeEmails] = useState([]);
  const [agentEmails, setAgentEmails] = useState([]);
  const [managerEmails, setManagerEmails] = useState([]);

  const [missionData, setMissionData] = useState({
    MissionID: mission?.MissionID || `M${Date.now().toString().slice(-6)}`,
    CreatedAt: mission?.CreatedAt || new Date().toISOString(),
    ClientID: mission?.ClientID || '',
    Representatives: mission?.Representatives || [],
    QuoteID: mission?.QuoteID || '',
    PlanningID: mission?.PlanningID || '',
    Address: mission?.Address || '',
    Comments: mission?.Comments || '',
    Agents: mission?.Agents || [],
    Managers: mission?.Managers || []
  });

  useEffect(() => {
    fetchClientIDs();
    fetchQuoteIDs();
    fetchPlanningIDs();
    fetchRepresentativeEmails();
    fetchAgentEmails();
    fetchManagerEmails();
  }, []);

  const fetchRepresentativeEmails = async () => {
    try {
      const response = await get('/admin/users');
      setRepresentativeEmails(response.filter(user => user.Role === 'Client').map(user => user.Email));
    } catch (error) {
      console.error('Erreur lors de la récupération des emails:', error);
    }
  };

  const fetchAgentEmails = async () => {
    try {
      const response = await get('/admin/users');
      setAgentEmails(response.filter(user => user.Role === 'Agent').map(user => user.Email));
    } catch (error) {
      console.error('Erreur lors de la récupération des emails:', error);
    }
  };

  const fetchManagerEmails = async () => {
    try {
      const response = await get('/admin/users');
      setManagerEmails(response.filter(user => user.Role === 'Manager').map(user => user.Email));
    } catch (error) {
      console.error('Erreur lors de la récupération des emails:', error);
    }
  };

  const fetchClientIDs = async () => {
    try {
      const response = await get('/admin/clients');
      setClientIDs(response.map(client => client.ClientID));
    } catch (error) {
      console.error('Erreur lors de la récupération des clients:', error);
    }
  };

  const fetchQuoteIDs = async () => {
    try {
      const response = await get('/admin/quotes');
      setQuoteIDs(response.map(quote => quote.QuoteID));
    } catch (error) {
      console.error('Erreur lors de la récupération des devis:', error);
    }
  };

  const fetchPlanningIDs = async () => {
    try {
      const response = await get('/admin/plannings');
      setPlanningIDs(response.map(planning => planning.PlanningID));
    } catch (error) {
      console.error('Erreur lors de la récupération des plannings :', error);
    }
  };

  // Handle input change in the form
  const handleChange = (e) => {
    const { name, value } = e.target;
    setMissionData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle multi-selection inputs for Representatives, Agents, and Managers
  const handleRepresentativesChange = (e, value) => {
    setMissionData(prevState => ({
      ...prevState,
      Representatives: value
    }));
  };

  const handleAgentsChange = (e, value) => {
    setMissionData(prevState => ({
      ...prevState,
      Agents: value
    }));
  };

  const handleManagersChange = (e, value) => {
    setMissionData(prevState => ({
      ...prevState,
      Managers: value
    }));
  };

  const handleSubmit = async () => {
    const endpoint = mission ? '/admin/update-mission' : '/admin/create-mission';
    try {
      await post(endpoint, missionData);
      onMissionUpdated();
      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement de la mission:', error);
      alert('Erreur lors de l\'enregistrement de la mission');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{mission ? 'Edit Mission' : 'Create Mission'}</DialogTitle>
      <DialogContent>

        <Autocomplete
          options={clientIDs}
          value={missionData.ClientID}
          onChange={(e, value) => setMissionData(prevState => ({ ...prevState, ClientID: value }))}
          renderInput={(params) => <TextField {...params} label="ClientID" margin="dense" size="small" />}
        />

        <Autocomplete
          options={quoteIDs}
          value={missionData.QuoteID}
          onChange={(e, value) => setMissionData(prevState => ({ ...prevState, QuoteID: value }))}
          renderInput={(params) => <TextField {...params} label="QuoteID" margin="dense" size="small" />}
        />

        <Autocomplete
          options={planningIDs}
          value={missionData.PlanningID}
          onChange={(e, value) => setMissionData(prevState => ({ ...prevState, PlanningID: value }))}
          renderInput={(params) => <TextField {...params} label="PlanningID" margin="dense" size="small" />}
        />

        <TextField
          margin="dense"
          label="Address"
          name="Address"
          fullWidth
          value={missionData.Address}
          onChange={handleChange}
          size="small"
        />

        <TextField
          margin="dense"
          label="Comments"
          name="Comments"
          fullWidth
          multiline
          rows={4}
          value={missionData.Comments}
          onChange={handleChange}
          size="small"
        />

        <Autocomplete
          multiple
          options={representativeEmails}
          value={missionData.Representatives}
          onChange={handleRepresentativesChange}
          renderInput={(params) => <TextField {...params} label="Representatives" margin="dense" size="small" />}
        />

        <Autocomplete
          multiple
          options={agentEmails}
          value={missionData.Agents}
          onChange={handleAgentsChange}
          renderInput={(params) => <TextField {...params} label="Agents" margin="dense" size="small" />}
        />

        <Autocomplete
          multiple
          options={managerEmails}
          value={missionData.Managers}
          onChange={handleManagersChange}
          renderInput={(params) => <TextField {...params} label="Managers" margin="dense" size="small" />}
        />

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {mission ? 'Update Mission' : 'Create Mission'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default MissionCreator;
