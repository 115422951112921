import React, { useState } from 'react';
import { AddCommentIcon } from './Icons';

const initialServicesData = [
  {
    id: 1,
    zone: "Open Space",
    category: "Nettoyage des Sols",
    service: "Aspiration des moquettes et tapis",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 2,
    zone: "Open Space",
    category: "Nettoyage des Sols",
    service: "Lavage des sols durs",
    frequency: "Hebdomadaire",
    lastExecuted: "2024-07-18",
    nextExecution: "2024-07-25",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 3,
    zone: "Bureaux Individuels",
    category: "Dépoussiérage",
    service: "Surfaces de bureaux",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "Faire les nouve ...",
    status: "Planifié",
    selected: true
  },
  {
    id: 4,
    zone: "Bureaux Individuels",
    category: "Nettoyage des Vitres",
    service: "Lavage des vitres intérieures",
    frequency: "Mensuelle",
    lastExecuted: "2024-07-01",
    nextExecution: "2024-08-01",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 5,
    zone: "Salles de Réunion",
    category: "Nettoyage des Sols",
    service: "Aspiration des moquettes et tapis",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 6,
    zone: "Salles de Réunion",
    category: "Nettoyage des Sanitaires",
    service: "Désinfection des toilettes",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 7,
    zone: "Couloirs",
    category: "Nettoyage des Sols",
    service: "Lavage des sols durs",
    frequency: "Hebdomadaire",
    lastExecuted: "2024-07-19",
    nextExecution: "2024-07-26",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 8,
    zone: "Sanitaires",
    category: "Nettoyage des Sanitaires",
    service: "Désinfection des toilettes",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 9,
    zone: "Sanitaires",
    category: "Nettoyage des Sanitaires",
    service: "Remplacement des consommables",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 10,
    zone: "Cafétéria",
    category: "Nettoyage des Sols",
    service: "Lavage des sols",
    frequency: "Quotidienne",
    lastExecuted: "2024-07-20",
    nextExecution: "2024-07-21",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 11,
    zone: "Cafétéria",
    category: "Nettoyage des Équipements",
    service: "Nettoyage des appareils électroménagers",
    frequency: "Hebdomadaire",
    lastExecuted: "2024-07-18",
    nextExecution: "2024-07-25",
    comments: "",
    status: "Planifié",
    selected: true
  },
  {
    id: 12,
    zone: "Salle Serveurs",
    category: "Dépoussiérage",
    service: "Dépoussiérage des équipements",
    frequency: "Hebdomadaire",
    lastExecuted: "2024-07-18",
    nextExecution: "2024-07-25",
    comments: "",
    status: "Planifié",
    selected: true
  },
];

const CleaningServicesTable = () => {
  const [servicesData, setServicesData] = useState(initialServicesData);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const handleCheckboxChange = (id) => {
    const updatedData = servicesData.map(service =>
      service.id === id ? { ...service, selected: !service.selected } : service
    );
    setServicesData(updatedData);
  };

  const handleInputChange = (id, field, value) => {
    const updatedData = servicesData.map(service =>
      service.id === id ? { ...service, [field]: value } : service
    );
    setServicesData(updatedData);
  };

  const handleSubmitComment = (id) => {
    const service = servicesData.find(service => service.id === id);
    alert(`Commentaire pour ${service.service} soumis : ${service.comments}`);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    const sortedData = [...servicesData].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setServicesData(sortedData);
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      if (sortConfig.direction === 'ascending') {
        return '↑';
      } else {
        return '↓';
      }
    }
    return '↕';
  };

  return (
    <div className="data-table bs-3" 
    style={{ maxHeight: '450px', width:"fit-content", overflow: 'hidden', fontSize:"0.7rem"}}>
      <table>
        <thead>
          <tr>
            <th></th>
            <th onClick={() => handleSort('zone')}>Zone {getSortIcon('zone')}</th>
            <th onClick={() => handleSort('category')}>Catégorie {getSortIcon('category')}</th>
            <th onClick={() => handleSort('service')}>Services {getSortIcon('service')}</th>
            <th onClick={() => handleSort('frequency')}>Fréquence {getSortIcon('frequency')}</th>
            <th onClick={() => handleSort('lastExecuted')}>Dernière {getSortIcon('lastExecuted')}</th>
            <th onClick={() => handleSort('nextExecution')}>Prochaine {getSortIcon('nextExecution')}</th>
            <th>Commentaire</th>
          </tr>
        </thead>
        <tbody>
          {servicesData.map((service) => (
            <tr key={service.id}>
              <td>
                <input
                  type="checkbox"
                  checked={service.selected || false}
                  onChange={() => handleCheckboxChange(service.id)}
                />
              </td>
              <td>{service.zone}</td>
              <td>{service.category}</td>
              <td>{service.service}</td>
              <td>{service.frequency}</td>
              <td>{service.lastExecuted}</td>
              <td>
                <input
                  type="date"
                  value={service.nextExecution}
                  onChange={(e) => handleInputChange(service.id, 'nextExecution', e.target.value)}
                />
              </td>
              <td>
                <div className="comment-section">
                  <input
                    type="text"
                    value={service.comments}
                    onChange={(e) => handleInputChange(service.id, 'comments', e.target.value)}
                  />
                  <AddCommentIcon fill="#007bff" onClick={() => handleSubmitComment(service.id)}/>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CleaningServicesTable;
