import React from "react";
import MissionsTable from "../../tables/MissionsTable";

const Operations = () => {
    return (
      <div className='flex col gap4' style={{padding:'10px 10px'}}>
        <h2>Operations </h2>


        <div className="flex col gap2 " style={{margin:'auto'}}>
          <MissionsTable/>
        </div>


          
      </div>
    );
  };
  
  export default Operations;