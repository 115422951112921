import React, { useEffect, useState } from 'react';
import { get } from '../../services/apiClient';

// MUI imports
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, TableSortLabel, IconButton, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Icone pour copier le CustomerID

const CustomerTable = () => {
  const [customers, setCustomers] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('FirstName');
  const [filter, setFilter] = useState('');

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await get('/admin/customers'); // Appel à l'API pour récupérer les clients
        setCustomers(response);
      } catch (error) {
        console.error('Erreur lors de la récupération des clients:', error);
      }
    };
    fetchClients();
  }, []);

  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
  };

  const handleCopy = (customerId) => {
    navigator.clipboard.writeText(customerId);
  };

  const filteredCustomers = customers.filter((customer) =>
    customer.FirstName.toLowerCase().includes(filter.toLowerCase()) ||
    customer.LastName.toLowerCase().includes(filter.toLowerCase()) ||
    customer.Email.toLowerCase().includes(filter.toLowerCase()) ||
    customer.Phone.includes(filter) ||
    customer.CustomerID.includes(filter)
  );

  const sortedCustomers = filteredCustomers.sort((a, b) => {
    if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
    if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
    return 0;
  });

  return (
    <div className='flex col'>
      <Typography variant="h4" gutterBottom>
        Prospects
      </Typography>

      <TextField
        label="Filtrer par ID, nom, email ou téléphone"
        variant="outlined"
        fullWidth
        margin="normal"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />

      {/* Limite la hauteur du tableau à 400px, rend les entêtes fixes, et change la couleur de fond */}
      <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto', backgroundColor: '#f5f5f5' }}> {/* Change background color here */}
        <Table stickyHeader size="small" sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}> {/* Background color for header */}
                CustomerID
              </TableCell>
              <TableCell  sx={{ backgroundColor: 'gray', color: '#fff' }}>
                <TableSortLabel
                  active={sortColumn === 'FirstName'}
                  direction={sortColumn === 'FirstName' ? sortOrder : 'asc'}
                  onClick={() => handleSort('FirstName')}
                >
                  First Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>
                <TableSortLabel
                  active={sortColumn === 'LastName'}
                  direction={sortColumn === 'LastName' ? sortOrder : 'asc'}
                  onClick={() => handleSort('LastName')}
                >
                  Last Name
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>
                <TableSortLabel
                  active={sortColumn === 'Email'}
                  direction={sortColumn === 'Email' ? sortOrder : 'asc'}
                  onClick={() => handleSort('Email')}
                >
                  Email
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>
                <TableSortLabel
                  active={sortColumn === 'Phone'}
                  direction={sortColumn === 'Phone' ? sortOrder : 'asc'}
                  onClick={() => handleSort('Phone')}
                >
                  Phone
                </TableSortLabel>
              </TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>Address</TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>Company</TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>Job Position</TableCell>
              <TableCell sx={{ backgroundColor: 'gray', color: '#fff' }}>Contact Consent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedCustomers.map((customer) => (
              <TableRow key={customer.CustomerID} sx={{ backgroundColor: '#fff' }}> {/* Background color for body rows */}
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => handleCopy(customer.CustomerID)}
                    aria-label="copy"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell>{customer.FirstName}</TableCell>
                <TableCell>{customer.LastName}</TableCell>
                <TableCell>{customer.Email}</TableCell>
                <TableCell>{customer.Phone}</TableCell>
                <TableCell>{customer.Address}</TableCell>
                <TableCell>{customer.Company}</TableCell>
                <TableCell>{customer.JobPosition}</TableCell>
                <TableCell>{customer.ContactConsent ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CustomerTable;
