const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000';
// const API_BASE_URL = 'https://api.alvy-services.com';

const apiClient = async (endpoint, { method = 'GET', headers = {}, body } = {}) => {
  const config = {
    method,
    headers: {
      'Content-Type': 'application/json',
      ...headers,
    },
    credentials: 'include', // Permet d'envoyer automatiquement les cookies avec chaque requête
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, config);

    if (!response.ok) {
      let errorMessage = 'Network error';
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (err) {
        console.error('Error parsing JSON response:', err);
      }
      throw new Error(errorMessage);
    }

    return response.json(); // Assumes valid JSON response
  } catch (error) {
    console.error(`API call failed: ${error.message}`);
    throw error;
  }
};

export const get = (endpoint) => apiClient(endpoint, { method: 'GET' });
export const post = (endpoint, body) => apiClient(endpoint, { method: 'POST', body });

export default { get, post };
