import React, { useState } from 'react';
import { TextField, Box, Grid, Paper, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { submitCustomerForm } from '../../services/quoteService';

function ContactForm({ formData, onClose }) {
  const [contactFormData, setContactFormData] = useState({
    LastName: '',
    FirstName: '',
    JobPosition: '',
    Company: '',
    Address: '',
    Phone: '',
    Email: '',
    Message: '',
    ContactConsent: true,
    DataConsent: false,
  });

  const [contactFormErrors, setContactFormErrors] = useState({});

  const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    setContactFormData((prevData) => ({
      ...prevData,
      [e.target.name]: e.target.checked,
    }));
  };

  const validateContactForm = () => {
    const errors = {};
    if (!contactFormData.LastName) errors.LastName = true;
    if (!contactFormData.FirstName) errors.FirstName = true;
    if (!contactFormData.Company) errors.Company = true;
    if (!contactFormData.Address) errors.Address = true;
    if (!contactFormData.JobPosition) errors.JobPosition = true;
    if (!contactFormData.Phone) errors.Phone = true;
    if (!contactFormData.Email) errors.Email = true;
    if (!contactFormData.DataConsent) errors.DataConsent = true;

    setContactFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateContactForm()) {
      const finalData = { 
        ...contactFormData, 
        Sector: formData.sector,
        AreaSize: formData.areaSize,
        RoomBreakdown: formData.roomBreakdown,
        RequestedServices: formData.results,
        HoursMonthly: formData.HoursMonthly,
        HoursWeekly: formData.HoursWeekly,
        ConsumableCosts: formData.ConsumableCosts,
        FixedCosts: formData.FixedCosts,
        PriceMonthly: formData.PriceMonthly,
        PriceHourly: formData.PriceHourly
      };

      try {
        await submitCustomerForm(finalData);
        alert('Votre formulaire a bien été soumis !');
        // onClose();
      } catch (error) {
        console.error('Erreur lors de la soumission du formulaire:', error);
        alert('Erreur lors de la soumission. Veuillez réessayer.');
      }
    }
  };

  return (
    <Paper elevation={3} sx={{ padding: 4, margin: 'auto', maxWidth: 600, backgroundColor: '#efefef' }}>
      <Box component="form" noValidate sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          <h5>Votre demande de prestations concerne :</h5>
          <Grid item xs={12}>
            <TextField
              label="Raison sociale de l'entreprise"
              name="Company"
              value={contactFormData.Company}
              onChange={handleContactFormChange}
              error={contactFormErrors.Company}
              helperText={contactFormErrors.Company && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Adresse des locaux concernés"
              name="Address"
              value={contactFormData.Address}
              onChange={handleContactFormChange}
              error={contactFormErrors.Address}
              helperText={contactFormErrors.Address && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>

          <h5 style={{ width: '100%', marginTop: '20px' }}>Représenté par :</h5>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Nom"
              name="LastName"
              value={contactFormData.LastName}
              onChange={handleContactFormChange}
              error={contactFormErrors.LastName}
              helperText={contactFormErrors.LastName && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Prénom"
              name="FirstName"
              value={contactFormData.FirstName}
              onChange={handleContactFormChange}
              error={contactFormErrors.FirstName}
              helperText={contactFormErrors.FirstName && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Fonction"
              name="JobPosition"
              value={contactFormData.JobPosition}
              onChange={handleContactFormChange}
              error={contactFormErrors.JobPosition}
              helperText={contactFormErrors.JobPosition && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="N° de téléphone"
              name="Phone"
              value={contactFormData.Phone}
              onChange={handleContactFormChange}
              error={contactFormErrors.Phone}
              helperText={contactFormErrors.Phone && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Adresse Email"
              name="Email"
              type="email"
              value={contactFormData.Email}
              onChange={handleContactFormChange}
              error={contactFormErrors.Email}
              helperText={contactFormErrors.Email && "Ce champ est obligatoire"}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Message (optionnel)"
              name="Message"
              value={contactFormData.Message}
              onChange={handleContactFormChange}
              multiline
              rows={4}
              fullWidth
            />
          </Grid>
        </Grid>

        <FormControlLabel
          control={
            <Checkbox
              checked={contactFormData.ContactConsent}
              onChange={handleCheckboxChange}
              name="ContactConsent"
            />
          }
          label="Être recontacté"
          sx={{ mt: 2 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={contactFormData.DataConsent}
              onChange={handleCheckboxChange}
              name="DataConsent"
            />
          }
          label={
            <div>
              J'accepte que mes données soient collectées et utilisées conformément à la{' '}
              <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                politique de confidentialité
              </a>. *
            </div>
          }
          sx={{ color: contactFormErrors.DataConsent ? 'red' : 'inherit' }}
        />
        {contactFormErrors.DataConsent && (
          <Typography variant="body2" color="error">
            Vous devez accepter la collecte des données.
          </Typography>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
          <button type="button" className="btn-1 tertiary all" onClick={onClose} style={{ fontSize: '0.8rem' }}>
            Revenir
          </button>
          <button type="submit" className="btn-1 primary all" onClick={handleSubmit} style={{ fontSize: '0.8rem' }}>
            Soumettre
          </button>
        </Box>
      </Box>
    </Paper>
  );
}

export default ContactForm;
