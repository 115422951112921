import React from 'react';
import CustomerTable from '../../tables/CustomerTable';
import QuoteTable from '../../tables/QuoteTable';
import ConversationTable from '../../tables/ConversationTable';


const AllData = () => {
  return (
    <div className='flex col gap4 bs-3 br-l' style={{padding:'20px 30px'}}>
        <CustomerTable/>
        <QuoteTable/>
        <ConversationTable/>
    </div>
  );
};

export default AllData;