// /src/services/sectorService.js
import apiClient from './apiClient';

export const getSectors = async () => {
    return await apiClient.get('/sectors');
};

export const getSectorNames = async () => {
    return await apiClient.get('/sectors/list');
};

export const getSectorByName = async (sectorName) => {
    return await apiClient.get(`/sectors/${encodeURIComponent(sectorName)}`);
};
