import CloudsVideo from "../videos/clouds.mp4";
import { InView } from '../components/Utils';

import esgImg1 from '../images/esg-img1.jpg';
import CTASection from "../components/CTASection";
const About = () => {
    // Alvy description                                 OK
    // Alvy pinciples                                   OK
    // Alvy Values (Listen, Respect, Audacity)          OK
    // Alvy RSE => commitment page                      OK
    // Alvy Team => 
    // Chez Alvy  => (inclusion et diversité, formation continue) => commitment page,  carrière page

    return (
        <div style={{overflow:'hidden'}}>
            <div className="hero" style={{height: '350px'}}>
                <div className="video-container">
                    <video autoPlay loop muted playsInline className="video-bg"
                    style={{  position: 'absolute', top: '-200px', left: '0'}}>
                        <source src={CloudsVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    <div className="video-content">
                        <h2 style={{color: '#ffffff', paddingTop: '100px'}}>Au service de nos environnements</h2>
                        {/* <p>Ceci est une description ou autre contenu que vous souhaitez afficher.</p> */}
                    </div>
                </div>
            </div>

            <div className="company" style={{paddingBottom:'100px'}}>
                <div className="flex row wrap-reverse hcenter gap6">
                    <div className="flex col hcenter gap2" style={{paddingTop:'0'}}>
                        <h1>Alvy</h1>
                        {/* <a className='btn-1 primary' href="">Notre identité</a> */}
                    </div>
                    <div className="flex col gap2 bs-1 br-m" 
                    style={{padding:'30px 40px', maxWidth: '600px',backgroundColor:'#efefef', 
                    textAlign:'justify', color:'GrayText', position:'relative', top:'-100px'}}>
                        <p>
                            Chez Alvy, nous voyons la propreté non seulement comme un besoin fonctionnel, mais comme un facteur 
                            clé de productivité et de bien-être. Nous proposons des services de nettoyage professionnel, 
                            réguliers ou ponctuels, adaptés à divers secteurs, des bureaux aux locaux industriels. Que ce soit 
                            pour un nettoyage quotidien, une intervention après travaux ou un service événementiel, Alvy est 
                            là pour vous.
                        </p>
                        <p>
                            Notre approche repose sur l'alignement des intérêts de tous. Comme le voilier trouve sa force dans 
                            l'équilibre de son centre vélique, nous croyons que l'efficacité durable naît de la transparence et 
                            de l'harmonie dans nos relations, tant avec nos clients qu'avec notre équipe. 
                        </p>
                        <p>
                            Plaçant l'innovation au cœur de notre démarche, nous cherchons constamment à réinventer nos processus, 
                            qu'il s'agisse de la relation client, des outils de management ou des contrôles de qualité. Cette 
                            capacité à innover est notre promesse : celle de vous offrir un service de nettoyage qui allie fiabilité, 
                            efficacité, et transparence.
                        </p>
                        <p>
                            Choisissez Alvy et découvrez une nouvelle approche du nettoyage professionnel, où chaque intervention 
                            pave la voie à une collaboration durable et fructueuse.
                        </p>
                    </div>
                </div>
            </div>

            <div className="team flex col hcenter gap5" style={{textAlign:'justify', padding: '0px 5vw 100px 5vw'}}>
                
                <div className="flex row nowrap">
                    <InView className='slide-left flex col gap2' style={{maxWidth:'450px'}}>
                        <div>
                            <div style={{color: 'GrayText', fontSize:'1.25rem'}}>01</div>
                            <h5 style={{fontSize:'1.75rem'}}>Notre Mission</h5>
                        </div>
                        <p style={{padding: '0 20px', color: 'GrayText', borderLeft:'2px solid #007bff'}}>
                            Faciliter le quotidien en apportant des solutions concrètes pour créer des espaces toujours plus sains,
                            plus sûrs, et plus fiables, où la sérénité et l’efficacité cohabitent au service de vos activités.
                        </p>
                    </InView>
                    <div style={{width:'20vw'}}></div>
                </div>


                <div className="flex row nowrap">
                    <div style={{width:'20vw'}}></div>
                    <InView className='slide-right flex col gap2' style={{maxWidth:'450px'}}>
                        <div>
                            <div style={{color: 'GrayText', fontSize:'1.25rem'}}>02</div>
                            <h5 style={{fontSize:'1.75rem'}}>Notre Vision</h5>
                        </div>
                        <p style={{padding: '0 20px', color: 'GrayText', borderLeft:'2px solid #007bff'}}>
                            Transformer les relations professionnelles en une collaboration fluide et authentique, où la simplicité 
                            et la confiance deviennent les fondations d'un monde de travail plus harmonieux et durable.
                        </p>
                    </InView>
                </div>

                <div className="flex row nowrap">
                    <InView className='slide-left flex col gap2' style={{maxWidth:'450px'}}>
                        <div>
                            <div style={{color: 'GrayText', fontSize:'1.25rem'}}>03</div>
                            <h5 style={{fontSize:'1.75rem'}}>Notre Ambition</h5>
                        </div>
                        <p style={{padding: '0 20px', color: 'GrayText', borderLeft:'2px solid #007bff'}}>
                            Améliorer la qualité de vie en devenant une entreprise de référence, celle qui permet à chacun 
                            de ses collaborateurs de créer de la valeur tangible pour chaque client, et de contribuer à un 
                            impact positif sur la société dans son ensemble.
                        </p>
                    </InView>
                    <div style={{width:'20vw'}}></div>
                </div>
            </div>
            
            


            <div id="values" className='flex row hsb vcenter' style={{marginBottom:'200px', padding:'100px 0'}}>
                <div style={{width:'10%',height: '1000px'}}>
                    <div className="video-container bs-inset" style={{borderRadius: ' 0 30px 30px 0  '}}>
                        <video autoPlay loop muted playsInline className="video-bg"
                        style={{  position: 'relative', top: '0px', left: '0'}}>
                            <source src={CloudsVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
                <div className="flex col gap4" style={{maxWidth: '800px',  padding:'30px 40px'}}>
                    <h2>Nos Valeurs</h2>
                    <InView className="fade-in flex col gap1 br-l bs-1" style={{textAlign:'justify', position:'relative', left:'-0',
                        backgroundColor:'#efefef', padding:'30px 40px'}}>
                        <h4>Ecoute</h4>
                        <p style={{color:'GrayText'}}>
                            L'écoute est le fil conducteur qui nous relie à notre valeur centrale : la proximité. 
                            En prêtant attention aux marchés, aux tendances et aux voix de nos parties prenantes, 
                            nous nous donnons les moyens de nous réinventer en permanence, pour anticiper et répondre 
                            aux évolutions de la société et aux attentes de nos clients et collaborateurs.
                        </p>
                    </InView>
                    <InView className="fade-in flex col gap1 br-l bs-1" style={{textAlign:'justify', position:'relative', left:'-0',
                        backgroundColor:'#efefef', padding:'30px 40px'}}>
                        <h4>Respect</h4>
                        <p style={{color:'GrayText'}}>
                            Le respect est la pierre angulaire de toute relation durable. Il incarne notre engagement 
                            envers l'individu, les règles, et les promesses que nous faisons. Ce respect crée un environnement 
                            serein où chacun peut s'épanouir et laisser s'exprimer pleinement son potentiel.
                        </p>
                    </InView>
                    <InView className="fade-in flex col gap1 br-l bs-1" style={{textAlign:'justify', position:'relative', left:'-0',
                        backgroundColor:'#efefef', padding:'30px 40px'}}>
                        <h4>Audace</h4>
                        <p style={{color:'GrayText'}}>
                            L’audace est l’étincelle qui alimente le progrès. Cet état d’esprit nous pousse à oser, 
                            entreprendre, et toujours rechercher des solutions meilleures. Nous encourageons nos équipes 
                            à croire en leur savoir-faire et à explorer sans cesse de nouvelles voies pour innover.
                        </p>
                    </InView>
                </div>
                <div style={{width:'10%',height: '1000px'}}>
                    <div className="video-container bs-inset" style={{borderRadius: '  30px 0 0 30px   '}}>
                        <video autoPlay loop muted playsInline className="video-bg"
                        style={{  position: 'relative', top: '0px', left: '0'}}>
                            <source src={CloudsVideo} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>
            </div>
            <div className="commitments flex col hstart" style={{marginBottom:'200px'}}>
                
                <div className="flex row wrap hcenter" style={{width:'100%'}}>
                    <h2 style={{paddingRight:'10px'}}>Engagé <br />pour notre<br /> avenir</h2>

                    <div className='bg-2 bs-inset' style={{width:'30vw', height:'60vh', backgroundImage: `url(${esgImg1})`}}>
                        <div style={{backgroundColor:'#000000', width: '100%', height:'100%', opacity:'0.25'}}></div>
                    </div>

                    <div className="flex col vsb " style={{padding:'50px 40px'}}>
                        <p className='br-l' style={{maxWidth: '500px', textAlign:'justify', position:'relative', left:'-10vw',
                            backgroundColor:'#efefef', padding:'30px 40px'}}>
                            Profondément engagés dans une démarche de Responsabilité Sociétale des Entreprises (RSE), nous plaçons 
                            le respect de l’environnement, l’éthique et le bien-être de nos collaborateurs au cœur de notre activité. 
                            Chaque action que nous menons vise à créer un impact positif durable, tant pour nos clients que pour la 
                            société dans son ensemble.
                        </p>
                        <a className='btn-1 primary' href="/commitments">Nos engagements</a>
                    </div>
                </div>

            </div>
            {/* <div className="with-us flex col hcenter gap4" style={{marginBottom:'200px'}}>
                <div>
                    <h2>Au service de l'humain</h2>
                </div>
                <div className="flex col gap2" style={{maxWidth: '700px'}}>
                    <p>
                        Nous croyons que chaque personne mérite de grandir dans un environnement bienveillant où son potentiel 
                        est reconnu et développé. La valorisation de nos collaborateur passe par l'écoute, le respect et le 
                        soutien tout au long de leur parcour. 
                    </p>
                    <p>
                        Notre approche vise à profiter non pas seulement à nos client et partenaires mais représente avant 
                        tout un engagement envers nos collaborateurs, pour que chacun puisse s'accomplir et contribuer à des 
                        relations de qualité avec l'ensemble de nos parties prenantes. 
                    </p>
                    <div className="flex col hcenter" style={{marginTop:'50px'}}>
                        <a className='btn-1 primary' href="">Notre rejoindre</a>
                    </div>
                </div>
            </div> */}
            <CTASection/>
        </div>
    )

}

export default About;