// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import NavBar from './components/NavBar';
// // import About from './components/About';
// // import Services from './components/Services';
// // import Contact from './components/Contact';
// // import Blog from './components/Blog';

// function App() {
//   return (
//     <Router>
//       <NavBar /> 
//       <Routes>
//         {/* <Route path="/" exact component={() => <h2>Home Page</h2>} /> */}
//         {/* <Route path="/about" component={About} />
//         <Route path="/services" component={Services} />
//         <Route path="/contact" component={Contact} />
//         <Route path="/blog" component={Blog} /> */}
//       </Routes>
//     </Router>
//   );
// }

// export default App;


import React, { useEffect, useState } from 'react';
import { 
  BrowserRouter as Router, Route, Routes, useLocation, Navigate  
} from 'react-router-dom';

import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/About';
import Blog from './pages/Blog';
import QuoteForm from './components/quoteForm/QuoteForm';
import ContactForm from './components/ContactForm';
import ArticleDetail from './components/ArticleDetail';
import Commitments from './pages/Commitments';
import LegalNotice from './pages/LegalNotice';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CookiePolicy from './pages/CookiePolicy';
import CookieConsentBanner from './components/CookieConsentBanner';
import Login from './components/Login';  // Ajout de l'importation pour la page de connexion admin
// import { getUserRole, isTokenExpired } from './services/authService';
// import { decodeTokenFromCookies } from './services/authService'; // Import de la fonction



// import AgentDashboard from './components/users/AgentDashboard';
// import ClientDashboard from './components/users/ClientDashboard';
import PrivateRoute from './components/users/PrivateRoute';
import Dashboard from './pages/Dashboard';

import AdminDashboard from './components/users/AdminDashboard';
import AdminHome from './components/users/pages/AdminHome';
import Clients from './components/users/pages/Clients';
import Operations from './components/users/pages/Operations';
import AllData from './components/users/pages/AllData';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

// const PrivateRoute = ({ children }) => {
//   if (isTokenExpired()) {
//     console.log('isTokenExpired', isTokenExpired);
//     return <Navigate to="/user/login" />;
//   }
//   return children;
// };

function App() {
  // const [userRole, setUserRole] = useState(null); // État pour le rôle de l'utilisateur
  // const [isAuthenticated, setIsAuthenticated] = useState(false); // État pour l'authentification

  // useEffect(() => {
  //   // Vérification s'il y a un token présent
  //   const decodedToken = decodeTokenFromCookies();

  //   if (decodedToken && decodedToken.role) {
  //     setUserRole(decodedToken.role); // Mettre à jour le rôle si le token existe
  //     setIsAuthenticated(true); // Mettre à jour l'état d'authentification
  //   } else {
  //     setIsAuthenticated(false); // L'utilisateur n'est pas connecté
  //   }
  // }, []);

  // Logique de rendu en fonction de l'état d'authentification
  // if (!isAuthenticated) {
  //   return <div>Veuillez vous connecter</div>; // Affiche une demande de connexion si l'utilisateur n'est pas connecté
  // }

  return (
    <Router>
      <NavBar />
      <ScrollToTop /> 
      <CookieConsentBanner/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/about" element={<About />} />
        <Route path="/quote-form" element={<QuoteForm />} /> 
        <Route path="/contact-form" element={<ContactForm />} /> 

        {/* <Route path="/about/identity" element={<Identity />} /> */}

        {/* Phase 2 : */}
        {/* <Route path="/about/governance" element={<Governance />} /> */}
        {/* <Route path="/about/history" element={<History />} /> */}
        {/* <Route path="/about/strategy-and-figures" element={<StrategyAndFigures />} /> */}
        <Route path="/blog" element={<Blog />} />
        <Route path="/articles/:id" element={<ArticleDetail />} /> 
        <Route path="/commitments" element={<Commitments />} />
        <Route path="/legal-notices" element={<LegalNotice />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />



        <Route path="/login" element={<Login />} />
        
        {/* Route protégée pour les espaces privés */}
        <Route path="/dashboard" element={<PrivateRoute allowedRoles={['Admin','Client','Agent']} />}>
          <Route path="" element={<Dashboard />} />
        </Route>


        {/* Routes pour les rôles spécifiques */}
        {/* <Route path="/admin/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<AdminDashboard />} />
        </Route> */}

        {/* Routes pour les Admins */}
        <Route path="/admin/dashboard" element={<PrivateRoute allowedRoles={['Admin']} />}>
          <Route path="" element={<AdminDashboard />}>
            <Route path="home" element={<AdminHome />} />
            <Route path="clients" element={<Clients />} />
            <Route path="operations" element={<Operations />} />
            <Route path="all-data" element={<AllData />} />
            {/* <Route path="clients" element={<Clients />} />
            <Route path="agents" element={<Agents />} /> */}
          </Route>
        </Route>





        {/* <Route path="/agent/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<AgentDashboard />} />
        </Route>

        <Route path="/client/dashboard" element={<PrivateRoute />}>
          <Route path="" element={<ClientDashboard />} />
        </Route> */}




      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;

