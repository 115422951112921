import { InView } from "../components/Utils"
import CTASection from "../components/CTASection"
import CommitmentImg from '../images/commitments-1.jpg';

const Commitments = () => {
    return (
        <div style={{margin:'200px 0', padding:'0 20px'}}>
            <div className="bg-2 " 
            style={{backgroundImage: `url(${CommitmentImg})`, width:'100%', height:'600px', position:'absolute', top:'0', left:'0', zIndex:'-1'
            }}>
                <div style={{width:'100%', height:'100%', backgroundColor:'#000', opacity:'0.5'}}>

                    
                </div>
            </div>
            <section className="flex col hcenter">

                <div className='flex col gap4' style={{maxWidth: '600px'}}>
                    <h1 style={{fontSize:'42px', lineHeight:'64px', color:'#fff'}}>Notre Engagement, <br /> Pour Votre Confiance</h1>
                    <p className="br-m bs-1" 
                    style={{padding:'40px 60px',margin:'50px 0', lineHeight:'2rem', textAlign:'justify', fontSize:'16px',
                    color:'GrayText', backgroundColor:'#efefef'}}>
                        Pour nous, la réussite se mesure en la qualité 
                        des relations que nous tissons avec nos clients, nos collaborateurs, et la société dans son ensemble. 
                        Notre engagement englobe une responsabilité envers 
                        toutes les parties prenantes avec lesquelles nous interagissons. Pour mériter votre confiance, nous nous 
                        engageons à agir avec transparence, respect et intégrité.
                    </p>
                </div>

            </section>


            <section className="flex col gap2 hcenter" style={{marginBlock:'100px'}}>
                <div className="flex col gap6 hcenter" style={{maxWidth:'1000px', borderTop:'1px solid lightgray', paddingTop:'50px'}}>
                    <h2 style={{width:'100%'}}>Pour nos collaborateurs</h2>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square transparent"></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Bien-être et Développement </h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Nous considérons nos collaborateurs comme notre plus grand atout. Nous offrons des 
                            formations régulières, des programmes de mentorat et des opportunités de développement 
                            de carrière pour que chaque employé puisse progresser. Nous avons mis en place un 
                            programme de formation annuel couvrant à la fois des compétences techniques et des 
                            compétences en gestion, pour préparer nos équipes à relever les défis futurs.
                        </p>
                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Respect et Ecoute </h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Le respect et l'écoute sont au cœur de notre culture d'entreprise. Nous organisons 
                            des réunions régulières avec nos équipes pour discuter de leurs idées, de leurs besoins 
                            et de leurs préoccupations. Un sondage trimestriel est également réalisé pour mesurer 
                            la satisfaction de nos employés et identifier les domaines où nous pouvons nous améliorer.
                        </p>
                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square "></div>
                            <h3 style={{marginLeft:"2rem"}}>Sécurité au travail </h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            La sécurité de nos collaborateurs est primordiale. Nous mettons en place 
                            des protocoles stricts de sécurité, avec des formations régulières sur les meilleures pratiques 
                            et l'utilisation d'équipements de protection individuelle. Par exemple, chaque nouveau collaborateur 
                            suit une formation de sécurité obligatoire avant de commencer toute mission, et des inspections 
                            de sécurité sont effectuées chaque mois sur tous nos sites.
                        </p>
                    </div>
                </div>
            </section>

            <section className="flex col gap2 hcenter" style={{marginBlock:'100px'}}>
                <div className="flex col gap6 hcenter" style={{maxWidth:'1000px', borderTop:'1px solid lightgray', paddingTop:'50px'}}>
                    <h2 style={{width:'100%'}}>Pour la société</h2>
                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square transparent"></div>
                            <div class="square transparent"></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Responsabilité environnemental</h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Nous nous engageons à réduire notre empreinte environnementale à chaque étape de nos opérations. 
                            Nous privilégions l'utilisation de produits de nettoyage écologiques certifiés, limitons la 
                            consommation d'eau grâce à des technologies de nettoyage innovantes, et mettons en place des 
                            pratiques strictes de gestion des déchets. Nous avons introduit un programme de 
                            réduction des plastiques à usage unique, visant à éliminer ces matériaux de nos opérations d'ici 
                            deux ans. Notre objectif est de devenir une entreprise neutre en carbone d'ici 2035, en investissant 
                            dans des initiatives de compensation et de réduction des émissions.
                        </p>
                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square transparent"></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Lutte Contre la Discrimination </h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Nous sommes fermement engagé dans la lutte contre toutes les formes de discrimination. Nous croyons 
                            en l'égalité des chances et nous veillons à ce que chaque individu soit traité avec respect et dignité. 
                            Nous avons mis en place une politique d'aucune tolérence envers toute forme de discrimination, et nous 
                            maintenons des discuscion pour promouvoir la diversité et l'inclusion au sein de l'entreprise.
                        </p>

                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square "></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Egalité Femme Homme</h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            L'égalité entre les femmes et les hommes est un de nos principe fondamental. Nous nous engageons 
                            à assurer une égalité de traitement dans toutes nos pratiques de gestion des ressources humaines, 
                            y compris dans le recrutement, les salaires, les promotions et les conditions de travail.
                        </p>
                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square "></div>
                            <div class="square "></div>
                            <h3 style={{marginLeft:"2rem"}}>Inclusion Sociale</h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Nous nous engageons à offrir des opportunités d'emploi et de développement à ceux qui en ont le plus 
                            besoin. Nous travaillons activement pour intégrer des personnes issues de milieux défavorisés.
                        </p>

                    </div>
                </div>
            </section>

            <section className="flex col gap2 hcenter">
                <div className="flex col gap6 hcenter" style={{maxWidth:'1000px', borderTop:'1px solid lightgray', paddingTop:'50px'}}>
                    <h2 style={{width:'100%'}}>Pour nos clients</h2>
                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square transparent"></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Excellence Opérationnelle</h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Nous nous engageons à fournir des services aux entreprises de la 
                            plus haute qualité, adaptés à vos besoins spécifiques. Nos équipes reçoivent une formation continue, 
                            et nous investissons dans les dernières technologies pour améliorer l'efficacité et la qualité de nos 
                            prestations. Par exemple, nous utilisons des systèmes de suivi en temps réel pour garantir que chaque 
                            intervention est exécutée selon les standards les plus stricts.
                        </p>
                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square transparent"></div>
                            <h3 style={{marginLeft:"2rem"}}>Transparence et Communication</h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                        La transparence est la clé d'une collaboration réussie. Nous mettons en 
                        place des rapports réguliers et un accès en ligne pour vous permettre de suivre l'avancement de nos 
                        services. Par exemple, chaque client d'Alvy reçoit un rapport mensuel détaillant les interventions 
                        réalisées, les observations et les recommandations pour améliorer encore la qualité du service.
                        </p>

                    </div>

                    <div className="flex col gap3"  style={{padding:'5px 15px', maxWidth:'600px' }}>

                        <InView className='slide-left flex vcenter row'>
                            <div class="square"></div>
                            <div class="square "></div>
                            <div class="square "></div>
                            <h3 style={{marginLeft:"2rem"}}>Réactivité et Flexibilité</h3>
                        </InView>

                        <p style={{ textAlign:'justify', color:'GrayText'}}>
                            Votre satisfaction est notre priorité. Nous nous engageons à être réactifs et 
                            flexibles, prêts à ajuster nos services en fonction de vos besoins. Par exemple, si vous avez besoin d'une 
                            intervention urgente en dehors des horaires habituels, notre équipe est disponible 24/7 pour répondre à vos 
                            demandes avec efficacité.
                        </p>
                    </div>
                </div>
            </section>

            <CTASection/>
        </div>       
    )
}

export default Commitments