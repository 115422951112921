import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import apiClient from '../../services/apiClient';

const PrivateRoute = ({ allowedRoles }) => {
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Appel au backend pour récupérer les informations de l'utilisateur connecté
    const fetchUserRole = async () => {
      try {
        const response = await apiClient.get('/user/profile'); // L'API renverra le rôle
        setRole(response.user.role); // Stocke le rôle
        setLoading(false);
      } catch (error) {
        console.error('Erreur lors de la récupération du rôle', error);
        setLoading(false);
      }
    };

    fetchUserRole();
  }, []);

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (!role) {
    return <Navigate to="/login" />; // Si pas connecté, redirige vers la page de connexion
  }

  if (!allowedRoles.includes(role)) {
    return <Navigate to="/login" />;
  }

  return <Outlet context={{ role }} />; // Passe le rôle au composant enfant
};

export default PrivateRoute;
