import React, { useEffect, useState } from 'react';
import { get } from '../../services/apiClient';

// MUI imports
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Typography, Grid, IconButton
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Icone pour copier le CustomerID

const ConversationTable = () => {
  const [conversations, setConversations] = useState([]);
  const [filterCustomerID, setFilterCustomerID] = useState('');
  const [filterDate, setFilterDate] = useState('');
  const [filterMessage, setFilterMessage] = useState('');

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await get('/admin/conversations'); // Appel à l'API pour récupérer les conversations
        setConversations(response);
      } catch (error) {
        console.error('Erreur lors de la récupération des conversations:', error);
      }
    };
    fetchConversations();
  }, []);

  const handleCopy = (customerId) => {
    navigator.clipboard.writeText(customerId);
  };

  // Filtrer les conversations en fonction des critères de recherche
  const filteredConversations = conversations
    .filter((conversation) => {
      const conversationDate = new Date(Number(conversation.Date)).toLocaleDateString('fr-FR'); // Conversion du timestamp en date lisible
      return (
        (conversation.CustomerID && conversation.CustomerID.toLowerCase().includes(filterCustomerID.toLowerCase())) &&
        conversationDate.includes(filterDate) && // Comparer la date formatée avec le filtre de date
        (conversation.Message && conversation.Message.toLowerCase().includes(filterMessage.toLowerCase()))
      );
    })
    .sort((a, b) => Number(b.Date) - Number(a.Date)); // Trier les conversations de la plus récente à la plus ancienne

  return (
    <div className='flex col'>
      <Typography variant="h4" gutterBottom>
        Conversations
      </Typography>

      {/* Champs de filtre organisés en ligne */}
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <Grid item xs={4}>
          <TextField
            label="Filtrer par CustomerID"
            variant="outlined"
            fullWidth
            value={filterCustomerID}
            onChange={(e) => setFilterCustomerID(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Filtrer par Date (jj/mm/aaaa)"
            variant="outlined"
            fullWidth
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Filtrer par Message"
            variant="outlined"
            fullWidth
            value={filterMessage}
            onChange={(e) => setFilterMessage(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* Tableau des conversations */}
      <TableContainer component={Paper} sx={{ maxHeight: 400, overflow: 'auto' }}>
        <Table stickyHeader size="small"> {/* stickyHeader pour les entêtes fixes, size="small" pour réduire la hauteur */}
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '150px', resize: 'horizontal', overflow: 'auto', backgroundColor: 'gray', color: '#fff' }}>CustomerID</TableCell>
              <TableCell style={{ width: '200px', resize: 'horizontal', overflow: 'auto', backgroundColor: 'gray', color: '#fff' }}>Date</TableCell>
              <TableCell style={{ width: '400px', resize: 'horizontal', overflow: 'auto', backgroundColor: 'gray', color: '#fff' }}>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredConversations.map((conversation) => (
              <TableRow key={conversation.CustomerID}>
                <TableCell>
                  <IconButton
                    size="small"
                    onClick={() => handleCopy(conversation.CustomerID)}
                    aria-label="copy"
                  >
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                {/* Conversion de la date en jj/mm/aaaa hh:mm:ss */}
                <TableCell>
                  {new Date(Number(conversation.Date)).toLocaleString('fr-FR', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                  })}
                </TableCell>
                <TableCell>{conversation.Message}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ConversationTable;
