import apiClient from './apiClient';


export const submitContactForm = async (formData) => {
    // return apiClient.post('/customers/from-quote', {
    //     method: 'POST',
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify(formData),  
    // });
    try {
        const response = await apiClient.post('/customers/from-contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData),  
        });
        return response;
    } catch (error) {
        throw new Error(`Erreur lors de la soumission du formulaire: ${error.message}`);
    }
};