import { useState } from 'react';
import { login } from '../services/loginService';
import { useNavigate } from 'react-router-dom';

// MUI imports
import { TextField, Button, Box, Alert } from '@mui/material';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await login(email, password); // Appel à la fonction login

      // Redirection après la connexion réussie
      navigate('/dashboard');
    } catch (err) {
      console.error('Erreur lors de la connexion:', err);
      setError('Échec de la connexion');
    }
  };

  return (
    <div className='flex col hcenter' style={{padding:'7.5vw 5vw'}}>
      <div className='flex col br-m bs-3' style={{maxWidth: '450px', padding:'30px 40px'}}>
        <div className='flex col gap2'>
          <h3>Nous collaborons déjà ensemble ?</h3>
          <p>Connectez-vous pour retrouver toutes vos informations et outils.</p>
        </div>
        <Box component="form" onSubmit={handleLogin} sx={{ mt: 3 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Adresse Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Se connecter
          </Button>
        </Box>
      </div>
    </div>
  );
};

export default Login;
