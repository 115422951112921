import { useState, useEffect } from 'react';
import { getSectors, getSectorNames, getSectorByName } from '../services/sectorService';

// Hook pour récupérer la liste des secteurs
export const useSectorNames = () => {
    const [sectorNames, setSectorNames] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSectorNames = async () => {
            try {
                const data = await getSectorNames();
                setSectorNames(data);
            } catch (err) {
                setError('Erreur lors de la récupération des noms des secteurs.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchSectorNames();
    }, []);

    return { sectorNames, loading, error };
};

// Hook pour récupérer la table complète des secteurs
export const useSectors = () => {
    const [sectors, setSectors] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSectors = async () => {
            try {
                const data = await getSectors();
                setSectors(data);
            } catch (err) {
                setError('Erreur lors de la récupération des secteurs.');
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchSectors();
    }, []);

    return { sectors, loading, error };
};

// Hook pour récupérer un secteur spécifique
export const useSectorByName = (sectorName) => {
    const [sector, setSector] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSector = async () => {
            if (!sectorName) {
                setError('Nom du secteur non défini');
                setLoading(false);
                return;
            }

            setLoading(true); // Démarre le chargement

            try {
                console.log("Fetching sector:", sectorName); // Vérifiez le nom du secteur
                const data = await getSectorByName(sectorName);
                console.log("API Response:", data); // Log de la réponse de l'API
                setSector(data);
            } catch (err) {
                setError(`Erreur lors de la récupération du secteur ${sectorName}.`);
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchSector();
    }, [sectorName]);

    return { sector, loading, error };
};