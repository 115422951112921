import React, { useState, useEffect } from 'react';
import { 
  Box, TextField, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  CircularProgress 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useSectorByName } from '../../hooks/useSectors';
// import { Loader } from '../Utils';
import { InfoToolTip } from '../Utils';

function SecondPage({ formData, onBack, onNext }) {
  const [roomBreakdown, setRoomBreakdown] = useState(formData.roomBreakdown || []);
  const [totalArea, setTotalArea] = useState(parseFloat(formData.totalArea) || parseFloat(formData.areaSize) || 0);
  const [openDialog, setOpenDialog] = useState(false);
  const [newRoom, setNewRoom] = useState({ roomType: '', size: '' });
  const [addIndex, setAddIndex] = useState(null);

  // Utilisation du hook pour récupérer les détails du secteur
  const selectedSector = formData.sector;
  const { sector, loading, error } = useSectorByName(selectedSector);
  

  useEffect(() => {
    if (selectedSector && formData.areaSize && sector ) {
      // Retirer "SectorName" et obtenir "sectorDetails"
      const { SectorName, ...sectorDetails } = sector;
      // console.log('***sectorDetails:', sectorDetails); // Ajoutez ce log pour vérifier les détails du secteur

      // Mappez les détails du secteur pour créer un breakdown des pièces
      const breakdown = Object.entries(sectorDetails).map(([roomType, proportion]) => ({
        roomType,
        size: proportion * formData.areaSize
      }));
      
      setRoomBreakdown(breakdown);
      const total = breakdown.reduce((acc, room) => acc + room.size, 0);
      setTotalArea(total);
    }
  }, [sector, selectedSector, formData.areaSize]);

  const handleRoomSizeChange = (index, newSize) => {
    const updatedBreakdown = [...roomBreakdown];
    updatedBreakdown[index].size = parseFloat(newSize) || 0;
    setRoomBreakdown(updatedBreakdown);
    const total = updatedBreakdown.reduce((acc, room) => acc + room.size, 0);
    setTotalArea(total);
  };

  const handleAddRoom = (index) => {
    setAddIndex(index);
    setOpenDialog(true);
  };

  const handleRemoveRoom = (index) => {
    const updatedBreakdown = roomBreakdown.filter((_, i) => i !== index);
    setRoomBreakdown(updatedBreakdown);
    const total = updatedBreakdown.reduce((acc, room) => acc + room.size, 0);
    setTotalArea(total);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogSubmit = () => {
    const updatedBreakdown = [...roomBreakdown];
    updatedBreakdown.splice(addIndex + 1, 0, { ...newRoom, size: parseFloat(newRoom.size) || 0 });
    setRoomBreakdown(updatedBreakdown);
    const total = updatedBreakdown.reduce((acc, room) => acc + room.size, 0);
    setTotalArea(total);
    setOpenDialog(false);
    setNewRoom({ roomType: '', size: '' });
    setAddIndex(null);
  };

  const handleNext = () => {
    const filteredBreakdown = roomBreakdown.filter(room => room.size !== 0);
    onNext({ roomBreakdown: filteredBreakdown, totalArea });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <p>Erreur lors du chargement du secteur: {error}</p>;
  }

  return (
    <div className='flex col hcenter gap4' style={{maxWidth: '600px'}}>

        {/* <div>
          <Typography variant="h5">Composition de votre superficie de {Number(totalArea).toFixed(0)} m²</Typography>
          <div className='fnt-1'>
            Nous vous proposons une décomposition de votre surface en utilisant des nominations génériques
            permettant de représenter tout type de locaux. Cette décomposition permettra d'associer des 
            services pour chaque zone.  
          </div>
        </div> */}


        <div className='flex row gap2 ' >
          <h4 style={{maxWidth: '600px'}}>Composition de vos locaux de {Number(totalArea).toFixed(0)} m²</h4>


 
          <InfoToolTip>
            <div className='fnt-1'>
              Nous vous proposons une décomposition de vos locaux en utilisant des nominations génériques permettant de représenter tout type de locaux. Cette décomposition permettra d'associer des services spécifiques pour chaque espace car chacun représente des enjeux spécifiques et requiert une attention particulière.
            </div>
            <div className='fnt-1'>
              Vous pouvez :
            </div>
            <ul className='fnt-1' style={{margin: '0 50px'}}>
              <li>Supprimer des espaces en cliquant sur le bouton "moins",</li>
              <li>Ajouter des espaces en spécifiant le nom qui vous convient et la superficie correspondante en cliquant sur le bouton "plus",</li>
              <li>Modifier les superficies de chaque espace en les indiquant en m² directement dans la zone de saisie.</li>
            </ul>
          </InfoToolTip>
        </div>
        
        <div className='flex col gap1'>
          {roomBreakdown.map((room, index) => (
            <div key={index} className='flex row hsb vcenter gap1' 
            style={{ ariaHidden: false, width:'100%', maxWidth: '600px', borderBottom: '1px solid lightgray', paddingBottom: '4px', margin: "auto"}}>
              <IconButton
                onClick={() => handleAddRoom(index)}
                sx={{ marginRight: 1, width: '20px', height: '20px' }}>
                <AddIcon />
              </IconButton>
              <h6 style={{width:'100%'}}>{room.roomType} :</h6>
              <TextField
                type="number"
                label="m²"
                value={room.size}
                onChange={(e) => handleRoomSizeChange(index, e.target.value)}
                variant="outlined"
                size="small"
                sx={{ maxWidth: '85px' }}
              />
              <IconButton
                onClick={() => handleRemoveRoom(index)}
                sx={{ marginLeft: 1, width: '20px', height: '20px' }}
              >
                <RemoveIcon />
              </IconButton>
            </div>
          ))}
        </div>

        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle>Ajouter une nouvelle zone</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Entrez le nom de la zone et la surface en m² pour la nouvelle zone.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              label="Nom de la zone"
              fullWidth
              value={newRoom.roomType}
              onChange={(e) => setNewRoom({ ...newRoom, roomType: e.target.value })}
            />
            <TextField
              margin="dense"
              label="m²"
              type="number"
              fullWidth
              value={newRoom.size}
              onChange={(e) => setNewRoom({ ...newRoom, size: e.target.value })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">Annuler</Button>
            <Button onClick={handleDialogSubmit} color="primary">Ajouter</Button>
          </DialogActions>
        </Dialog>

        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>

          <button type="submit" className='btn-1 tertiary all' onClick={onBack}>
            Précédent
          </button>

          <button type="submit" className='btn-1 primary all' onClick={handleNext}>
            Suivant
          </button>

        </Box> */}


        
        <div className='flex row hsb' style={{marginTop: '50px', maxWidth: '600px', width: '100%'}}>
          <button className='btn-1 tertiary all' onClick={onBack}>
            Précédent
          </button>
          <button className='btn-1 primary all' onClick={handleNext}>
            Suivant
          </button>
        </div>

        
    </div>
  );
}

export default SecondPage;
