import ArticlesList from "../components/ArticlesList";
import { ArticleCards } from "../components/ArticleCards";
import CTASection from "../components/CTASection";

const Blog = () => {
    return (
        <div id="blog-page"  className='flex col hcenter' style={{padding: '7.5vw 5vw'}}>
           
            <div className='flex col hcenter gap8' style={{ maxWidth: '1400px'}}>
                <div className="flex col hstart gap2" style={{marginTop: '50px', width:'100%'}}>
                    <h2>Grandir Ensemble</h2>
                    <h5 style={{maxWidth: '600px'}}>
                        Le service aux entreprises comme un levier de performance durable 
                        pour votre activité.</h5>
                </div>
                <ArticleCards/>
                <ArticlesList id='all-articles'/>
                <CTASection/>
            </div>
        </div>
    )
}

export default Blog;