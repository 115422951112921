import React, { useState, useEffect } from 'react';
import { 
  Accordion, AccordionSummary, AccordionDetails, MenuItem, Select, Typography, Box, IconButton,
  CircularProgress, FormControl, TextField, Autocomplete, FormHelperText
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { InfoToolTip } from '../Utils';
import { useAllServicesWithFrequencies } from '../../hooks/useServices';
import { useAllDefaultServices } from '../../hooks/useDefaultServices';
import { estimateQuote } from '../../services/quoteService';


function ThirdPage({ formData, onBack, onSubmit }) {
  const [frequency, setFrequency] = useState(''); 
  const [serviceFrequencies, setServiceFrequencies] = useState({});
  const [frequencyError, setFrequencyError] = useState(false); // Ajout pour gérer l'erreur de fréquence

  const { 
    services: allServicesWithFrequencies, 
    loading : servicesLoading, 
    error : servicesError 
  } = useAllServicesWithFrequencies();

  // const allServices = allServicesWithFrequencies.reduce((acc, service) => {
  //   acc[service.ServiceName] = { ...service };  // Use ServiceName as the key and spread service details
  //   delete acc[service.ServiceName].ServiceName;  // Remove the ServiceName field from the values
  //   return acc;
  // }, {});

  // Trier les services par ordre alphabétique
  const allServices = allServicesWithFrequencies
  .sort((a, b) => a.ServiceName.localeCompare(b.ServiceName))
  .reduce((acc, service) => {
    acc[service.ServiceName] = { ...service };  // Utiliser ServiceName comme clé
    delete acc[service.ServiceName].ServiceName;  // Supprimer ServiceName des valeurs
    return acc;
  }, {});

  const { 
    services: allDefaultServices, 
    loading: defaultServicesLoading, 
    error: defaultServicesError 
  } = useAllDefaultServices();

  const loading = servicesLoading || defaultServicesLoading;
  const error = servicesError || defaultServicesError;

  const frequencyOptions = [
    { label: "2 passages par semaine", value: '1x2x4' },
    { label: "3 passages par semaine", value: '1x3x4' },
    { label: "5 passages par semaine", value: '1x5x4' },
    { label: "2 passages par jour 5 fois par semaine", value: '2x5x4' },
    { label: "1 passage par jour", value: '1x7x4' },
    { label: "2 passages par jour", value: '2x7x4' },
    { label: "1 passages par mois", value: '1x' },
  ];

  useEffect(() => {
    const initialFrequencies = {};

    formData.roomBreakdown.forEach((zone) => {
      const zoneName = zone.roomType;

      const defaultServices = allDefaultServices.find(
        (service) => service.SectorName === formData.sector && service.ZoneName === zoneName
      )?.services || {};

      initialFrequencies[zoneName] = Object.keys(defaultServices).length > 0
        ? Object.entries(defaultServices).map(([serviceName, serviceDetails]) => ({
            name: serviceName,
            ...serviceDetails,
            frequency: serviceDetails[frequency] || '' 
          }))
        : createDefaultServices();
    });

    setServiceFrequencies(initialFrequencies);
  }, [frequency, formData]);

  const createDefaultServices = () => {
    const defaultServices = [
      createServiceObject("Aération des locaux"),
      createServiceObject("Aspiration et balayage des sols"),
      createServiceObject("Lavage et désinfection des sols durs"),
      createServiceObject("Nettoyage et désinfection des divers surfaces de contact"),
      createServiceObject("Nettoyage des surfaces vitrées intérieurs et miroirs"),
      createServiceObject("Vidage des poubelles / corbeilles et remplacement des sacs"),
      createServiceObject("Nettoyage des vitres")
    ];

    return defaultServices;
  };

  const createServiceObject = (serviceName) => {
    const serviceDetails = allServices[serviceName];
    return {
      name: serviceName,
      frequency: serviceDetails ? serviceDetails[frequency] : '', 
      ...serviceDetails
    };
  };

  const handleServiceChange = (zoneIndex, serviceIndex, newServiceName) => {
    // Vérifiez si le newServiceName est défini pour éviter les erreurs
    if (!newServiceName) return;

    const zonesArray = Object.entries(serviceFrequencies);
    if (zonesArray[zoneIndex]) {
      const updatedFrequencies = { ...serviceFrequencies };
      const zoneName = zonesArray[zoneIndex][0];
      const selectedService = allServices[newServiceName];

      updatedFrequencies[zoneName][serviceIndex] = {
        name: newServiceName,
        ...selectedService,
        frequency: selectedService[frequency] || '' // Utiliser la fréquence sélectionnée
      };

      setServiceFrequencies(updatedFrequencies);
    }
  };

  const handleFrequencyChange = (zoneIndex, serviceIndex, newFrequency) => {
    const zonesArray = Object.entries(serviceFrequencies);
    if (zonesArray[zoneIndex]) {
      const updatedFrequencies = { ...serviceFrequencies };
      updatedFrequencies[zonesArray[zoneIndex][0]][serviceIndex].frequency = newFrequency;
      setServiceFrequencies(updatedFrequencies);
    }
  };

  const handleAddService = (zoneIndex, serviceIndex) => {
    const zonesArray = Object.entries(serviceFrequencies);
    const zoneName = zonesArray[zoneIndex][0];
    const updatedFrequencies = { ...serviceFrequencies };

    const newService = {
      name: "", // Valeur par défaut
      frequency: ""
    };

    updatedFrequencies[zoneName].splice(serviceIndex + 1, 0, newService);

    setServiceFrequencies(updatedFrequencies);
  };

  const handleRemoveService = (zoneIndex, serviceIndex) => {
    const zonesArray = Object.entries(serviceFrequencies);
    const zoneName = zonesArray[zoneIndex][0];
    const updatedFrequencies = { ...serviceFrequencies };

    if (updatedFrequencies[zoneName].length > 1) {
      updatedFrequencies[zoneName].splice(serviceIndex, 1);
      setServiceFrequencies(updatedFrequencies);
    }
  };

  const handleFrequencyDropdownChange = (e) => {
    setFrequency(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); 
  
    // Vérifier si la fréquence globale est définie
    if (!frequency) {
      setFrequencyError(true);
      return;
    }

    const updatedServiceFrequencies = { ...serviceFrequencies };
    Object.entries(updatedServiceFrequencies).forEach(([zone, services]) => {
      const zoneInfo = formData.roomBreakdown.find((z) => z.roomType === zone);
      services.forEach((service) => {
        service.surface = zoneInfo?.size || 0; 
      });
    });
  
    try {
      const results = await estimateQuote(updatedServiceFrequencies);
      console.log('Form submitted successfully:', results);
  
      let HoursMonthly = 0;
      let ConsumableCosts = 0;
      let PriceMonthly = 0;
  
      Object.entries(results.results).forEach(([zoneName, services]) => {
          services.forEach((service) => {
            HoursMonthly += service.hm || 0;
            ConsumableCosts += service.cc || 0;
            PriceMonthly += service.mp || 0;
          });
        });

      const updatedFormData = {
        ...formData,
        globalFrequency: frequency,
        serviceFrequencies: updatedServiceFrequencies,
        results: results.results,
        HoursMonthly : HoursMonthly,
        HoursWeekly : HoursMonthly / ( 52 / 12 ),
        ConsumableCosts : ConsumableCosts,
        FixedCosts : 100,
        PriceMonthly : PriceMonthly,
        PriceHourly : ( PriceMonthly + 100 ) / HoursMonthly
      };
  
      onSubmit(updatedFormData);
  
    } catch (error) {
      console.error('Form submission failed:', error);
    }
  };


  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return (
      <div>
        <p>Erreur lors de la récupération des données.</p>
        {servicesError && <p>Erreur avec les services: {servicesError.message}</p>}
        {defaultServicesError && <p>Erreur avec les fréquences: {defaultServicesError.message}</p>}
      </div>
    );
  }
  return (
    <div className='flex col hcenter gap2' style={{padding:'0 0'}}>
      
      <div className='flex row hcenter' style={{width: '100%', marginBottom:'50px', padding:'0 5vw'}}>
        <h4 style={{maxWidth: '600px'}}>Nos services pour vos locaux</h4>
      </div>
        
      <div className='flex row hstart' style={{width: '100%', padding:'0 5vw'}}>
        <Typography variant="h5" gutterBottom>Selectionnez une fréquence d'intervention :</Typography>
      </div>
       

        <div className='flex row  hcenter vcenter gap1' style={{padding:'0 5vw'}}>

          <FormControl required error={frequencyError}>
            <Select
              value={frequency || ''}
              displayEmpty
              onChange={(e) => {
                setFrequency(e.target.value);
                setFrequencyError(false); // Réinitialiser l'erreur si une fréquence est sélectionnée
              }}
              sx={{ width: "30vw", minWidth: '200px', marginBottom: 2 }}
              required
            >
              <MenuItem value="" disabled>Sélectionnez une fréquence...</MenuItem>
              {frequencyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            {frequencyError && <FormHelperText>Veuillez sélectionner une fréquence.</FormHelperText>}
          </FormControl>
          <InfoToolTip>
              <div className='fnt-1' style={{textAlign: 'justify'}}>
                Si vous selectionnez un ou deux passage par jour, cela s'applique sur les sept jours de la semaine. 
                Si vous souhaitez une prestation ponctuelle, selectionnez " 1 fois par mois ".
              </div>
            </InfoToolTip>
        </div>

        <div className='flex row hstart' style={{width:'100%', padding:'0 5vw'}}>
            <Typography variant="h5" gutterBottom>Détails des prestations pour chacun de vos espaces</Typography>
            <InfoToolTip>
              <div className='flex col gap2 ' >
                <div className='fnt-1' style={{textAlign: 'justify'}}>
                  A cette étape, vous avez défini la nature de votre activité, la superficie de vos locaux et sa répartition et 
                  la récurrence souhaitée des interventions. A présent, définissons l'ensemble des services à opérer pour chaque espace et 
                  affinons la réccurence de chaque service. 
                </div>
                <div className='fnt-1' style={{textAlign: 'justify'}}>
                  Nous avons selectionné par défaut un lot de service standard pour chaque espace de vos locaux. De notre expérience, cette 
                  selection correspond à la plupart de nos client, il suffit généralement d'ajouter quelques services supplémentaire pour 
                  répondre à des exigences plus élevées. 
                </div>
                <div className='fnt-1' style={{textAlign: 'justify'}}>
                  Une fois la réccurence globale définie juste au dessus, par défaut, nous la propageons à l'ensemble des services sauf pour 
                  certains comme le nettoyage des vitres fixé à une fois par mois. Cette propagation ou non par défaut de la fréquence 
                  d'intervention résult de ce qui est communément souhaité part nos client. N'hésitez pas à en changer. 
                </div>
              </div>
            </InfoToolTip>
          </div>


        <Box className='flex col gap1' sx={{ padding: 2, width:'100vw', maxWidth:'1000px'}}>

          
          {Object.entries(serviceFrequencies).map(([zone, services], zoneIndex) => (
            <div key={zone} style={{borderRadius: '4px', boxShadow: '8px 8px 15px #a3b1c6, -8px -8px 15px #efefef', width:'100%'}}>
              <Accordion key={zone} sx={{ boxShadow: 'none', border: 'none', '&::before': { display: 'none' }, backgroundColor: '#efefef'  }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${zoneIndex}-content`}
                  id={`panel${zoneIndex}-header`}
                  sx={{ borderRadius: 1, width: "90%", margin: "0 auto", boxShadow: "none" }}
                >
                  <h6>{zone}</h6>
                </AccordionSummary>
                <AccordionDetails sx={{ width: "100%", margin: "1rem auto" }}>
                  {services.map((service, serviceIndex) => (
                    <Box key={`${zone}-${service.name}-${serviceIndex}`} 
                      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: "auto", 
                      flexDirection:'row', flexWrap:'wrap',
                            borderBottom: '1px solid lightgray', paddingY: 1, gap: 2}}>

                      <div className='flex row ' style={{ width: '50%', minWidth:'300px'}}>
                        <IconButton
                          onClick={() => handleAddService(zoneIndex, serviceIndex)}
                          sx={{ marginRight: 0, width: '20px', height: '20px' }}
                        >
                          <AddIcon />
                        </IconButton>

                        <Autocomplete
                          sx={{ width: '100%'}}
                          value={service.name || ""}
                          options={Object.keys(allServices)}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => handleServiceChange(zoneIndex, serviceIndex, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sélectionnez un service"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null // Supprime la croix de suppression
                              }}
                            />
                          )}
                        />
                      </div>

                      <div className='flex row' style={{ width: '45%', minWidth:'300px'}}>
                        <Autocomplete
                          sx={{ width: '100%'}}
                          value={service.frequency || ""}
                          options={[
                            "1 fois par jour", "2 fois par jour", "3 fois par jour",
                            "1 fois par semaine", "2 fois par semaine", "3 fois par semaine",
                            "4 fois par semaine", "5 fois par semaine", "6 fois par semaine", "10 fois par semaine",
                            "1 fois par mois", "2 fois par mois", "3 fois par mois",
                            "1 fois par trimestre", "2 fois par trimestre", "1 fois par semestre",
                            "2 fois par semestre", "1 fois par an", ""
                          ]}
                          getOptionLabel={(option) => option}
                          onChange={(e, value) => handleFrequencyChange(zoneIndex, serviceIndex, value)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Sélectionnez une fréquence"
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: null, // Supprime la croix de suppression
                              }}
                            />
                          )}
                        />

                        <IconButton
                          onClick={() => handleRemoveService(zoneIndex, serviceIndex)}
                          sx={{ marginLeft: 1, width: '20px', height: '20px' }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      </div>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
        </Box>


        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
            <button type="submit" className='btn-1 tertiary all' onClick={onBack}>
            Précédent</button>
            <button type="submit" className='btn-1 primary all'>
            Suivant</button>
        </Box> */}

        <div className='flex row hsb' style={{marginTop: '50px', maxWidth: '600px', width: '100%'}}>
          <button className='btn-1 tertiary all' onClick={onBack}>
            Précédent
          </button>
          <button className='btn-1 primary all' onClick={handleSubmit}>
            Suivant
          </button>
        </div>

    </div>
  );
}

export default ThirdPage;


                      {/* <Select
                        sx={{
                          width: '15vw', height: "30px", fontSize: 12, padding: '5px',
                          '& .MuiSelect-select': {padding: '8px 16px'},
                        }}
                        value={service.frequency || ''}
                        displayEmpty
                        onChange={(e) => handleFrequencyChange(zoneIndex, serviceIndex, e.target.value)}
                      >
                        <MenuItem value="" disabled>Sélectionnez une fréquence...</MenuItem>
                        {[
                          "1 fois par jour", "2 fois par jour", "3 fois par jour",
                          "1 fois par semaine", "2 fois par semaine", "3 fois par semaine",
                          "4 fois par semaine", "5 fois par semaine", "6 fois par semaine", "10 fois par semaine",
                          "1 fois par mois", "2 fois par mois", "3 fois par mois",
                          "1 fois par trimestre", "2 fois par trimestre", "1 fois par semestre",
                          "2 fois par semestre", "1 fois par an"
                        ].map(option => (
                          <MenuItem key={option} value={option}>{option}</MenuItem>
                        ))}
                      </Select> */}