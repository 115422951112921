import React from 'react'
import { TwitterIcon } from './Icons';
import CallIcon from '../icons/call_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg';
import MailIcon from '../icons/mail_24dp_E8EAED_FILL0_wght400_GRAD0_opsz24.svg';
import LinkedInIcon from '../icons/linkedin-svgrepo-com.svg';
import InstagramIcon from '../icons/instagram-logo-facebook-svgrepo-com.svg';

function Footer() {
  return (
    <div className='flex col gap2' style={{backgroundColor: '#161719', padding:'50px 5vw', minHeight:'500px'}}> 
      <h1 style={{color: "#ffffff"}}>Alvy</h1>
      <div className='flex row hsb wrap gap4' >
          <div className='flex col' style={{padding:'10px 20px'}}>
              <div className='flex col wrap gap1'>
                <h6 className='box-1'>PROPRETE</h6>
                <h6 className='box-1'>GARDIENNAGE</h6>
                <h6 className='box-1'>ESPACES VERTS</h6>
            </div>
          </div>
          <div className='flex col gap1' style={{padding:'10px 20px'}}>
              <div className='flex row gap1'> 
                  <img className='icon' src={CallIcon} alt="" />
                  <p style={{color: "#ffffff"}}>07 82 70 77 69</p>
              </div>
              <div className='flex row gap1'> 
                  <img className='icon' src={MailIcon} alt="" />
                  <a className='dark-link'  href="mailto:contact@alvy-services.com" style={{fontSize:'1rem', color: "#ffffff"}}>contact@alvy-services.com</a>
              </div>
            
              <div className="flex row gap2">
                  {/* <LinkedInIcon className='social-icon'/> */}
                  <a href="/"><img src={LinkedInIcon} alt="" style={{width:'30px'}}/></a>
                  <TwitterIcon className='social-icon' fill='#ffffff'/>
                  <a href="/"><img src={InstagramIcon} alt="" style={{width:'30px'}}/></a>
              </div>
          </div>
          <div className='flex col gap1' style={{color: "#ffffff", fontSize:'1rem', padding:'10px 20px'}}>
              <a className='dark-link'  href="/">Accueil</a>
              <a className='dark-link'  href="/services">Services</a>
              <a className='dark-link'  href="/about">A propos</a>
              <a className='dark-link'  href="/blog">Actualités</a>
              <a className='dark-link'  href="/commitments">Engagements</a>
          </div>


          <div className='flex col gap1' style={{color: "#ffffff", fontSize:'1rem', padding:'10px 20px'}}>
              {/* <a className='dark-link'  href="/">Plan du site</a> */}
              <a className='dark-link'  href="/quote-form">Estimer vos besoins</a>
              <a className='dark-link'  href="/contact-form">Contactez nous</a>  
          </div>



      </div>

      <div className='flex row wrap hcenter gap1' style={{color: "#ffffff", fontSize:'1rem', padding:'10px 20px', marginTop:'50px'}}>
          {/* <a className='dark-link'  href="/">Plan du site</a> */}
          <a className='dark-link'  href="/legal-notices">Mentions légales</a>
          <a className='dark-link'  href="/privacy-policy">Protection des données personnelles</a>
          <a className='dark-link'  href="/cookie-policy">Politique de cookie (UE)</a>
      </div>
    </div>
      

  )
}

export default Footer





            //   <div className="footer-container">
      //   <div className="grid">
      //     <div className="grid-col">
      //       <h2 style={{color:'white', fontSize:'2.2rem'}}>ALVY</h2>
      //     </div>
      //     <div className="grid-col">
      //       <h4>Contact</h4>

      //     </div>
      //     <div className="grid-col">
      //       <h4>Entreprise</h4>

      //     </div>
      //     <div className="grid-col">
      //       <h4>Services</h4>
      //       <a className='dark-link'  href="#">Propreté</a>
      //       <a className='dark-link'  href="#">Sécurité</a>
      //       <a className='dark-link'  href="#">Accueil</a>
      //       <a className='dark-link'  href="#">Maintenance</a>
      //       <a className='dark-link'  href="#">Logistique</a>
      //       <a className='dark-link'  href="#">Espaces verts</a>
      //     </div>
      //     <div className="grid-col">
      //       <h4>Légal</h4>
      //       <a className='dark-link'  href="#">Plan du site</a>
      //       <a className='dark-link'  href="#">Protection des données personnelles</a>
      //       <a className='dark-link'  href="/legal-notices">Mentions légales</a>
      //       <a className='dark-link'  href="#">Politique de cookie (UE)</a>
      //     </div>
      //   </div>

      // </div>