import React, { useState, useEffect } from 'react';
import { get } from '../../services/apiClient';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, TextField, Button, IconButton, Typography, Tooltip
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import UserCreator from '../users/UserCreator';

function UsersTable({ Role = null, specificEmails = null }) {
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('FirstName');
  const [openCreator, setOpenCreator] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null); // State for selected user to edit

  // Fetch users from API
  const fetchUsers = async () => {
    try {
      const response = await get('/admin/users');
      let filteredUsers = response;

      // Filter by Role if provided
      if (Role) {
        filteredUsers = filteredUsers.filter(user => user.Role === Role);
      }

      // Filter by specificEmails if provided
      if (specificEmails && specificEmails.length > 0) {
        filteredUsers = filteredUsers.filter(user => specificEmails.includes(user.Email));
      }

      setUsers(filteredUsers);
    } catch (error) {
      console.error('Erreur lors de la récupération des utilisateurs:', error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [Role, specificEmails]);

  // Open UserCreator for creating or editing a user
  const handleOpenCreator = (user = null) => {
    setSelectedUser(user); // Pass the selected user for editing
    setOpenCreator(true);
  };

  const handleCloseCreator = () => {
    setOpenCreator(false);
    setSelectedUser(null); // Reset the selected user when closing
    fetchUsers(); // Refresh users list after creating or updating
  };

  // Handle sorting when column header is clicked
  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Filter and sort users
  const filteredAndSortedUsers = users
    .filter(user =>
      user.FirstName.toLowerCase().includes(filter.toLowerCase()) ||
      user.LastName.toLowerCase().includes(filter.toLowerCase()) ||
      user.Email.toLowerCase().includes(filter.toLowerCase()) ||
      user.Phone.includes(filter) ||
      user.Address.toLowerCase().includes(filter.toLowerCase()) ||
      user.Company.toLowerCase().includes(filter.toLowerCase()) ||
      user.Position.toLowerCase().includes(filter.toLowerCase())
    )
    .sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

  return (
    <div className="flex col bs-3 br-l" style={{ padding: '20px', width: '1100px' }}>
      {/* Header with Add New User and Refresh Icons */}
      <div className="flex row hsb vcenter">
        <div className="flex row vcenter gap1">
          <Typography variant="h4">Users</Typography>
          {Role && (
            <div style={{ backgroundColor: 'lightgray', padding: '3px 6px', color: '#fff', borderRadius: '5px' }}>
              {Role}
            </div>
          )}
        </div>

        <div className="flex row gap1 vcenter">
          {/* Filter input */}
          <TextField
            label="name, email, phone, company, job position"
            variant="outlined"
            size="small"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{ width: '300px' }}
          />
          {/* Add New User Button */}
          <Tooltip title="New User">
            <IconButton color="primary" onClick={() => handleOpenCreator()}>
              <AddIcon />
            </IconButton>
          </Tooltip>

          {/* Refresh Button */}
          <Tooltip title="Refresh">
            <IconButton color="primary" onClick={fetchUsers}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {/* Table displaying filtered and sorted users */}
      <TableContainer className="scrollbar-1" component={Paper} elevation={0} 
        sx={{ maxHeight: 300, backgroundColor: '#efefef' }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <Button onClick={() => handleSort('FirstName')}>
                  First Name
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('LastName')}>
                  Last Name
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Email')}>
                  Email
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Phone')}>
                  Phone
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Address')}>
                  Address
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Company')}>
                  Company
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Position')}>
                  Position
                </Button>
              </TableCell>
              <TableCell>Role</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedUsers.map(user => (
              <TableRow key={user.Email}>
                <TableCell>{user.FirstName}</TableCell>
                <TableCell>{user.LastName}</TableCell>
                <TableCell>{user.Email}</TableCell>
                <TableCell>{user.Phone}</TableCell>
                <TableCell>{user.Address}</TableCell>
                <TableCell>{user.Company}</TableCell>
                <TableCell>{user.Position}</TableCell>
                <TableCell>{user.Role}</TableCell>
                <TableCell>
                  <IconButton onClick={() => handleOpenCreator(user)} sx={{ margin: '0px 5px', padding: 0 }}>
                    <EditIcon fontSize="small"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* UserCreator Component for creating or editing users */}
      {openCreator && (
        <UserCreator
          open={openCreator}
          onClose={handleCloseCreator}
          user={selectedUser} // Pass selectedUser for editing
        />
      )}
    </div>
  );
}

export default UsersTable;
