import React, { useState, useEffect } from 'react';
import { get, post } from '../../services/apiClient';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, TextField, Button, IconButton, Typography, Tooltip 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import ClientCreator from '../users/ClientCreator';  // The new component for creating/editing clients

function ClientsTable() {
  const [clients, setClients] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('CompanyName');
  const [openCreator, setOpenCreator] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);

  // Fetch clients from API
  const fetchClients = async () => {
    try {
      const response = await get('/admin/clients');
      setClients(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des clients:', error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  // Open ClientCreator for new client
  const handleOpenCreator = () => {
    setSelectedClient(null);
    setOpenCreator(true);
  };

  // Open ClientCreator for editing an existing client
  const handleEditClient = (client) => {
    setSelectedClient(client);
    setOpenCreator(true);
  };

  // Handle sorting when column header is clicked
  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Copy item to clipboard
  const handleCopy = (item) => {
    navigator.clipboard.writeText(item);
    alert(`Copied to clipboard: ${item}`);
  };

  // Filter and sort clients
  const filteredAndSortedClients = clients
    .filter(client =>
      client.CompanyName.toLowerCase().includes(filter.toLowerCase()) ||
      client.Siren.includes(filter) ||
      client.Address.toLowerCase().includes(filter.toLowerCase())
    )
    .sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
      return 0;
    });

  return (
    <div className="flex col bs-3 br-l" style={{ padding: '20px', width: '1100px' }}>
      {/* Header with Add New Client and Refresh Icons */}
      <div className="flex row hsb vcenter">
        <Typography variant="h4">Clients</Typography>

        <div className="flex row gap1 vcenter">
          {/* Filter input */}
          <TextField
            label="name, siren, address"
            variant="outlined"
            size="small"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            sx={{ width: '300px' }}
          />
          {/* Button to create new client */}
          <Tooltip title="New Client">
            <IconButton color="primary" onClick={handleOpenCreator}>
              <AddIcon />
            </IconButton>
          </Tooltip>

          {/* Refresh button */}
          <Tooltip title="Refresh">
            <IconButton color="primary" onClick={fetchClients}>
              <RefreshIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      {/* Table displaying filtered and sorted clients */}
      <TableContainer className="scrollbar-1" component={Paper} elevation={0} 
      sx={{ maxHeight: 300, backgroundColor: '#efefef' }} >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Client ID</TableCell>
              <TableCell>
                <Button onClick={() => handleSort('CompanyName')}>
                  Company Name
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Siren')}>
                  Siren
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('Address')}>
                  Address
                </Button>
              </TableCell>
              <TableCell>
                <Button onClick={() => handleSort('CreatedAt')}>
                  Created At
                </Button>
              </TableCell>
              <TableCell>Representatives</TableCell>
              <TableCell>Missions</TableCell>
              <TableCell>Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAndSortedClients.map(client => (
              <TableRow key={client.ClientID}>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  {client.ClientID}
                  <IconButton onClick={() => handleCopy(client.ClientID)} sx={{margin:'0px 5px', padding:0}}>
                    <ContentCopyIcon fontSize="small" />
                  </IconButton>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>{client.CompanyName}</TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>{client.Siren}</TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>{client.Address}</TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>{new Date(client.CreatedAt).toLocaleDateString('en-US', { 
                                    year: 'numeric', 
                                    month: '2-digit', 
                                    day: '2-digit', 
                                    hour: '2-digit', 
                                    minute: '2-digit', 
                                    second: '2-digit', 
                                    hour12: false 
                                    })}</TableCell>
                <TableCell sx={{ verticalAlign: 'top', maxWidth: '100px' }}>
                  <div className='flex col hcenter gap1' style={{ maxWidth: '100px' }}>
                      {client.Representatives?.length > 0 
                      ? client.Representatives.map((rep, index) => (
                          <div key={index} 
                            onClick={() => handleCopy(rep)}
                              style={{
                                  cursor:'pointer',
                                  padding: '2px 4px', 
                                  backgroundColor:"lightgray", 
                                  color:'GrayText', 
                                  borderRadius:'5px', 
                                  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                  fontSize:'8px',
                                  maxWidth: '100px',           // Limite la largeur
                                  whiteSpace: 'nowrap',        // Empêche le retour à la ligne
                                  overflow: 'hidden',          // Masque le texte qui déborde
                                  textOverflow: 'ellipsis'     // Ajoute des points de suspension si le texte déborde
                              }}> 
                              {rep}
                          </div>
                          ))
                      : '--'}
                  </div>
              </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                    <div className='flex col hcenter gap1'>
                        {client.Missions?.length > 0 
                        ? client.Missions.map((rep, index) => (
                            <div key={index} 
                            style={{
                                padding: '2px 4px', 
                                backgroundColor:"lightgray", color:'GrayText', borderRadius:'5px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                fontSize:'8px' }}> 
                                {rep}
                            </div>
                            ))
                        : '--'}
                    </div>
                </TableCell>
                <TableCell sx={{ verticalAlign: 'top' }}>
                  <IconButton onClick={() => handleEditClient(client)} sx={{margin:'0px 5px', padding:0}}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ClientCreator Dialog for creating or editing clients */}
      {openCreator && (
        <ClientCreator
          open={openCreator}
          onClose={() => setOpenCreator(false)}
          client={selectedClient}  // Pass the selected client for editing, or null for creating
          onClientUpdated={fetchClients}  // Refresh the client list after an update
        />
      )}
    </div>
  );
}

export default ClientsTable;
