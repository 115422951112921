import { useState, useEffect } from 'react';
import { 
    getAllServices, 
    getAllServicesWithFrequencies, 
    getServiceWithFrequenciesByName 
} from '../services/servicesService';

// Hook pour récupérer la liste de tous les services
export const useAllServices = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await getAllServices();
                setServices(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    return { services, loading, error };
};

// Hook pour récupérer tous les services avec leurs fréquences
export const useAllServicesWithFrequencies = () => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await getAllServicesWithFrequencies();
                setServices(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    return { services, loading, error };
};

// Hook pour récupérer les fréquences d'un service spécifique
export const useServiceFrequencies = (serviceName) => {
    const [services, setFrequencies] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!serviceName) return;

        const fetchServiceFrequencies = async () => {
            try {
                const data = await getServiceWithFrequenciesByName(serviceName);
                setFrequencies(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceFrequencies();
    }, [serviceName]);

    return { services, loading, error };
};
