import React, { useEffect, useState } from 'react';
import { get } from '../../services/apiClient';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, TextField, Typography, Button, IconButton, Tooltip 
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import QuoteSummary from './QuoteSummary'; // Importing the new QuoteSummary component

const QuoteTable = () => {
  const [quotes, setQuotes] = useState([]);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('CustomerID');
  const [selectedQuote, setSelectedQuote] = useState(null);

  // Fonction pour récupérer les devis depuis l'API
  const fetchQuotes = async () => {
    try {
      const response = await get('/admin/quotes'); // Appel à l'API pour récupérer les devis
      setQuotes(response);
    } catch (error) {
      console.error('Erreur lors de la récupération des devis:', error);
    }
  };

  useEffect(() => {
    fetchQuotes();
  }, []);

  const handleCopy = (customerId) => {
    navigator.clipboard.writeText(customerId);
  };

  // Fonction pour gérer le tri des colonnes
  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Fonction pour trier les devis en fonction de la colonne sélectionnée
  const sortedQuotes = quotes
    .filter((quote) => {
      const quoteDate = new Date(Number(quote.CreatedAt)).toLocaleDateString('fr-FR');
      return (
        (quote.CustomerID && quote.CustomerID.toLowerCase().includes(filter.toLowerCase())) ||
        (quote.QuoteID && quote.QuoteID.toLowerCase().includes(filter.toLowerCase())) ||
        quoteDate.includes(filter) ||
        (quote.Sector && quote.Sector.toLowerCase().includes(filter.toLowerCase())) ||
        (quote.AreaSize && quote.AreaSize.toString().includes(filter))
      );
    })
    .sort((a, b) => {
      const compareA = a[sortColumn];
      const compareB = b[sortColumn];
      if (sortOrder === 'asc') {
        return compareA < compareB ? -1 : compareA > compareB ? 1 : 0;
      }
      return compareA > compareB ? -1 : compareA < compareB ? 1 : 0;
    });

  return (
    <div className="flex row gap2">
      {/* Tableau des devis */}
      <div className="flex col bs-3 br-l" style={{ padding: '20px', width: '760px' }}>
        {/* Header avec boutons */}
        <div className="flex row hsb vcenter">
          <Typography variant="h4">Quotes</Typography>

          <div className='flex row gap1 vcenter'>
            <TextField
              label="ids, date, area size, sector"
              variant="outlined"
              size='small'
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              sx={{ width: '300px' }}
            />
            {/* Bouton "New Quote" */}
            <Tooltip title="New Quote">
              <IconButton color="primary" component="a"
                href="/quote-form" target="_blank" rel="noopener noreferrer" >
                <AddIcon sx={{ color: '#007bff' }} />
              </IconButton>
            </Tooltip>

            {/* Bouton de rafraîchissement */}
            <Tooltip title="Refresh">
              <IconButton onClick={fetchQuotes} color="primary">
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        <TableContainer className='scrollbar-1' component={Paper} elevation={0}
          sx={{ maxHeight: 300, backgroundColor: '#efefef' }}>
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#efefef' }}>
                  <Button onClick={() => handleSort('CustomerID')} >
                    Customer ID
                  </Button>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>
                  <Button onClick={() => handleSort('QuoteID')}>
                    Quote ID
                  </Button>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>
                  <Button onClick={() => handleSort('CreatedAt')}>
                  Created At
                  </Button>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>
                  <Button onClick={() => handleSort('AreaSize')}>
                    Area Size
                  </Button>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>
                  <Button onClick={() => handleSort('Sector')}>
                    Sector
                  </Button>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedQuotes.map((quote) => (
                <TableRow key={quote.QuoteID} hover
                  onClick={() => setSelectedQuote(quote)}
                  selected={selectedQuote && selectedQuote.QuoteID === quote.QuoteID}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    <IconButton
                      size="small"
                      onClick={(e) => { e.stopPropagation(); handleCopy(quote.CustomerID); }}
                      aria-label="copy"
                      sx={{margin:0, padding:0}}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{quote.QuoteID}</TableCell>
                  <TableCell>{quote.CreatedAt ? new Date(Number(quote.CreatedAt)).toLocaleDateString('fr-FR') : 'Date non disponible'}</TableCell>
                  <TableCell>{quote.AreaSize}</TableCell>
                  <TableCell>{quote.Sector}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {/* Tableau de synthèse du devis sélectionné */}
      {selectedQuote && (
        <div className='br-l bs-3'>
          <QuoteSummary selectedQuote={selectedQuote} />
        </div>
        )}
    </div>
  );
};

export default QuoteTable;
