import apiClient from './apiClient';

const LOGIN_ENDPOINT = '/user/login';

// Fonction pour la connexion 
export const login = async (Email, Password) => {
  try {
    // Appel à l'API pour la connexion
    const response = await apiClient.post(LOGIN_ENDPOINT, { Email, Password });
    
    // Log de la réponse brute pour voir ce qu'elle contient
    console.log('Réponse de l\'API:', response);

    // Vérifier si la réponse contient bien le token
    if (response) {
      return response;
    } else {
      throw new Error('La réponse de l\'API ne contient pas de token ou d\'informations valides');
    }
  } catch (error) {
    console.error('Erreur lors de la connexion de l\'utilisateur:', error.message || error);
    throw error;
  }
};

export default {
  login,
};
