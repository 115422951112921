import React, { useState } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, TextField, CircularProgress } from '@mui/material';

// import sectorData from './sectorData.json';
// import servicesData from './servicesData2.json';
import { useSectorNames } from '../../hooks/useSectors';
// import { Loader } from '../Utils';

function FirstPage({ onNext, formData }) {
  const [sector, setSector] = useState(formData.sector || '');
  const [areaSize, setAreaSize] = useState(formData.areaSize || '');

  // const sectors = Object.keys(servicesData.sectors); // Sector List
  const { sectorNames, loading, error } = useSectorNames();


  const handleSubmit = (e) => {
    e.preventDefault();
    // const sectorDetails = servicesData.sectors;
    onNext({ sector, areaSize });
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }
  if (error) { return <div>{error}</div> }
  
  return (
    <form onSubmit={handleSubmit}
    className='flex col hcenter gap4' style={{maxWidth: '600px'}}>
      {/* <div className='flex col vsb gap2 ' style={{width: '100%', padding: '1rem 2rem'}}> */}

        <div className='flex col gap2 ' >
          <h4 style={{maxWidth: '600px'}}>Obtenez une estimation de nos prestations de nettoyage pour vos locaux </h4>
          <div className='fnt-1' >
            Vous souhaitez connaître rapidement le coût d’une prestation de nettoyage récurrente pour vos locaux ? 
            Vous recherchez une préstation ponctuelle ou récurrente ?.
          </div>
          <div className='fnt-1' >
            Vous pouvez dès à présent réaliser une simulation précise et obtenir une proposition préliminaire 
            grace à notre outil de devis en ligne.   
          </div>
        </div>


        <div className='flex row wrap gap1 hsb' >
          <FormControl required  sx={{minWidth: '300px'}}>
            <InputLabel id="sector-label">Secteur d'activité</InputLabel>
              <Select
                labelId="sector-label"
                value={sector}
                onChange={(e) => setSector(e.target.value)}
                label="Secteur d'activité"
              >
                <MenuItem value="">
                  <em>Sélectionner...</em>
                </MenuItem>
                {sectorNames.map((sector) => (
                  <MenuItem key={sector} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Superficie (m²)"
              type="number"
              value={areaSize}
              onChange={(e) => setAreaSize(e.target.value)}
              required

            />
        </div>

        <div className='flex row hsb' style={{maxWidth: '600px', width: '100%'}}>
          <button type="submit" className='btn-1 tertiary all'>
            Contact
          </button>
          <button type="submit" className='btn-1 primary all'>
            Suivant
          </button>
        </div>

      {/* </div> */}
    </form>
  );
}

export default FirstPage;
