import React, { useState, useEffect } from 'react';
import { 
    Button, Dialog, DialogContent, DialogTitle, IconButton, Tooltip, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField 
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import PlanningCreator from '../users/PlanningCreator';
import { get } from '../../services/apiClient'; // Importation du service API pour récupérer les plannings
import { OpenInNew } from '../Icons';

const PlanningsTable = () => {
    const [open, setOpen] = useState(false); // État pour gérer l'ouverture et la fermeture de la modal
    const [plannings, setPlannings] = useState([]); // État pour stocker les plannings récupérés
    const [openPlanningID, setOpenPlanningID] = useState(null); // Stocker l'ID du planning à ouvrir
    const [openQuoteID, setOpenQuoteID] = useState(null); // Stocker l'ID du planning à ouvrir
    const [selectedPlanning, setSelectedPlanning] = useState(null); // Stocker le planning sélectionné

    const [filter, setFilter] = useState(''); // Filtre de recherche
    const [sortColumn, setSortColumn] = useState(null); // Colonne de tri
    const [sortDirection, setSortDirection] = useState('asc'); // Direction du tri

    // Fonction pour ouvrir la modal
    const handleOpen = () => {
        setOpen(true); // Ouvre la modal
    };

    // Fonction pour fermer la modal
    const handleClose = () => {
        setOpen(false); // Ferme la modal
        setOpenPlanningID(null); // Reset the planning ID when closing
        setOpenQuoteID(null);
        setSelectedPlanning(null); // Reset the selected planning when closing
    };

    // Fonction pour récupérer les plannings depuis l'API
    const fetchPlannings = async () => {
        try {
            const response = await get('/admin/plannings'); // Appel API pour récupérer les plannings
            setPlannings(response); // Stocke les plannings dans l'état
        } catch (error) {
            console.error('Erreur lors de la récupération des plannings:', error);
        }
    };

    // Fonction pour ouvrir une nouvelle fenêtre avec le planning sélectionné
    const handleViewPlanning = (planningID) => {
        const planning = plannings.find(p => p.PlanningID === planningID); // Trouver le planning correspondant
        setSelectedPlanning(planning); // Stocker le planning sélectionné
        setOpenPlanningID(planningID); // Stocker l'ID du planning sélectionné
        setOpenQuoteID(planning.QuoteID); // Stocker l'ID du devis correspondant 
        setOpen(true); // Ouvrir la modal
    };

    // Fonction pour trier la colonne
    const handleSort = (column) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    // Appliquer le tri et le filtre sur les plannings
    const filteredAndSortedPlannings = plannings
        .filter((planning) => {
            const searchTerm = filter.toLowerCase();
            return (
                planning.PlanningID.toLowerCase().includes(searchTerm) ||
                planning.QuoteID.toLowerCase().includes(searchTerm) ||
                new Date(planning.CreatedAt).toLocaleDateString().includes(searchTerm) ||
                (planning.UpdatedAt && new Date(planning.UpdatedAt).toLocaleDateString().includes(searchTerm))
            );
        })
        .sort((a, b) => {
            if (sortColumn) {
                const valueA = a[sortColumn] || '';
                const valueB = b[sortColumn] || '';
                if (sortDirection === 'asc') {
                    return valueA > valueB ? 1 : -1;
                }
                return valueA < valueB ? 1 : -1;
            }
            return 0;
        });

    // useEffect pour récupérer les plannings lorsque le composant est monté
    useEffect(() => {
        fetchPlannings();
    }, []);

    const handleCopy = (name, item) => {
        navigator.clipboard.writeText(item);
        alert(`${name} copied to clipboard`);
    };

    return (
        <div className="flex col bs-3 br-l" style={{ padding: '20px', width: 'fit-content' }}>
            <div className="flex row hsb vcenter">
                <Typography variant="h4">Plannings</Typography>
                <div className='flex row gap1 vcenter'>
                    <TextField
                        label="ids, dates"
                        variant="outlined"
                        size="small"
                        value={filter}
                        onChange={(e) => setFilter(e.target.value)}
                        sx={{ width: '200px' }}
                    />
                    <Tooltip title="Create New Planning">
                        <IconButton color="primary" onClick={handleOpen}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Refresh">
                        <IconButton onClick={fetchPlannings} color="primary">
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                </div>
            </div>

            {/* Table pour afficher les plannings */}
            <TableContainer className='scrollbar-1' component={Paper} elevation={0}
              sx={{ maxHeight: 300, backgroundColor: '#efefef' }}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>

                            <TableCell sx={{ backgroundColor: '#efefef' }}>
                                <Button onClick={() => handleSort('PlanningID')} >
                                    Planning ID
                                </Button>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: '#efefef' }}>
                                <Button onClick={() => handleSort('QuoteID')} >
                                    Quote ID
                                </Button>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: '#efefef' }}>
                                <Button onClick={() => handleSort('CreatedAt')} >
                                    Created At
                                </Button>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: '#efefef' }}>
                                <Button onClick={() => handleSort('UpdatedAt')} >
                                    Last Update
                                </Button>
                            </TableCell>
                            <TableCell sx={{ backgroundColor: '#efefef' }}>Planning</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredAndSortedPlannings.map((planning) => (
                            <TableRow key={planning.PlanningID}>
                                <TableCell>
                                    {planning.PlanningID}
                                    <IconButton onClick={() => handleCopy('PlanningID', planning.PlanningID)} sx={{margin:'0px 5px', padding:0}}>
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    {planning.QuoteID}
                                    <IconButton onClick={() => handleCopy('QuoteID', planning.QuoteID)} sx={{margin:'0px 5px', padding:0}}>
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                                <TableCell>
                                    {new Date(planning.CreatedAt).toLocaleDateString('en-US', { 
                                    year: 'numeric', 
                                    month: '2-digit', 
                                    day: '2-digit', 
                                    hour: '2-digit', 
                                    minute: '2-digit', 
                                    second: '2-digit', 
                                    hour12: false 
                                    })}
                                </TableCell>
                                <TableCell>
                                    {planning.UpdatedAt ? new Date(planning.UpdatedAt).toLocaleString('en-US', { 
                                        year: 'numeric', 
                                        month: '2-digit', 
                                        day: '2-digit', 
                                        hour: '2-digit', 
                                        minute: '2-digit', 
                                        second: '2-digit', 
                                        hour12: false 
                                    }) : '--'}
                                </TableCell>
                                <TableCell>
                                    <button onClick={() => handleViewPlanning(planning.PlanningID)}>
                                        <OpenInNew fill='#007bff'/>
                                    </button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Modal qui contient le composant PlanningCreator */}
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle className="flex row gap1">
                    <div className="br-l" style={{ marginTop: '10px', padding: '5px 10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                        {openPlanningID ? `PlanningID : ${openPlanningID}` : "Create Planning"}
                        {openPlanningID && (
                            <IconButton onClick={() => handleCopy('PlanningID', openPlanningID)} sx={{margin:0, padding:0}}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        )}
                    </div>

                    {openQuoteID && (
                    <div className="br-l" style={{ marginTop: '10px', padding: '5px 10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                        {openQuoteID ? `QuoteID : ${openQuoteID}` : ""}
                        
                        {openQuoteID && (
                            <IconButton onClick={() => handleCopy('QuoteID', openQuoteID)} sx={{margin:0, padding:0}}>
                                <ContentCopyIcon fontSize="small" />
                            </IconButton>
                        )}
                    </div>
                    )}
                    
                </DialogTitle>
                <DialogContent>
                    {openPlanningID ? (
                        // Si un planning est sélectionné, passer le planning à PlanningCreator
                        <PlanningCreator planning={selectedPlanning?.Planning} planningID={openPlanningID} quoteID={openQuoteID} />
                    ) : (
                        // Sinon, afficher le formulaire de création de planning
                        <PlanningCreator />
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default PlanningsTable;
