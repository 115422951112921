import React from "react";

const AdminHome = () => {
    return (
      <div className='flex col gap4 bs-3 br-l' style={{padding:'20px 30px'}}>
        <h2>Admin Home</h2>
      </div>
    );
  };
  
  export default AdminHome;