import React from 'react'

function CookiePolicy() {
  return (
    <div className='flex col hcenter' style={{margin: '7.5vw 5vw'}}>
        <div className='flex col gap2'  style={{maxWidth: '800px'}}>

            <section>
                <h3>1. Introduction</h3>
                <p style={{margin:'10px 20px'}}>
                    La présente Politique de Cookies décrit les pratiques mises en œuvre par Alvy, entreprise de services de nettoyage professionnels, concernant l’utilisation de cookies et autres traceurs sur notre site internet <a href="http://www.alvy-services.com">www.alvy-services.com</a> (ci-après le "Site").
                </p>
                <p style={{margin:'10px 20px'}}>
                    Cette politique vous informe sur la manière dont nous utilisons ces technologies, les types de cookies que nous utilisons, et vos droits concernant l’utilisation de cookies lors de votre navigation sur notre Site.
                </p>
            </section>

            <section>
                <h3>2. Qu'est-ce qu'un cookie ?</h3>
                <p style={{margin:'10px 20px'}}>
                    Un cookie est un petit fichier texte déposé sur votre terminal (ordinateur, smartphone, tablette) lors de la visite d’un site web. Les cookies permettent au site de reconnaître votre appareil et de collecter certaines informations relatives à votre navigation, comme vos préférences ou vos identifiants de session.
                </p>
            </section>

            <section>
                <h3>3. Types de Cookies Utilisés</h3>
                <p style={{margin:'10px 20px'}}>Alvy utilise différents types de cookies sur son Site, chacun ayant des finalités spécifiques :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Cookies nécessaires au fonctionnement du site</strong> : Ces cookies sont indispensables pour naviguer sur le Site et utiliser ses fonctionnalités. Sans ces cookies, les services que vous avez demandés ne peuvent pas être fournis.</li>
                    <li><strong>Cookies de performance</strong> : Ces cookies collectent des informations sur la manière dont les visiteurs utilisent notre Site, par exemple les pages les plus consultées. Ces données anonymes sont utilisées pour améliorer le fonctionnement du Site.</li>
                    <li><strong>Cookies de fonctionnalité</strong> : Ces cookies permettent au Site de se souvenir des choix que vous faites (comme votre nom d'utilisateur, votre langue ou la région dans laquelle vous vous trouvez) et offrent des fonctionnalités améliorées et plus personnalisées.</li>
                    <li><strong>Cookies de ciblage ou publicitaires</strong> : Ces cookies sont utilisés pour vous proposer des publicités plus pertinentes pour vous et adaptées à vos centres d’intérêt. Ils sont également utilisés pour limiter le nombre de fois que vous voyez une publicité et pour mesurer l'efficacité des campagnes publicitaires.</li>
                </ul>
            </section>

            <section>
                <h3>4. Gestion des Cookies</h3>
                <h4>4.1. Consentement à l'utilisation des cookies</h4>
                <p style={{margin:'10px 20px'}}>
                    Lors de votre première visite sur notre Site, une bannière vous informe de la présence de cookies et vous invite à indiquer votre choix. Les cookies ne seront déposés que si vous les acceptez ou si vous poursuivez votre navigation sur le Site en sélectionnant une page autre que la page d'accueil.
                </p>
                <p style={{margin:'10px 20px'}}>
                    Vous pouvez à tout moment modifier vos préférences en matière de cookies en accédant aux paramètres de votre navigateur ou en utilisant les outils fournis sur notre Site.
                </p>

                <h4>4.2. Désactivation des cookies via votre navigateur</h4>
                <p style={{margin:'10px 20px'}}>
                    Vous avez la possibilité de configurer votre navigateur pour qu'il refuse l’enregistrement de cookies sur votre terminal ou qu'il les supprime à tout moment. Cependant, la désactivation des cookies nécessaires au fonctionnement du Site peut altérer votre expérience utilisateur.
                </p>
                <p style={{margin:'10px 20px'}}>
                    Voici comment configurer les principaux navigateurs :
                </p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Google Chrome</strong> : Menu &gt; Paramètres &gt; Confidentialité et sécurité &gt; Cookies et autres données de site.</li>
                    <li><strong>Mozilla Firefox</strong> : Menu &gt; Options &gt; Vie privée et sécurité &gt; Cookies et données de sites.</li>
                    <li><strong>Microsoft Edge</strong> : Menu &gt; Paramètres &gt; Cookies et autorisations de site &gt; Gérer et supprimer les cookies et les données du site.</li>
                    <li><strong>Safari</strong> : Préférences &gt; Confidentialité &gt; Cookies et données de site web.</li>
                </ul>
            </section>

            <section>
                <h3>5. Durée de Conservation des Cookies</h3>
                <p style={{margin:'10px 20px'}}>
                    Les cookies déposés sur votre terminal sont conservés pour une durée maximale de 13 mois. À l’expiration de ce délai, votre consentement sera à nouveau requis pour leur dépôt.
                </p>
            </section>

            <section>
                <h3>6. Cookies Tiers</h3>
                <p style={{margin:'10px 20px'}}>
                    Notre Site peut contenir des cookies émis par des tiers (agences de publicité, réseaux sociaux, partenaires, etc.) qui permettent à ces derniers de collecter des informations de navigation vous concernant. Alvy n'a pas de contrôle sur l'utilisation de ces cookies par les tiers, et vous êtes invités à consulter leurs propres politiques de confidentialité.
                </p>
            </section>

            <section>
                <h3>7. Vos Droits</h3>
                <p style={{margin:'10px 20px'}}>
                    Conformément à la réglementation applicable, vous disposez de droits d'accès, de rectification, d’effacement, de limitation du traitement, d’opposition et de portabilité concernant vos données personnelles. Vous pouvez exercer ces droits en contactant notre Délégué à la Protection des Données (DPO) à l’adresse suivante : <a href="mailto:dpo@alvy-services.com">dpo@alvy-services.com</a> ou par courrier à Alvy, 60 rue François 1er, 75008 Paris.
                </p>
                <p style={{margin:'10px 20px'}}>
                    Vous pouvez également introduire une réclamation auprès de l’autorité de contrôle compétente (en France, la CNIL).
                </p>
            </section>

            <section>
                <h3>8. Modifications de la Politique</h3>
                <p style={{margin:'10px 20px'}}>
                    Alvy se réserve le droit de modifier la présente Politique de Cookies à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette page pour rester informé(e) de nos pratiques en matière de cookies.
                </p>
            </section>

            <section>
                <h3>9. Contact</h3>
                <p style={{margin:'10px 20px'}}>
                    Pour toute question concernant cette politique ou le traitement de vos données personnelles, vous pouvez contacter notre Délégué à la Protection des Données (DPO) par email à l’adresse suivante : <a href="mailto:dpo@alvy-services.com">dpo@alvy-services.com</a> ou par courrier à l'adresse suivante : Alvy, 60 rue François 1er, 75008 Paris.
                </p>
            </section>
        </div>
    </div>
  )
}

export default CookiePolicy