import React, { useState, useEffect } from 'react';
import { Button, TextField, Typography, Alert, Checkbox, FormControlLabel, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { TimePicker, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { post, get } from '../../services/apiClient';

// Extensions de Dayjs pour gérer les semaines ISO
dayjs.extend(isoWeek);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const PlanningCreator = ({ planning: propPlanning, planningID: propPlanningID, quoteID: propQuoteID }) => {
  const [planning, setPlanning] = useState({});
  const [date, setDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [repeatWeekly, setRepeatWeekly] = useState(false);
  const [error, setError] = useState('');
  const [quoteID, setQuoteID] = useState(propQuoteID || '');
  const [planningID, setPlanningID] = useState(propPlanningID || `P-${Date.now().toString().slice(-6)}`);
  const [quoteIDs, setQuoteIDs] = useState([]); // Stocker les QuoteID récupérés
  const [filteredQuoteIDs, setFilteredQuoteIDs] = useState([]); // Stocker les QuoteID filtrés

  // Fonction pour récupérer les QuoteID depuis l'API
  const fetchQuoteIDs = async () => {
    try {
      const response = await get('/admin/quoteIDs');
      setQuoteIDs(response);
      setFilteredQuoteIDs(response); // Initialiser les QuoteIDs filtrés
    } catch (error) {
      console.error('Erreur lors de la récupération des QuoteID:', error);
    }
  };

  useEffect(() => {
    fetchQuoteIDs(); // Récupérer les QuoteID à l'initialisation
    if (propQuoteID) setQuoteID(propQuoteID);
    if (propPlanningID) setPlanningID(propPlanningID);
    if (propPlanning) {
      // Trier les dates dès la réception du planning
      const sortedPlanning = Object.keys(propPlanning)
        .sort((a, b) => dayjs(a).diff(dayjs(b)))
        .reduce((obj, key) => {
          obj[key] = propPlanning[key];
          return obj;
        }, {});
      setPlanning(sortedPlanning);
    }
  }, [propQuoteID, propPlanning, propPlanningID]);

  // Fonction pour filtrer les QuoteIDs en fonction de l'entrée de l'utilisateur
  const handleQuoteIDChange = (event, value) => {
    setQuoteID(value);

    if (!value) {
      setFilteredQuoteIDs(quoteIDs);
    } else {
      const filtered = quoteIDs.filter(id => id.includes(value));
      setFilteredQuoteIDs(filtered);
    }
  };

  // Fonction pour calculer le nombre d'heures entre l'heure de début et l'heure de fin
  const calculateHours = (start, end) => {
    if (!start || !end) return 0;
    const diff = dayjs(end).diff(dayjs(start), 'hour', true);
    return diff > 0 ? diff : 0;
  };

  // Fonction pour vérifier si les créneaux se chevauchent
  const isOverlapping = (date, newSlot) => {
    return planning[date]?.some(
      (slot) =>
        dayjs(slot.startTime).isBefore(newSlot.endTime) &&
        dayjs(slot.endTime).isAfter(newSlot.startTime)
    );
  };

  // Fonction pour ajouter un créneau
  const addSlot = () => {
    if (!date || !startTime || !endTime) {
      setError('Tous les champs doivent être remplis.');
      return;
    }

    const formattedDate = date.format('YYYY-MM-DD');
    const newSlot = {
      startTime: startTime.format('HH:mm'),
      endTime: endTime.format('HH:mm'),
      hours: calculateHours(startTime, endTime),
    };

    if (isOverlapping(formattedDate, newSlot)) {
      setError('Les créneaux ne doivent pas se chevaucher.');
      return;
    }

    let updatedPlanning = { ...planning };
    if (!updatedPlanning[formattedDate]) {
      updatedPlanning[formattedDate] = [];
    }

    updatedPlanning[formattedDate].push(newSlot);

    // Répétition hebdomadaire
    if (repeatWeekly) {
      for (let i = 1; i <= 3; i++) {
        const repeatedDate = dayjs(date).add(i, 'week').format('YYYY-MM-DD');
        if (!updatedPlanning[repeatedDate]) {
          updatedPlanning[repeatedDate] = [];
        }
        updatedPlanning[repeatedDate].push({ ...newSlot });
      }
    }

    // Tri par date de la plus proche à la plus lointaine
    const sortedPlanning = Object.keys(updatedPlanning)
      .sort((a, b) => dayjs(a).diff(dayjs(b)))
      .reduce((obj, key) => {
        obj[key] = updatedPlanning[key];
        return obj;
      }, {});

    setPlanning(sortedPlanning);
    setDate(null);
    setStartTime(null);
    setEndTime(null);
    setError('');
  };

  // Fonction pour supprimer un créneau
  const removeSlot = (date, index) => {
    let updatedPlanning = { ...planning };
    updatedPlanning[date].splice(index, 1);
    if (updatedPlanning[date].length === 0) delete updatedPlanning[date]; // Supprime la date si aucun créneau n'y est associé
    setPlanning(updatedPlanning);
  };

  // Fonction pour calculer le total d'heures pour chaque semaine
  const calculateTotalHoursForWeek = (weekSlots) => {
    return weekSlots.reduce((total, [date, slots]) => {
      return total + slots.reduce((slotTotal, slot) => slotTotal + slot.hours, 0);
    }, 0).toFixed(2);
  };

  // Fonction pour calculer le numéro de la semaine (du lundi au dimanche)
  const getWeekNumber = (date) => {
    return dayjs(date).isoWeek();
  };

  // Fonction pour envoyer le planning au backend
  const submitPlanning = async () => {
    if (!quoteID) {
      setError('L\'identifiant du devis est requis.');
      return;
    }

    if (Object.keys(planning).length === 0) {
      setError('Le planning ne peut pas être vide.');
      return;
    }

    try {
      await post('/admin/save-planning', { planning, planningID, quoteID });
      alert('Planning enregistré avec succès');
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du planning :', error);
      alert('Erreur lors de l\'enregistrement du planning');
    }
  };

  // Déterminer le nombre maximum de créneaux horaires pour générer les colonnes dynamiques
  const maxSlots = Math.max(...Object.values(planning).map((slots) => slots.length), 0);

  // Fonction pour afficher chaque bloc de semaine avec ombre et marge, et total d'heures
  const renderPlanningByWeek = () => {
    let weeks = [];
    let weekSlots = [];
    let currentWeek = null;

    Object.entries(planning).forEach(([date, slots]) => {
      const weekNumber = getWeekNumber(date); // Utilisation de la semaine ISO (lundi - dimanche)

      if (currentWeek !== weekNumber) {
        if (weekSlots.length > 0) {
          weeks.push(renderWeek(weekSlots, currentWeek));
        }
        currentWeek = weekNumber;
        weekSlots = [];
      }

      weekSlots.push([date, slots]);
    });

    // Ajouter la dernière semaine
    if (weekSlots.length > 0) {
      weeks.push(renderWeek(weekSlots, currentWeek));
    }

    return weeks;
  };

  const renderWeek = (weekSlots, weekNumber) => {
    const totalHours = calculateTotalHoursForWeek(weekSlots);
    return (
      <div key={weekNumber} className="br-l" style={{ marginBottom: '20px', padding: '15px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
        <TableContainer component={Paper} elevation={0} sx={{ width: 'fit-content' }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell>Day</TableCell>
                {[...Array(maxSlots)].map((_, index) => (
                  <TableCell key={index}>{`Slot ${index + 1}`}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {weekSlots.map(([date, slots]) => (
                <TableRow key={date}>
                  <TableCell>{date}</TableCell>
                  <TableCell>{dayjs(date).format('dddd')}</TableCell>
                  {[...Array(maxSlots)].map((_, index) => (
                    <TableCell key={index}>
                      {slots[index] ? (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {`${slots[index].startTime} - ${slots[index].endTime}`}
                          <IconButton onClick={() => removeSlot(date, index)} size="small" sx={{ color: 'gray' }}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </div>
                      ) : ''}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex row hsb vcenter">
          <div className="br-l" style={{ marginTop: '10px', padding: '10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
            Week {weekNumber}
          </div>
          <div className="br-l" style={{ marginTop: '10px', padding: '10px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
            Weekly hours : {totalHours} h
          </div>
        </div>
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {error && <Alert severity="error">{error}</Alert>} {/* Affichage des erreurs */}

      {/* Input pour le Quote ID si non fourni en prop */}
      {!propQuoteID && (
        <div style={{ marginBottom: '20px' }}>
          <Autocomplete
            freeSolo
            options={filteredQuoteIDs}
            value={quoteID}
            onInputChange={handleQuoteIDChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Quote ID"
                size="small"
                required
                sx={{ width: '150px' }}
              />
            )}
          />
        </div>
      )}

      {/* Formulaire pour choisir un créneau */}
      <div className="flex row hsb vcenter gap1">
        <div className="flex row gap1">
          <DatePicker
            label="Date"
            value={date}
            onChange={(newValue) => setDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Heure de début"
            value={startTime}
            onChange={(newValue) => setStartTime(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <TimePicker
            label="Heure de fin"
            value={endTime}
            onChange={(newValue) => setEndTime(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={repeatWeekly}
                onChange={() => setRepeatWeekly(!repeatWeekly)}
                color="primary"
              />
            }
            label="Repeat 4 weeks"
          />
        </div>
        <button onClick={addSlot}
          className="br-l flex row hcenter vcenter"
          style={{ backgroundColor: '#007bff', width: 'fit-content', height: 'fit-content', padding: '5px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          <AddIcon sx={{ color: '#fff' }} />
        </button>
      </div>

      {/* Tableau des créneaux ajoutés, par bloc de semaines */}
      <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
        Planning
      </Typography>
      {renderPlanningByWeek()}

      {/* Bouton pour soumettre le planning */}
      <div className="flex row hend">
        <Button onClick={submitPlanning} variant="contained" color="primary"
          disabled={Object.keys(planning).length === 0}
          style={{ marginTop: '20px', backgroundColor: '#007bff', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
          Save
        </Button>
      </div>
    </LocalizationProvider>
  );
};

export default PlanningCreator;
