import React from 'react';
import { Navigate, useOutletContext } from 'react-router-dom';

const Dashboard = () => {
  const { role } = useOutletContext(); // Le rôle est récupéré depuis PrivateRoute

  if (role === 'Admin') {
    return <Navigate to="/admin/dashboard/home" />;
  } else if (role === 'Agent') {
    return <Navigate to="/agent/dashboard" />;
  } else if (role === 'Client') {
    return <Navigate to="/client/dashboard" />;
  } else {
    return <div>Rôle non reconnu</div>;
  }
};

export default Dashboard;
