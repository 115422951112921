import React, { useEffect, useState } from 'react';
import { get } from '../../services/apiClient';
import { Typography, IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import GoogleMapComponent from '../GoogleMaps';
import QuoteSummary from './QuoteSummary';
import UsersTable from './UsersTable';

const MissionSummary = ({ mission }) => {
  const [clientDetails, setClientDetails] = useState(null);
  const [people, setPeople] = useState([]); // Merged state for representatives, agents, and managers
  const [quoteDetails, setQuoteDetails] = useState(null);
  const [planningDetails, setPlanningDetails] = useState(null);

  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('FirstName');

  useEffect(() => {
    if (mission) {
      fetchClientByID(mission.ClientID);
      fetchQuoteByID(mission.QuoteID);
      fetchUsersByIDs([...mission.Representatives, ...mission.Agents, ...mission.Managers]); // Fetch all users
      // Uncomment if you need to fetch planning details:
      // fetchPlanningByID(mission.PlanningID);
    }
  }, [mission]);

  // Fetch client details
  const fetchClientByID = async (clientID) => {
    try {
      const response = await get(`/admin/clients/${clientID}`);
      setClientDetails(response);
    } catch (error) {
      console.error(`Error fetching client with ID ${clientID}:`, error);
    }
  };

  // Fetch all users (representatives, agents, managers)
  const fetchUsersByIDs = async (userIDs) => {
    try {
      const userPromises = userIDs.map(async (userID) => {
        const response = await get(`/admin/users/${userID}`);
        return response;
      });
      const users = await Promise.all(userPromises);
      console.log('user', users)
      setPeople(users); // Set all users in the people state
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // Fetch quote details
  const fetchQuoteByID = async (quoteID) => {
    try {
      const response = await get(`/admin/quotes/${quoteID}`);
      setQuoteDetails(response);
    } catch (error) {
      console.error(`Error fetching quote with ID ${quoteID}:`, error);
    }
  };

  // Handle copying to clipboard
  const handleCopy = (label, value) => {
    navigator.clipboard.writeText(value);
    alert(`${label} copied to clipboard`);
  };

  // Handle sorting when column header is clicked
  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
  };

  return (
    <div className="flex col gap2" style={{ padding: '20px 0', marginTop: '20px' }}>
      <div className="flex row hsb gap1">
        <Typography variant="h4">
          {mission.MissionID} - {clientDetails ? clientDetails.CompanyName : 'Loading...'}
        </Typography>
        <div className="flex row vcenter">
          <p>{mission.Address}</p>
          <IconButton onClick={() => handleCopy('Address', mission.Address)} sx={{ margin: '0px 5px', padding: 0 }}>
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        </div>
      </div>

      <div className="flex row hsb gap2">
        {/* Quote details summary */}
        {quoteDetails && (
          <div className="flex col gap2 bs-3 br-l" >
            <QuoteSummary selectedQuote={quoteDetails} />
          </div>
        )}

        <div className="bs-3 br-l" style={{ width: '100%', height: '300px' }}>
          <GoogleMapComponent address={mission.Address} />
        </div>
      </div>

      {/* People (Representatives, Agents, Managers) */}
      <div className="flex row wrap gap1">
          <UsersTable specificEmails={people.map(person => person.Email)}/>
      </div>
      <div className="flex bs-3 br-l" style={{padding:'20px 30px'}}>
          <h5>PlanningsTable planningID=mission.PlanningID</h5>
      </div>
      <div className="flex col gap1 bs-3 br-l" style={{padding:'20px 30px'}}>
          <h5>Intervention Planning</h5>
          <p>Vue d'un joli planning avec le nom des Agents </p>
      </div>

      <div className="flex bs-3 br-l" style={{padding:'20px 30px'}}>
          <h5>Liste de tache par intervention</h5>
      </div>
      <div className="flex bs-3 br-l" style={{padding:'20px 30px'}}>
          <h5>Liste des équipements et consommables par intervention</h5>
      </div>
    </div>
  );
};

export default MissionSummary;
