import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';
import { OpenInNew } from '../Icons';
import { openSurfaceBreakdownWindow } from '../quoteForm/openWindows';

const QuoteSummary = ({ selectedQuote }) => {
  // Calcul des valeurs à partir de selectedQuote
  let HoursMonthly = 0;
  let ConsumableCosts = 0;
  let PriceMonthly = 0;

  if (selectedQuote && selectedQuote.RequestedServices) {
    Object.entries(selectedQuote.RequestedServices).forEach(([zoneName, services]) => {
      services.forEach((service) => {
        HoursMonthly += service.hm || 0;
        ConsumableCosts += service.cc || 0;
        PriceMonthly += service.mp || 0;
      });
    });
  }

  const HoursWeekly = HoursMonthly / (52 / 12);
  const FixedCosts = 100;
  const PriceHourly = (PriceMonthly + FixedCosts) / HoursMonthly;

  return (
    <div className="flex col " style={{ padding: '20px', marginLeft: '20px', width: '300px' }}>
      <div className="flex row hsb gap2">
        <Typography variant="h5">Quote Summary</Typography>
        {selectedQuote && selectedQuote.RoomBreakdown && (
          <button
            onClick={() => openSurfaceBreakdownWindow(selectedQuote.RoomBreakdown, selectedQuote.RequestedServices)}
            style={{ padding: 0, margin: "0 5px" }}
          >
            <OpenInNew fill='#007bff' />
          </button>
        )}
      </div>
      <TableContainer className='flex col vcenter' component={Paper} elevation={0} sx={{ backgroundColor: '#efefef', marginTop: '10px', height: '100%' }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell>Sector</TableCell>
              <TableCell >
                <div style={{overflow:'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', width: '100px'}}>
                  {selectedQuote ? selectedQuote.Sector : "..."}
                </div>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Area Size</TableCell>
              <TableCell>{selectedQuote ? selectedQuote.AreaSize : "..."} m²</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Hours Monthly</TableCell>
              <TableCell>{selectedQuote ? HoursMonthly.toFixed(2) : "..."}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Hours Weekly</TableCell>
              <TableCell>{selectedQuote ? HoursWeekly.toFixed(2) : "..."}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Consumable Costs</TableCell>
              <TableCell>{selectedQuote ? ConsumableCosts.toFixed(2) : "..."} €</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Fixed Costs</TableCell>
              <TableCell>{selectedQuote ? FixedCosts.toFixed(0) : "..."} €</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Price Monthly</TableCell>
              <TableCell>{selectedQuote ? PriceMonthly.toFixed(2) : "..."} €</TableCell>
            </TableRow>

            <TableRow>
              <TableCell>Price Hourly</TableCell>
              <TableCell>{selectedQuote ? PriceHourly.toFixed(2) : "..."} €</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default QuoteSummary;
