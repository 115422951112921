export const openSurfaceBreakdownWindow = (roomBreakdown, results) => {
    console.log('roomBreakdown',roomBreakdown)
    console.log('results',results)
    const surfaceWindow = window.open('', '_blank', 'width=1000,height=600');
    surfaceWindow.document.write(`
        <html>
        <head>
            <title>Décomposition de la Surface</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    padding: 20px;
                    background-color: #f5f5f5;
                }
                h2 {
                    color: #333;
                    font-size: 24px;
                    border-bottom: 2px solid #007bff;
                    padding-bottom: 10px;
                    margin-bottom: 20px;
                }
                table {
                    width: 100%;
                    border-collapse: collapse;
                    margin-bottom: 20px;
                }
                table th, table td {
                    border: 1px solid #ddd;
                    padding: 8px;
                    text-align: left;
                }
                table th {
                    background-color: #007bff;
                    color: white;
                }
                table tr:nth-child(even) {
                    background-color: #f2f2f2;
                }
                table tr:hover {
                    background-color: #ddd;
                }
                .service-table {
                    margin-top: 10px;
                    margin-left: auto;
                    margin-right: auto;
                    border: 1px solid #ccc;
                    table-layout: auto; /* Allow columns to adjust */
                    width: 100%;
                    font-size: 0.8rem;
                }
                .service-table th, .service-table td {
                    padding: 5px;
                    text-align: left;
                }
                .service-table th {
                    background-color: #4b4f56;
                    color: white;
                }
                .service-name {
                    width: auto;  /* Let it take the remaining space */
                }
                .service-hm, .service-cc, .service-mp {
                    white-space: nowrap;  /* Prevent the text from wrapping */
                    width: 1%;  /* Set minimal width so content defines it */
                }
                .service-frequency {
                    width: 110px;
                }
            </style>
        </head>
        <body>
            <h2>Décomposition de la Surface par Zones</h2>
            <table>
                <thead>
                    <tr>
                        <th>Zone</th>
                        <th>Surface (m²)</th>
                    </tr>
                </thead>
                <tbody>
    `);

    // Pour chaque zone
    roomBreakdown.forEach((room) => {
        const zoneName = room.roomType;
        const zoneServices = results[zoneName] || [];

        // Ecrire la zone
        surfaceWindow.document.write(`
            <tr>
                <td>${zoneName}</td>
                <td>${room.size}</td>
            </tr>
        `);

        // Si des services existent pour la zone
        if (zoneServices.length > 0) {
            surfaceWindow.document.write(`
                <tr>
                    <td colspan="2">
                        <table class="service-table">
                            <thead>
                                <tr>
                                    <th class="service-name">Nom du Service</th>
                                    <th class="service-frequency">Fréquence</th>
                                    <th class="service-hm">HM</th>
                                    <th class="service-cc">CC</th>
                                    <th class="service-mp">MP</th>
                                </tr>
                            </thead>
                            <tbody>
            `);

            // Parcourir les services de la zone
            zoneServices.forEach((service) => {
                surfaceWindow.document.write(`
                    <tr>
                        <td>${service.name}</td>
                        <td>${service.frequency}</td>
                        <td>${(service.hm*60).toFixed(2) || 0}</td>
                        <td>${service.cc.toFixed(2) || 0}</td>
                        <td>${service.mp.toFixed(2) || 0}</td>
                    </tr>
                `);
            });

            surfaceWindow.document.write(`
                            </tbody>
                        </table>
                    </td>
                </tr>
            `);
        }
    });

    surfaceWindow.document.write(`
                </tbody>
            </table>
        </body>
        </html>
    `);
    surfaceWindow.document.close();
};
