import React, { useState, useEffect } from 'react';
import { post } from '../../services/apiClient';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button,
  Select, MenuItem, InputLabel, FormControl
} from '@mui/material';

function UserCreator({ open, onClose, user }) {
  const [userData, setUserData] = useState({
    FirstName: user?.FirstName || '',
    LastName: user?.LastName || '',
    Email: user?.Email || '',
    Phone: user?.Phone || '',
    Address: user?.Address || '',
    Company: user?.Company || '',
    Position: user?.Position || '',
    Role: user?.Role || ''
  });

  useEffect(() => {
    if (user) {
      setUserData({
        FirstName: user.FirstName,
        LastName: user.LastName,
        Email: user.Email,
        Phone: user.Phone,
        Address: user.Address,
        Company: user.Company,
        Position: user.Position,
        Role: user.Role
      });
    }
  }, [user]);

  // Handle input change in the form
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const endpoint = user ? '/admin/update-user' : '/admin/create-user';
      await post(endpoint, userData);
      alert(user ? 'User updated successfully' : 'User created successfully');
      onClose();
    } catch (error) {
      console.error('Error saving the user:', error);
      alert('Error saving the user');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{user ? 'Edit User' : 'Create User'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="First Name"
          name="FirstName"
          fullWidth
          value={userData.FirstName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Last Name"
          name="LastName"
          fullWidth
          value={userData.LastName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Email"
          name="Email"
          fullWidth
          value={userData.Email}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Phone"
          name="Phone"
          fullWidth
          value={userData.Phone}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Company"
          name="Company"
          fullWidth
          value={userData.Company}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Address"
          name="Address"
          fullWidth
          value={userData.Address}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Position"
          name="Position"
          fullWidth
          value={userData.Position}
          onChange={handleChange}
        />

        <FormControl fullWidth margin="dense">
          <InputLabel>Role</InputLabel>
          <Select
            name="Role"
            value={userData.Role}
            onChange={handleChange}
          >
            <MenuItem value="Client">Client</MenuItem>
            <MenuItem value="Manager">Manager</MenuItem>
            <MenuItem value="Agent">Agent</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          {user ? 'Update User' : 'Create User'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserCreator;
