

import React from 'react';
import { Link } from 'react-router-dom';

// import { useRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { InView } from '../components/Utils';
import CTASection from '../components/CTASection';
// import { Proposal } from '../components/Proposal';
// import Features from './Features';

import { VideoBackground } from '../components/Utils';
import Video1 from "../videos//853889-hd_1920_1080_25fps.mp4";
import Video2 from "../videos/1654216-hd_1920_1080_30fps.mp4";
import Video3 from "../videos/2034261-uhd_3840_2160_30fps.mp4";
import Video4 from "../videos/3191900-uhd_3840_2160_25fps.mp4";



// import herobg1 from '../images/hero-bg1.jpg';
import PeopleImg1 from '../images/people-at-work-1.jpg';
import CleaningIconBlue from '../icons/cleaning_services_blue.svg';
import SecurityIconBlue from '../icons/verified_user_24dp_FILL0_wght400_GRAD0_opsz24.svg';
// import HostIconBlue from '../icons/support_agent_24dp_FILL0_wght400_GRAD0_opsz24.svg';
// import MaintenanceIconBlue from '../icons/engineering_24dp_FILL0_wght400_GRAD0_opsz24.svg';
import TreeIconBlue from '../icons/nature_24dp_FILL0_wght400_GRAD0_opsz24 (1).svg';
// import LogisticIconBlue from '../icons/local_shipping_24dp_FILL0_wght400_GRAD0_opsz24.svg';
// import SettingsIconBlue from '../icons/settings_24dp_FILL0_wght400_GRAD0_opsz24.svg';
// import ArrowRightBlue from '../icons/arrow_right_alt_24dp_FILL0_wght400_GRAD0_opsz24.svg';
// import { ReactComponent as ArrowRight } from "../icons/arrow-right.svg";
import { ContactIcon, ArrowPlay, ArrowRight } from '../components/Icons';
// import {} from './Icons';

// import SimplicityBg1 from '../images/istockphoto-1337476941-1024x1024.jpg';
// import FlexibilityBg2 from '../images/flexibility-bg2.jpg';
// // import ProximityBg1 from '../images/proximity-bg1.jpg';
// import ProximityBg2 from '../images/proximity-bg2.jpg';

// import QualityBg1 from '../images/quality-bg2.jpg';
// import OrganisationBg1 from '../images/organisation-bg2.jpg';
// import './Home.scss';

// import CleanImg2 from '../images/cleaning-img2.jpg';
// import HostImg from '../images/cleaning-img2.jpg';


// import Headshot1 from '../images/profil-img1-1024x1024.jpg';
// import Headshot2 from '../images/profil-img2-1024x1024.jpg';
// import Headshot3 from '../images/profil-img3-1024x1024.jpg';
// import Headshot4 from '../images/profil-img4-1024x1024.jpg';
// import Headshot5 from '../images/profil-img5-1024x1024.jpg';
// import Headshot6 from '../images/profil-img6-1024x1024.jpg';

// import { TwitterIcon, LinkedInIcon } from '../components/Icons';

// import Illustration0 from '../images/illustrations/illustration-0.jpg';
// import Illustration1 from '../images/illustrations/illustration-1.png';
// import Illustration2 from '../images/illustrations/illustration-2.jpg';
// import Illustration3 from '../images/illustrations/illustration-3.jpg';

import Napkin2 from '../images/illustrations/napkin-2.svg';
// import ShowCaseImg1 from "../images/cleaning-img1-1024x1024.jpg";
// import ShowCaseImg2 from "../images/security-img1-1024x1024.jpg";
// import ShowCaseImg3 from "../images/host-img1-1024x1024.jpg";
// import ShowCaseImg4 from "../images/green-img1-1024x1024.jpg";

// import FitnessPark from "../images/customers/fitness-park.png";

// import QuoteForm from '../components/quoteForm/QuoteForm';
// import ArticlesList from '../components/ArticlesList';
import { ArticleCards } from '../components/ArticleCards';
// import { BorderBottom, BorderRight } from '@mui/icons-material';

const Home = () => {

  const videoSources = [
    Video1,
    Video2,
    Video3,
    Video4
  ];
  // const { ref: myRef, inView: myElementIsVisible } = useInView();

  const { ref: servicesRef, inView: servicesVisible } = useInView();
  const { ref: valuesRef, inView: valuesVisible } = useInView();
  const { ref: proposalRef, inView: proposalVisible } = useInView();
  const { ref: showCaseRef, inView: showCaseVisible } = useInView();
  const { ref: teamRef, inView: teamVisible } = useInView();
  const { ref: joinusRef, inView: joinusVisible } = useInView();
  // const { ref: newsRef, inView: newsVisible } = useInView();

  // const { ref: ctaRef, inView: ctaVisible } = useInView();

  // *******************************
  // const [showForm, setShowForm] = useState(false);

  const handleShowQuoteForm = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    // setShowForm(true);
    window.open('/quote-form', '_blank'); // Ouvre le formulaire dans un nouvel onglet
  };
  const handleShowContactForm = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    // setShowForm(true);
    window.open('/contact-form'); 
  };
  // const handleCloseForm = () => {
  //   setShowForm(false);
  // };
  return (

      
      <div className="home flex col">

        
        {/* <section id="section1" 
        className="hero bg-parallax bg-animated " 
        style={{backgroundImage: `url(${HeroBg5})`, 
        }}>

          <div className=" header opacity-1 flex col vend gap4 "
          style={{
            minHeight: "110vh", 
            paddingTop: "200px",
            paddingBottom: "7rem",
            paddingLeft: "10vw"}}>
            <div>
              <h1>NOTRE METIER</h1>
              <h1>FACILITER LE VOTRE</h1>
            </div>
            <h5 className='fnt-white '
            style={{maxWidth:"800px"}}>
            Redéfinissez avec nous la gestion des espaces professionelles pour vous concentrer sur ce qui compte 
            vraiment</h5>
            <div className='flex row wrap gap1'>
              <h6 className='box-1'>PROPRETE</h6>
              <h6 className='box-1'>GARDIENNAGE</h6>
              <h6 className='box-1'>ESPACES VERTS</h6>
            </div>


            
            <div className="flex row wrap gap2" >
              <div className='btn-start-container complementary br-m' style={{width:'275px'}}>
                <p>Contactez-nous</p>


                <Link to="/contact-form" > 
                  <div 
                  className={`btn-start complementary pulse infinite white left bs-3 ${servicesVisible ? "visible" : "" } ${valuesVisible ? "visible" : "" } ${proposalVisible ? "visible" : "" } ${showCaseVisible ? "visible" : "" } ${teamVisible ? "visible" : "" } ${joinusVisible ? "visible" : "" }`} 
                  onClick={handleShowContactForm}>
                    <ContactIcon />
                  </div>
                </Link>

              </div>         
              <div className='btn-start-container primary br-m'>
                <p>Obtenez votre devis</p>
                <Link to="/quote-form" > 
                  <div 
                  className={`btn-start primary pulse infinite white right bs-3 ${servicesVisible ? "visible" : "" } ${valuesVisible ? "visible" : "" } ${proposalVisible ? "visible" : "" } ${showCaseVisible ? "visible" : "" } ${teamVisible ? "visible" : "" } ${joinusVisible ? "visible" : "" }`} 
                  onClick={handleShowQuoteForm}>
                    <ArrowPlay />
                  </div>
                </Link>
              </div>
            </div>

            

          </div>
        </section> */}

        <section style={{width:'100%', height:'650px'}}>
          
          <VideoBackground videoSources={videoSources} />

          <div 
          className='flex col vcenter hcenter'
          style={{
              width:'100%', height:'100%', position: 'relative', top: '0', zIndex: -1, 
              backgroundColor:'#000',opacity:'0.75'}}>

            {/* Autres éléments au-dessus de la vidéo */}
          </div>
          <div className='flex col' style={{position: 'absolute', top: '0', margin:'7.5vw 5vw', gap:'1vw'}}>
            <div style={{marginTop:'100px'}}>
              <h2 style={{color:'#fff', letterSpacing:'2.5px'}}>NOTRE METIER</h2>
              <h2 style={{color:'#fff', letterSpacing:'2.5px'}}>FACILITER LE VOTRE</h2>
            </div>
            <h5 className='fnt-white '
              style={{maxWidth:"800px"}}>
              Redéfinissez avec nous la gestion des espaces professionelles pour vous concentrer sur ce qui compte 
              vraiment</h5>
            <div className='flex row wrap gap1'  style={{marginTop:'20px'}}>
              <h6 className='box-1'>PROPRETE</h6>
              <h6 className='box-1'>GARDIENNAGE</h6>
              <h6 className='box-1'>ESPACES VERTS</h6>
            </div>

            <div className="flex row wrap gap2"  style={{marginTop:'20px'}}>
              <div className='btn-start-container complementary br-m' style={{width:'220px'}}>
                <Link to="/contact-form" onClick={handleShowContactForm}><p>Contactez-nous</p></Link>
                <Link to="/contact-form" > 
                  <div 
                  className={`btn-start complementary pulse infinite white left bs-3 ${servicesVisible ? "visible" : "" } ${valuesVisible ? "visible" : "" } ${proposalVisible ? "visible" : "" } ${showCaseVisible ? "visible" : "" } ${teamVisible ? "visible" : "" } ${joinusVisible ? "visible" : "" }`} 
                  onClick={handleShowContactForm}>
                    <ContactIcon />
                  </div>
                </Link>

              </div>         
              <div className='btn-start-container primary br-m'>
                <Link to="/contact-form" onClick={handleShowQuoteForm}><p>Obtenez votre devis</p></Link>
                <Link to="/quote-form" > 
                  <div 
                  className={`btn-start primary pulse infinite white right bs-3 ${servicesVisible ? "visible" : "" } ${valuesVisible ? "visible" : "" } ${proposalVisible ? "visible" : "" } ${showCaseVisible ? "visible" : "" } ${teamVisible ? "visible" : "" } ${joinusVisible ? "visible" : "" }`} 
                  onClick={handleShowQuoteForm}>
                    <ArrowPlay />
                  </div>
                </Link>
              </div>
            </div>
          </div>

        </section>

          <section id="section2" className="services flex col hcenter gap1" 
          style={{padding:'0 5vw', marginBottom:'100px'}}>

            <div className='flex col hcenter gap1' style={{position:'relative', top:'-100px'}}>

              <div  className=' flex col hcenter gap4 bs-1 br-l' 
              style={{padding:'40px 60px', maxWidth:'600px', textAlign:'center', backgroundColor:'#efefef'}}>
                <h4>La gestion des espaces professionnels que vous attendiez</h4>
                <p style={{lineHeight:'22px'}}>
                  Chez Alvy, nous réinventons le service aux entreprise pour répondre à toutes vos exigences. Que vous 
                  ayez besoin d'un entretien quotidien, d'une remise à neuf après travaux, ou d'un service événementiel, 
                  notre équipe est là pour vous. Des bureaux aux locaux industriels, nous offrons des solutions sur 
                  mesure, pour des environnement toujours impeccable.
                </p>
                <div className="flex row gap2">
                  <a href="/about" className='btn-1 tertiary'>Alvy</a>
                  <a href="/services" className='btn-1 primary'>Nos Services</a>
                </div>
              </div>


            <div  >
              <div className='flex col ' >
                <img src={Napkin2} alt="" style={{width:'90vw', maxWidth:'700px', margin:'100px 0'}}/>
              </div>

              <div ref={servicesRef} className='flex row hcenter wrap gap2'>
                <InView className="fade-in flex col hcenter gap1 br-l bs-3" style={{padding:'20px', maxWidth:'250px'}}>
                  <img className='icon' src={CleaningIconBlue} alt="" />
                  <h5>Propreté</h5>
                  <p style={{textAlign:'center'}}>
                    Assurez-vous d'un environnement impeccable avec notre service de propreté professionnel.
                  </p>
                </InView>
                <InView className="fade-in flex col hcenter gap1 br-l bs-3" style={{padding:'20px', maxWidth:'250px'}}>
                  <img className='icon' src={SecurityIconBlue} alt="" />
                  <h5>Gardiennage</h5>
                  <p style={{textAlign:'center'}}>
                    Protégez vos locaux et vos parties prenantes avec notre expertise en sécurité et nos agents qualifiés
                  </p>
                </InView>
                <InView className="fade-in flex col hcenter gap1 br-l bs-3" style={{padding:'20px', maxWidth:'250px'}}>
                  <img className='icon' src={TreeIconBlue} alt="" />
                  <h5>Espaces Verts</h5>
                  <p style={{textAlign:'center'}}>
                    Embellissez et entretenez vos espaces verts grâce à notre savoir-faire expert et notre équipe de passionés
                  </p>
                </InView>
              </div>
            </div>

            </div>


            {/* <div className="cards-1">
              <div  className="br-l" style={{
                backgroundImage: `url(${Illustration0})`, 
                width:"650px",
                height:"350px",
                backgroundSize: "cover"}}>
                <div className="opacity-light flex col vsb pad3 bs-3 br-l">
                  <h4>Une expérience simple et flexible en parfaite adéquation avec vos besoins.</h4>
                  <p className='fnt-1'
                  style={{paddingLeft:"3rem",textAlign:"justify"}}>
                  Nous développons pour vous des outils concus pour une gestion des prestations 
                  toujours plus simple. De leur calibration au plus juste de vos besoins à leur gestion quotidienne.</p>
                  <div className="flex row hend">
                    <a href="/services" className='btn-1 white-dot flex row gap0'>
                      <span className='dot'></span>
                      <span className='dot'></span>
                      <span className='dot'></span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="br-l"  style={{
                backgroundImage: `url(${Illustration1})`, 
                width:"650px",
                height:"350px",
                backgroundSize: "cover"}}>
                <div className="opacity-light card-light flex col vsb pad3 bs-3 br-l">
                  <h4>Des processus organisationnels clairs, pertinents et transparents. </h4>
                  <p className="fnt-1"
                  style={{paddingLeft:"3rem",textAlign:"justify"}}>
                  Nous vous proposons des méthodes pensées pour la réduction de vos couts.
                  Nous concevons pour vous des processus imbriqués et décomposables pour être au plus 
                  juste de vos exigences économiques.</p>
                  <div className="flex row hend">
                    <a href="/services" className='btn-1 white-dot flex row  gap0'>
                      <span className='dot'></span>
                      <span className='dot'></span>
                      <span className='dot'></span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="br-l" style={{
                backgroundImage: `url(${Illustration3})`, 
                width:"650px",
                height:"350px",
                backgroundSize: "cover"}}>
                <div className="opacity-light card-light flex col vsb pad3 bs-3 br-l">
                  <h4>La réactivité que vous attendiez</h4>
                  <p className="fnt-1"
                  style={{paddingLeft:"3rem",textAlign:"justify"}}>
                  Nous vous assurons une parfaite fluidité entre vos décisions et 
                  leur application. Nous élaborons continuellement des outils pour que vos intentions se concrétisent en quelques clics.</p>
                  <div className="flex row hend">
                    <a href="/services" className='btn-1 white-dot flex row  gap0'>
                      <span className='dot'></span>
                      <span className='dot'></span>
                      <span className='dot'></span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="br-l" style={{
                backgroundImage: `url(${Illustration2})`, 
                width:"650px",
                height:"350px",
                backgroundSize: "cover"}}>
                <div className="opacity-light card-light flex col vsb pad3 bs-3 br-l">
                  <h4>L'assurance de la qualité d'excecution</h4>
                  <p className="fnt-1"
                  style={{paddingLeft:"3rem",textAlign:"justify"}}>
                  Nous vous garantissons une execution opération irréprochable. Nos collaborations suivent des formations
                  métier et téchnique en continue et notre organisation managériale innovante assure le parfait allignement des intérêts. </p>
                  <div className="flex row hend">
                    <a href="/services" className='btn-1 white-dot flex row  gap0'>
                      <span className='dot'></span>
                      <span className='dot'></span>
                      <span className='dot'></span>
                    </a>
                  </div>
                </div>
              </div>

            </div> */}

              {/* <div className='cards-1 flex wrap br-m ' >
                <InView className="fade-in card light flex col hcenter">
                  <img className='icon' src={CleaningIconBlue} alt="" />
                  <h5>Propreté</h5>
                  <p>Assurez-vous d'un environnement impeccable avec notre service de propreté professionnel.</p>
                </InView>
                
                <InView className="fade-in card light flex col hcenter">
                  <img className='icon' src={SecurityIconBlue} alt="" />
                  <h5>Gardiennage</h5>
                  <p>Protégez vos locaux et vos parties prenantes avec notre expertise en sécurité et nos agents qualifiés</p>
                </InView> */}

                {/* <InView className="item br-m center col bs">
                  <img className='icon' src={HostIconBlue} alt="" />
                  <h5>Accueil</h5>
                  <p>Offrez un accueil chaleureux et professionnel à vos visiteurs dès leur arrivée avec nos hotes expérimentés</p>
                </InView> */}

                {/* <InView className="item br-m center col bs">
                  <img className='icon' src={MaintenanceIconBlue} alt="" />
                  <h5>Maintenance</h5>
                  <p>Garantissez-vous un fonctionnement optimal avec notre service de maintenance mutli-technique.</p>
                </InView> */}

                {/* <InView className="item br-m center col bs">
                  <img className='icon' src={LogisticIconBlue} alt="" />
                  <h5>Logistique</h5>
                  <p>Optimisez vos opérations avec nos solutions logistiques calibrées au plus juste de vos besoins</p>
                </InView> */}

                {/* <InView className="fade-in card light flex col hcenter">
                  <img className='icon' src={TreeIconBlue} alt="" />
                  <h5>Espaces Verts</h5>
                  <p>Embellissez et entretenez vos espaces verts grâce à notre savoir-faire expert et notre équipe de passionés</p>
                </InView>
              </div> */}


          </section>
        

        {/* Missions Section */}
        
          <section id="section3" ref={valuesRef} 
          className="dark-section values flex col hcenter dark-bg-secondary ">
            <InView className="fade-in header">
              <h2>ALVY</h2>
              <span className='text-primary '>Human First</span>
            </InView>

            <InView className='flex row hsb vend'>
              <div className="container">
                <h6 style={{color:'GrayText'}}>01</h6>
                <h6>Notre Mission</h6>
              </div>
              <h6> Faciliter le quotidien</h6>
            </InView>


            <InView className='flex row hsb vend'>
              <div className="container">
                <h6 style={{color:'GrayText'}}>02</h6>
                <h6>Notre Vision</h6>
              </div>
              <h6>Simplifier les relations professionelles</h6>
            </InView>


            <InView className='flex row hsb vend'>
              <div className="container">
                <h6 style={{color:'GrayText'}}>03</h6>
                <h6>Notre Ambition</h6>
              </div>
              <h6>Améliorer nos qualité de vie</h6>
            </InView>

          </section>


          <section id="secton4" ref={proposalRef}
          className='proposal flex col hcenter gap10 dark-section  dark-bg'
          style={{padding: '100px 5vw'}}>
            <InView className='fade-in flex col hstart gap4' style={{width:'100%', BorderBottom: '1px solid gray', padding:'100px 5vw'}}>
              <h2 style={{fontSize: '4rem', lineHeight: '4rem'}}>Notre <br /> Proposition</h2>
              <div className='flex row gap1'>
                <span className='box-1'>PROPRETE</span>
                <span className='box-1'>GARDIENNAGE</span>
                <span className='box-1'>ESPACES VERTS</span>
            </div>
              {/* <h4>S'occuper de tout pour que vous vous concentrez sur ce 
              qui compte vraiment</h4> */}
            </InView>   

              <div className='flex gap5 row wrap hcenter vcenter'>
              <InView className="slide-left card light flex col vcenter" style={{width:'300px', height:'200px'}}>
                  <h4 style={{color:'GrayText'}}>1.1</h4>
                  <h4 style={{color:'#000'}}>Simplicité et Flexibilité</h4>
                </InView>
                <InView className="slide-right card dark flex col ">
                  <p>Recevez votre estimation personnalisée immédiatement, en
                  un message ou quelques clics</p>
                  <p>Calibrez vos prestation au plus juste de vos besoins grace à une 
                  décomposition fine de nos services</p>
                  <p>Ajustez les préstations, les plannings et les lists de tache à chaque 
                  instant du bout du doigt</p>
                </InView>
              </div>

              <div className='flex gap5 row wrap-reverse hcenter vcenter'>
                <InView className="slide-left card dark flex col ">
                  <p>Disponible pour vous 7j/7, connectons-nous par tout les moyens qui 
                  vous conviendront</p>
                  <p>Intervention et présence fréquente, contractuel et sur demande 
                  des gestionnaires et inspecteurs</p>
                  <p>Des notifications et rapports détaillés, consus pour une prise de 
                  décision rapide, simple et pertinente</p>
                </InView>
                <InView className="slide-right card light flex col vcenter" style={{width:'300px', height:'200px'}}>
                  <h4 style={{color:'GrayText'}}>1.2</h4>
                  <h4 style={{color:'#000'}}>Proximité et Suivi</h4>
                </InView>
              </div>


              <div className='flex gap5 row wrap hcenter vcenter'>
              <InView className="slide-left card light flex col vcenter" style={{width:'300px', height:'200px'}}>
                  <h4 style={{color:'GrayText'}}>1.3</h4>
                  <h4 style={{color:'#000'}}>Methode et Organisation</h4>
                </InView>
                <InView className="slide-right card dark flex col ">
                  <p>Des stratégies opérationnelles optimisées pour vous garantir l'allocation 
                  optimale des ressources</p>
                  <p>Un processus managériale contractuel et perfectionniste, pensé pour 
                  l'exigence et les économies d'échelle</p>
                  <p>Une organisation interne et culture orientée innovation pour vous 
                  proposez les meilleurs outils et les meilleurs prix</p>
                </InView>
              </div>
              <InView className="fade-in">
                <blockquote>
                  Et parceque la confiance se mérite, toutes nos préstations récurrentes 
                  incluent une période d'essai de trois mois !
                </blockquote>
              </InView>
          </section>

          {/* <Proposal id="section4" ref={proposalRef} className="dark-section proposal dark-bg"/> */}

          {/* <section id="sectionZ" className=" bg-parallax" style={{backgroundImage: `url(${HeroBg5})`}}>

          </section> */}
          {/* <section id="section5" ref={showCaseRef} 
          className="dark-section show-case flex col gap10 dark-bg">
            <InView className="fade-in header-1 flex col">
              <div className="container">
                <h2>Votre <br /> Histoire</h2>
                <h4>Grandir <span className='fnt-blue'>ensemble</span></h4>
              </div>
            </InView>

            <div className="cards-2 flex row wrap hcenter"
            style={{maxWidth: "3000px"}}>
              <InView className='card ' 
              style={{backgroundImage: `url(${ShowCaseImg1})`} }>
                <a href="#" className=" flex col vend">
                  <h5>Optimisez l'entretien de vos locaux avec Alvy</h5>
                </a>
              </InView>
              <InView className='card' 
              style={{backgroundImage: `url(${ShowCaseImg2})`} }>
                <a href="#" className=" flex col vend">
                  <h5>Optimisez l'entretien de vos locaux avec Alvy</h5>
                </a>
              </InView>           
              <InView className='card' 
              style={{backgroundImage: `url(${ShowCaseImg3})`} }>
                <a href="#" className=" flex col vend">
                  <h5>Optimisez l'entretien de vos locaux avec Alvy</h5>
                </a>
              </InView>            
              <InView className='card' 
              style={{backgroundImage: `url(${ShowCaseImg4})`} }>
                <a href="#" className=" flex col vend">
                  <h5>Optimisez l'entretien de vos locaux avec Alvy</h5>
                </a>
              </InView>   
              <InView className='card' 
              style={{backgroundImage: `url(${ShowCaseImg1})`} }>
                <a href="#" className=" flex col vend">
                  <h5>Optimisez l'entretien de vos locaux avec Alvy</h5>
                </a>
              </InView>   
              <InView className='card' 
              style={{backgroundImage: `url(${ShowCaseImg1})`} }>
                <a href="#" className=" flex col vend">
                  <h5>Optimisez l'entretien de vos locaux avec Alvy</h5>
                </a>
              </InView> 
            </div>
          </section> */}
{/* 
          <section style={{padding:'100px 50px', border:'1px solid black'}}>
            <ArticlesList />
          </section> */}


          <section id="section5" ref={showCaseRef} className='dark-section flex col hcenter' 
            style={{padding:'100px 5vw', backgroundColor:'#161719'}}>
            <div className="flex row wrap hsb vend gap2" style={{marginBottom:'50px', width:'100%'}}>
              <div className='flex col' >
                <h2 style={{color: '#ffffff'}}>Grandir Ensemble</h2>
                <h4 style={{color: '#ffffff'}}>Décrouvrez ce que nous pourrions réaliser</h4>
              </div>
              <div>
                <a href="/blog" className='fnt-1 flex row hcenter vcenter gap1' style={{color: '#ffffff'}}>
                  Toutes nos actualités <ArrowRight fill='#007bff'/>
                </a>
              </div>
            </div>
            <ArticleCards />
          </section>

          <section id='section6' ref={teamRef}
          className='meet-the-team1 flex row hcenter wrap-reverse '>

            {/* <InView className="header flex col vsb">
              <div className="container flex col gap3">
                <h2>Rencontrez le <span className="fnt-blue">coeur </span> battant d'ALVY </h2>
                <h5>Ensemble, repoussons les limites pour transformer les défis en opportunités.</h5>
              </div>
              <div className="btns">
                <a href="#" className='btn-1 primary'>Alvy Team</a>
              </div>  
            </InView>
            <div className='cards-1 br-m bs-3'>
                <img src={Headshot1} alt="" className='card light'/>
                <img src={Headshot2} alt="" className='card light'/>
                <img src={Headshot3} alt="" className='card light'/>
                <img src={Headshot4} alt="" className='card light'/>
                <img src={Headshot5} alt="" className='card light'/>
                <img src={Headshot6} alt="" className='card light'/>
            </div>         */}
          </section>
          
          {/* <section id="section6" ref={teamRef} className="team center col">
            <InView className="header">
              <h3>Rencontrez <span className="fnt-blue">le coeur battant </span>d'ALVY </h3>
              <h5>Ensemble, repoussons les limites pour transformer les défis en opportunités.</h5>
            </InView>

            <div className="cards center">
              <InView className='card card1'>
                <div className="card-content">
                  <img src={Headshot1} alt="" />
                  <div className='name'>Dorian BONNET</div>
                  <div className="job">Directeur Général - CEO</div>
                  <div className='social-icons'>
                    <TwitterIcon className='social-icon'/>
                    <LinkedInIcon className='social-icon'/>
                  </div>
                </div>
              </InView>

              <InView className='card card1'>
                <div className="card-content">
                  <img src={Headshot2} alt="" />
                  <div className='name'>Rabab NORI</div>
                  <div className="job">Directrice des Opérations - COO</div>
                  <div className='social-icons'>
                    <TwitterIcon className='social-icon'/>
                    <LinkedInIcon className='social-icon'/>
                  </div>
                </div>
              </InView>

              <InView className='card card1'>
                <div className="card-content">
                  <img src={Headshot3} alt="" />
                  <div className='name'>Sabrina Fetaheich</div>
                  <div className="job">Directrice des Services Techniques</div>
                  <div className='social-icons'>
                    <TwitterIcon className='social-icon'/>
                    <LinkedInIcon className='social-icon'/>
                  </div>
                </div>
              </InView>

              <InView className='card card1'>
                <div className="card-content">
                  <img src={Headshot4} alt="" />
                  <div className='name'>Felix BOLIVARD</div>
                  <div className="job">Responsable des Opérations de Facility Management</div>
                  <div className='social-icons'>
                    <TwitterIcon className='social-icon'/>
                    <LinkedInIcon className='social-icon'/>
                  </div>
                </div>
              </InView>

              <InView className='card card1'>
                <div className="card-content">
                  <img src={Headshot5} alt="" />
                  <div className='name'>Anne LY</div>
                  <div className="job">Chef de Projet Facility Management</div>
                  <div className='social-icons'>
                    <TwitterIcon className='social-icon'/>
                    <LinkedInIcon className='social-icon'/>
                  </div>
                </div>
              </InView>
              <InView className='card card1'>
                <div className="card-content">
                  <img src={Headshot6} alt="" />
                  <div className='name'>Julie Mejaat</div>
                  <div className="job">Responsable Commerciale</div>
                  <div className='social-icons'>
                    <TwitterIcon className='social-icon'/>
                    <LinkedInIcon className='social-icon'/>
                  </div>
                </div>
              </InView>
            </div>


          </section> */}

          <section id="section7" ref={joinusRef}
          className=' flex row wrap hcenter gap4' style={{padding:'200px 5vw' }}>

            <div className=" bg-2  br-m" 
            style={{backgroundImage: `url(${PeopleImg1})`}}>
              <InView className='fade-in opacity-1 flex col vcenter hcenter gap5 br-m' style={{padding:'20px 5vw' }}>
                <h2 style={{color:'#ffffff'}}>Rejoignez-nous</h2>
                <div className="btns">
                  <a href="/about" className='btn-1 primary'>Chez Alvy</a>
                  <a href="/commitment" className='btn-1 primary'>Nos Engagements</a>
                </div>
              </InView>
            </div>

            <div className="box flex col vcenter gap4" style={{maxWidth:'500px'}}>
              <p>Vous cherchez à faire une différence ? Nous aussi ! Nous croyons que nos collaborateurs 
              sont notre plus grande force. Travailler chez Alvy, c'est rejoindre une équipe passionnée et dévouée. 
              Nous valorisons l'innovation, la collaboration et le développement personnel, avec une culture d'entreprise 
              basée sur la diversité, l'inclusion et le respect.</p>
              <p>Des opportunités de croissance et de développement professionnel vous attendent, avec des programmes de 
              formation continue pour soutenir votre carrière. Profitez également d'un environnement de travail flexible et dynamique.</p>
            </div>
          </section>


          {/* <section className='cta flex col vcenter hcenter gap4'
          style={{paddingTop: "5rem", paddingBottom:" 15rem"}}>
            <InView className='header flex col hcenter gap6 txt-center'
            style={{maxWidth: "1000px"}}>
              <h2>Prêt à commencer ?</h2>
              <h5>Transformez vos idées en réalité avec Alvy. Il n'a jamais été aussi simple de 
              commencer. Profitez de solutions sur mesure pour répondre à vos besoins et d'un 
              accompagnement personnalisé à chaque étape. Avec nos outils innovants, démarrez 
              rapidement et efficacement.</h5>
            </InView>
            <div className="btns-container flex row wrap hcenter gap2">
              <div className='btn-start-container br-m'>
                <p>Contactez-nous</p>
                <ContactIcon 
                className={`btn-start complementary infinite pulse left `} 
                fill="#ffffff" href="#"/>
              </div>         
              <div className='btn-start-container br-m'>
                <p>Obtenez votre devis</p>
                <ArrowPlay
                className={`btn-start primary infinite pulse right`} 
                fill="#ffffff" href="#"/>
              </div>
            </div>
          </section> */}

          <CTASection/>
      </div>
    
  );
};

export default Home;

