import React, { useState } from 'react';


const servicesData = [
    {
      category: "Nettoyage des Sols",
      services: [
        { name: "Aspiration - Aspiration des moquettes et tapis", frequency: "Quotidienne" },
        { name: "Lavage - Lavage des sols durs (carrelage, parquet, vinyle)", frequency: "Hebdomadaire" },
        { name: "Polissage - Cirage et polissage des sols en bois ou en marbre", frequency: "Mensuelle" },
        { name: "Shampoing des moquettes et tapis - Nettoyage en profondeur des moquettes et tapis avec shampoing spécialisé", frequency: "Mensuelle" }
      ]
    },
    {
      category: "Dépoussiérage",
      services: [
        { name: "Surfaces de bureaux - Dépoussiérage des meubles, étagères et équipements", frequency: "Quotidienne" },
        { name: "Luminaires et plinthes - Dépoussiérage des luminaires, plinthes et corniches", frequency: "Hebdomadaire" }
      ]
    },
    {
      category: "Nettoyage des Vitres",
      services: [
        { name: "Lavage des vitres - Lavage intérieur et extérieur des vitres", frequency: "Mensuelle" },
        { name: "Nettoyage des encadrements - Nettoyage des cadres de fenêtres et rebords", frequency: "Mensuelle" }
      ]
    },
    {
      category: "Nettoyage des Sanitaires",
      services: [
        { name: "Désinfection des toilettes - Désinfection des toilettes, urinoirs, lavabos et poignées", frequency: "Quotidienne" },
        { name: "Remplacement des consommables - Réapprovisionnement en papier toilette, savon, serviettes", frequency: "Quotidienne" }
      ]
    },
    {
      category: "Nettoyage des Communes",
      services: [
        { name: "Salles de réunion - Nettoyage et désinfection des salles de réunion", frequency: "Quotidienne" },
        { name: "Cuisines et cafétérias - Nettoyage et désinfection des cuisines, cafétérias et zones de pause", frequency: "Quotidienne" }
      ]
    },
    {
      category: "Nettoyage des Équipements",
      services: [
        { name: "Désinfection des équipements informatiques - Nettoyage et désinfection des claviers, téléphones, écrans", frequency: "Hebdomadaire" },
        { name: "Nettoyage des appareils électroménagers - Nettoyage des réfrigérateurs, micro-ondes et autres appareils", frequency: "Hebdomadaire" }
      ]
    },
    {
      category: "Nettoyage Industriel",
      services: [
        { name: "Zones de production - Nettoyage des machines, sols et équipements de production", frequency: "Quotidienne" },
        { name: "Entrepôts - Balayage et lavage des sols des entrepôts", frequency: "Hebdomadaire" },
        { name: "Zones sensibles - Nettoyage des salles blanches et environnements contrôlés", frequency: "Hebdomadaire" }
      ]
    },
    {
      category: "Nettoyage Après Travaux",
      services: [
        { name: "Enlèvement des déchets - Enlèvement des déchets de construction", frequency: "Ponctuelle" },
        { name: "Nettoyage approfondi - Nettoyage des murs, plafonds, plinthes et installations électriques", frequency: "Ponctuelle" }
      ]
    },
    {
      category: "Entretien des Espaces Verts",
      services: [
        { name: "Tonte de pelouse - Coupe régulière de la pelouse", frequency: "Hebdomadaire" },
        { name: "Taille des haies - Taille et modelage des haies et arbustes", frequency: "Mensuelle" },
        { name: "Entretien des parterres - Désherbage et plantation de fleurs et plantes", frequency: "Hebdomadaire" }
      ]
    },
    {
      category: "Désinfection et Hygiène",
      services: [
        { name: "Désinfection des surfaces de contact - Nettoyage et désinfection des poignées, interrupteurs, rampes", frequency: "Quotidienne" },
        { name: "Désinfection de l'air - Utilisation de purificateurs d'air et nébulisateurs", frequency: "Mensuelle" },
        { name: "Nettoyage des textiles - Lavage et désinfection des rideaux, tapis, moquettes", frequency: "Mensuelle" }
      ]
    }
  ];
  
  // Services les plus couramment demandés (exemples donnés pour illustration)
  const commonlyRequestedServices = {
    "0-0": true,  // Aspiration des moquettes et tapis
    "0-1": true,  // Lavage des sols durs
    "3-0": true,  // Désinfection des toilettes
    "4-0": true,  // Nettoyage et désinfection des salles de réunion
    "9-0": true   // Désinfection des surfaces de contact
  };
  
  export const DataTable = ({className=""}) => {
    const [checkedItems, setCheckedItems] = useState(commonlyRequestedServices);
  
    const handleCheckboxChange = (categoryIndex, serviceIndex) => {
      const key = `${categoryIndex}-${serviceIndex}`;
      setCheckedItems(prevState => ({
        ...prevState,
        [key]: !prevState[key]
      }));
    };
  
    return (
      <div className={`data-table ${className}`}>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Catégorie</th>
              <th>Type de Service</th>
              <th>Fréquence</th>
            </tr>
          </thead>
          <tbody>
            {servicesData.map((categoryItem, categoryIndex) => (
              <React.Fragment key={categoryIndex}>
                {categoryItem.services.map((service, serviceIndex) => (
                  <tr key={serviceIndex}>
                    <td>
                      <input
                        type="checkbox"
                        checked={!!checkedItems[`${categoryIndex}-${serviceIndex}`]}
                        onChange={() => handleCheckboxChange(categoryIndex, serviceIndex)}
                      />
                    </td>
                    {serviceIndex === 0 && (
                      <td rowSpan={categoryItem.services.length} className="category-cell">
                        {categoryItem.category}
                      </td>
                    )}
                    <td>{service.name}</td>
                    <td>{service.frequency}</td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const hotelRestaurantData = [
    { zone: "Chambres d'Hôtel", surface: "30 m²/chambre" },
    { zone: "Salles de Bain Privées", surface: "8 m²/salle" },
    { zone: "Couloirs et Zones Communes", surface: "150 m²" },
    { zone: "Hall d'Entrée et Réception", surface: "100 m²" },
    { zone: "Salles de Réunion et Conférences", surface: "50 m²/salle" },
    { zone: "Restaurant", surface: "200 m²" },
    { zone: "Cuisine", surface: "80 m²" },
    { zone: "Bar", surface: "70 m²" },
    { zone: "Espaces de Loisirs (Salle de Sport, Spa)", surface: "100 m²" },
    { zone: "Espaces Extérieurs (Piscine, Jardins)", surface: "300 m²" },
    { zone: "Zones de Service (Buanderie, Stockage)", surface: "50 m²" },
  ];
  
  const salleDeSportData = [
    { zone: "Salle de Fitness", surface: "250 m²" },
    { zone: "Salle de Musculation", surface: "150 m²" },
    { zone: "Studio de Cours Collectifs", surface: "70 m²" },
    { zone: "Vestiaires", surface: "80 m²" },
    { zone: "Douches", surface: "30 m²" },
    { zone: "Sauna/Hammam", surface: "40 m²" },
    { zone: "Accueil et Zone d'Attente", surface: "60 m²" },
    { zone: "Bureau Administratif", surface: "30 m²" },
    { zone: "Zone de Cardio", surface: "150 m²" },
    { zone: "Espaces Extérieurs (Terrains de Sport)", surface: "200 m²" },
    { zone: "Zones de Service (Stockage Équipement, Buanderie)", surface: "50 m²" },
  ];
  
  const espaceCommercialData = [
    { zone: "Zone de Vente", surface: "500 m²" },
    { zone: "Vitrines", surface: "100 m²" },
    { zone: "Caisses et Comptoirs de Paiement", surface: "70 m²" },
    { zone: "Salles de Stockage", surface: "300 m²" },
    { zone: "Bureau Administratif", surface: "40 m²" },
    { zone: "Sanitaires Publics", surface: "30 m²" },
    { zone: "Sanitaires du Personnel", surface: "20 m²" },
    { zone: "Zone de Livraison", surface: "150 m²" },
    { zone: "Espaces Communes (Couloirs, Escaliers)", surface: "200 m²" },
    { zone: "Aires de Repos et Cafétéria", surface: "60 m²" },
    { zone: "Zones de Service (Maintenance, Local Technique)", surface: "50 m²" },
  ];
  const espaceBureauxData = [
    { zone: "Open Space", surface: "300 m²" },
    { zone: "Bureaux Individuels", surface: "200 m²" },
    { zone: "Salles de Réunion", surface: "100 m²" },
    { zone: "Espace d'Accueil", surface: "50 m²" },
    { zone: "Couloirs", surface: "150 m²" },
    { zone: "Bureaux Administratifs", surface: "60 m²" },
    { zone: "Sanitaires", surface: "30 m²" },
    { zone: "Cafétéria", surface: "40 m²" },
    { zone: "Salle de Repos", surface: "50 m²" },
    { zone: "Zone de Stockage", surface: "80 m²" },
    { zone: "Salle Serveurs", surface: "20 m²" },
  ];
  
  const renderTable = (data, title, checkedItems, handleCheckboxChange) => (
    <div className="data-table bs-3">
      <h5>{title}</h5>
      <table>
        <thead>
          <tr>
            <th></th>
            <th>Zone</th>
            <th>Surface</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                <input
                  type="checkbox"
                  checked={!!checkedItems[`${title}-${index}`]}
                  onChange={() => handleCheckboxChange(`${title}-${index}`)}
                />
              </td>
              <td>{item.zone}</td>
              <td>{item.surface}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
  
  export const FacilityDecomposition = () => {
    const initialCheckedItems = {};
    hotelRestaurantData.forEach((_, index) => {
      initialCheckedItems[`Hôtel-Restaurant-${index}`] = true;
    });
    salleDeSportData.forEach((_, index) => {
      initialCheckedItems[`Salle de Sport-${index}`] = true;
    });
    espaceCommercialData.forEach((_, index) => {
      initialCheckedItems[`Espace Commercial-${index}`] = true;
    });
    espaceBureauxData.forEach((_, index) => {
      initialCheckedItems[`Espace de Bureaux-${index}`] = true;
    });
  
    const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  
    const handleCheckboxChange = (key) => {
      setCheckedItems(prevState => ({
        ...prevState,
        [key]: !prevState[key]
      }));
    };
  
    return (
      <div className="tables-decomposition">
        {renderTable(hotelRestaurantData, "Hôtel-Restaurant", checkedItems, handleCheckboxChange)}
        {renderTable(salleDeSportData, "Salle de Sport", checkedItems, handleCheckboxChange)}
        {renderTable(espaceCommercialData, "Espace Commercial", checkedItems, handleCheckboxChange)}
        {renderTable(espaceBureauxData, "Espace de Bureaux", checkedItems, handleCheckboxChange)}
      </div>
    );
  };

// export default TablesDecomposition;
