import apiClient from './apiClient';

// Fonction pour récupérer la liste de tous les services
export const getAllDefaultServices = async () => {
    try {
        const data = await apiClient.get('/default-services', {
            method: 'GET'
        });
        return data;
    } catch (error) {
        console.error('Error fetching all services:', error);
        throw error;
    }
};



// Fonction pour récupérer les fréquences d'un service spécifique
export const getDefaultServicesBySectorAndZone = async (SectorName, ZoneName) => {
    try {
        const data = await apiClient.get(`/default-services/${encodeURIComponent(SectorName)}/${encodeURIComponent(ZoneName)}`, {
            method: 'GET'
        });
        return data;
    } catch (error) {
        console.error(`Error fetching sector ${SectorName} and zone ${ZoneName}:`, error);
        throw error;
    }
};
