import { useState, useEffect } from 'react';
import articlesService from '../services/articlesService';

// Hook pour récupérer tous les articles
export const useArticles = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const data = await articlesService.fetchAllArticles();
        setArticles(data);
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération des articles');
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  return { articles, loading, error };
};

// Hook pour récupérer un article par ID
export const useArticle = (id) => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const data = await articlesService.fetchArticleById(id);
        setArticle(data);
      } catch (err) {
        setError(err.message || 'Erreur lors de la récupération de l\'article');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [id]);

  return { article, loading, error };
};
