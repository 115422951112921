// QuoteForm.js
import React, { useState, useEffect } from 'react';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import { ProgressHeader } from '../Utils';

const QuoteForm = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const [formData, setFormData] = useState({
    sector: '',
    areaSize: 0,
    roomBreakdown: [],
    totalArea: 0,
    sectorDetails: null,
    serviceFrequencies: null,
    allServices: null
  });

  const handleNextPage = (updatedData) => {
    setFormData({ ...formData, ...updatedData });
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handlePageClick = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const handleSubmit = (serviceFrequencies) => {
    console.log('Final data submitted:', formData);
    // Vous pouvez ajouter ici la logique pour envoyer les données au backend
  };

  // Effect for scrolling to the top of the page when navigating between form pages
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [currentPage]);

  return (
    <div className='quote-form flex col hcenter vsb' style={{ position: 'relative', top: '-100px', padding: '150px 5vw 50px 5vw' }}>
      <ProgressHeader
        className="flex row wrap vcenter hcenter"
        currentPage={currentPage}
        onPageClick={handlePageClick}
        pages={['Locaux', 'Surfaces', 'Services', 'Proposition']}
        style={{ gap: '10px' }}
      />

      <div className='flex col gap2' style={{ margin: '1rem', maxWidth: '1400px', paddingTop: '50px' }}>
        {currentPage === 0 && <FirstPage formData={formData} onNext={handleNextPage} />}
        {currentPage === 1 && <SecondPage formData={formData} onBack={handlePreviousPage} onNext={handleNextPage} />}
        {currentPage === 2 && <ThirdPage formData={formData} onBack={handlePreviousPage} onSubmit={handleNextPage} />}
        {currentPage === 3 && <FourthPage formData={formData} onBack={handlePreviousPage} onSubmit={handleSubmit} />}
      </div>
    </div>
  );
};

export default QuoteForm;
