import React, { useState } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner = () => {
  const [analyticsEnabled, setAnalyticsEnabled] = useState(false);

  const handleAcceptCookies = () => {
    // Activer les cookies analytiques
    setAnalyticsEnabled(true);
    // Charger Google Analytics uniquement après acceptation des cookies
    loadGoogleAnalytics();
  };

  const loadGoogleAnalytics = () => {
    // Charger dynamiquement le script Google Analytics
    if (!window.gtag) {
      const script = document.createElement('script');
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-5LQG1GHQC9';
      script.async = true;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      window.gtag = gtag;

      gtag('js', new Date());
      gtag('config', 'G-5LQG1GHQC9');
    }
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accepter"
      declineButtonText="Refuser"
      enableDeclineButton
      cookieName="alvy_cookie_consent"
      style={{ background: "#2B373B", color: "#fff" }}
      buttonStyle={{ background: "#007bff", color: "#fff", fontSize: "14px" }}
      declineButtonStyle={{ background: "gray", color: "#fff", fontSize: "14px" }}
      expires={1}
      onAccept={handleAcceptCookies}
    >
      Nous utilisons des cookies pour améliorer votre expérience.{" "}
      <a href="/privacy-policy" style={{ color: "#fff", textDecoration: "underline" }}>
        Politique de confidentialité
      </a>
    </CookieConsent>
  );
};

export default CookieConsentBanner;
