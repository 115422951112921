

import React from 'react'

function PrivacyPolicy() {
  return (
    <div className='flex col hcenter' style={{margin: '7.5vw 5vw'}}>
        <div className='flex col gap2'  style={{maxWidth: '800px'}}>
            <section>
                <h3>1. Introduction</h3>
                <p style={{margin:'10px 20px'}}>
                    La présente Politique de Protection des Données à Caractère Personnel décrit les pratiques mises en œuvre par Alvy, entreprise de services de nettoyage professionnels, concernant la collecte, l’utilisation, la conservation, la protection et le partage des données personnelles que vous nous fournissez ou que nous collectons lors de votre utilisation de notre site internet <a href="http://www.alvy-services.com">www.alvy-services.com</a> (ci-après le "Site").
                </p>
                <p style={{margin:'10px 20px'}}>
                    Nous accordons une grande importance à la protection de votre vie privée et au respect de vos droits. Cette politique vous informe des droits dont vous disposez et des mesures que nous mettons en place pour protéger vos données personnelles, conformément à la Loi Informatique et Libertés n° 78-17 du 6 janvier 1978 modifiée et au Règlement Général sur la Protection des Données (RGPD) Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016.
                </p>
            </section>

            <section>
                <h3>2. Données Personnelles Collectées</h3>

                <h4>2.1. Types de Données Collectées</h4>
                <p style={{margin:'10px 20px'}}>Dans le cadre de votre interaction avec Alvy, nous pouvons être amenés à collecter différentes catégories de données personnelles, notamment :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><p><strong>Informations d'identité</strong> : nom, prénom, adresse, numéro de téléphone, adresse email, etc.</p></li>
                    <li><strong>Données de connexion</strong> : adresse IP, logs, données de navigation, etc.</li>
                    <li><strong>Données relatives à vos demandes et commandes</strong> : informations sur les services demandés ou commandés, suivi des prestations, historique des commandes, etc.</li>
                    <li><strong>Données de facturation et de paiement</strong> : adresse de facturation, coordonnées bancaires, etc.</li>
                    <li><strong>Données de suivi de la relation client</strong> : échanges via notre service client, feedback, notes et commentaires, etc.</li>
                    <li><strong>Données marketing</strong> : préférences en matière de communication, abonnements à des newsletters, etc.</li>
                    <li><strong>Données nécessaires pour les études marketing et statistiques</strong>.</li>
                </ul>
                <p style={{margin:'10px 20px'}}>Les données marquées d’un astérisque (*) sont obligatoires pour la réalisation des services que vous demandez. Le refus de fournir ces informations peut entraîner l’impossibilité pour Alvy de traiter votre demande ou de vous fournir le service souhaité.</p>

                <h4>2.2. Méthodes de Collecte</h4>
                <p style={{margin:'10px 20px'}}>Vos données personnelles peuvent être collectées de plusieurs manières, notamment :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Directement auprès de vous</strong> : lors de la création de votre compte, de la demande de devis, de la commande de services, de la souscription à une newsletter, etc.</li>
                    <li><strong>Automatiquement</strong> : lors de votre navigation sur notre Site, via des cookies ou autres traceurs.</li>
                    <li><strong>Via des tiers</strong> : partenaires commerciaux, réseaux sociaux, etc.</li>
                </ul>
            </section>

            <section>
                <h3>3. Finalités du Traitement</h3>
                <p style={{margin:'10px 20px'}}>Les données personnelles que nous collectons sont utilisées pour les finalités suivantes :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Gestion de vos demandes</strong> : traitement et suivi de vos demandes d’information, de devis, de commande, etc.</li>
                    <li><strong>Gestion de votre compte client</strong> : création, mise à jour, et suivi de votre compte client sur notre Site.</li>
                    <li><strong>Exécution des prestations</strong> : réalisation et suivi des services de nettoyage commandés.</li>
                    <li><strong>Amélioration de nos services</strong> : réalisation d’études marketing et statistiques, suivi de la qualité de nos prestations.</li>
                    <li><strong>Communication</strong> : envoi de newsletters, d’offres commerciales, et autres communications en lien avec nos services, sous réserve de votre consentement préalable.</li>
                    <li><strong>Respect de nos obligations légales</strong> : gestion des demandes de droits relatifs aux données personnelles, conformité avec la réglementation en vigueur, etc.</li>
                </ul>
            </section>

            <section>
                <h3>4. Destinataires des Données</h3>
                <p style={{margin:'10px 20px'}}>Vos données personnelles sont traitées par les services internes d'Alvy compétents pour répondre aux finalités précitées, notamment les services administratifs, commerciaux, marketing et support.</p>
                <p style={{margin:'10px 20px'}}>Nous pouvons également partager vos données avec :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Nos sous-traitants</strong> : dans le cadre de la réalisation des prestations (hébergement, maintenance, etc.), sous réserve que ces derniers offrent des garanties suffisantes quant à la mise en œuvre de mesures techniques et organisationnelles appropriées pour assurer la protection de vos données personnelles.</li>
                    <li><strong>Autorités administratives ou judiciaires</strong> : pour répondre à nos obligations légales ou protéger nos droits en justice.</li>
                </ul>
            </section>

            <section>
                <h3>5. Durée de Conservation</h3>
                <p style={{margin:'10px 20px'}}>Vos données personnelles sont conservées pour la durée nécessaire à l'accomplissement des finalités pour lesquelles elles ont été collectées, et ce conformément aux dispositions légales en vigueur.</p>
                <p style={{margin:'10px 20px'}}>Les durées de conservation peuvent varier en fonction du type de données et des obligations légales associées. Par exemple :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Données de votre compte client</strong> : conservées pendant la durée de la relation contractuelle, puis archivées pour une durée de 5 ans à des fins probatoires.</li>
                    <li><strong>Données de facturation</strong> : conservées pendant 10 ans conformément aux obligations comptables.</li>
                    <li><strong>Données de prospection commerciale</strong> : conservées pendant 3 ans à compter du dernier contact ou de la fin de la relation commerciale.</li>
                </ul>
            </section>

            <section>
                <h3>6. Sécurité des Données</h3>
                <p style={{margin:'10px 20px'}}>Alvy met en place des mesures de sécurité techniques et organisationnelles appropriées pour protéger vos données personnelles contre la destruction accidentelle ou illicite, la perte, l'altération, la divulgation ou l'accès non autorisé.</p>
                <p style={{margin:'10px 20px'}}>Cependant, aucune méthode de transmission sur Internet ou méthode de stockage électronique n'est totalement sûre. Bien que nous nous efforcions d'utiliser des moyens commercialement acceptables pour protéger vos données personnelles, nous ne pouvons garantir leur sécurité absolue.</p>
            </section>

            <section>
                <h3>7. Vos Droits</h3>
                <p style={{margin:'10px 20px'}}>Conformément à la Loi Informatique et Libertés et au RGPD, vous disposez des droits suivants concernant vos données personnelles :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li><strong>Droit d'accès</strong> : vous pouvez obtenir la confirmation que des données personnelles vous concernant sont traitées par Alvy et en obtenir la copie.</li>
                    <li><strong>Droit de rectification</strong> : vous pouvez demander la correction des données personnelles inexactes ou incomplètes.</li>
                    <li><strong>Droit d'effacement</strong> : vous pouvez demander la suppression de vos données personnelles sous certaines conditions.</li>
                    <li><strong>Droit à la limitation</strong> : vous pouvez demander la limitation du traitement de vos données personnelles dans certains cas.</li>
                    <li><strong>Droit d'opposition</strong> : vous pouvez vous opposer, pour des raisons tenant à votre situation particulière, au traitement de vos données personnelles.</li>
                    <li><strong>Droit à la portabilité</strong> : vous pouvez recevoir les données personnelles que vous nous avez fournies dans un format structuré, couramment utilisé et lisible par machine, et les transmettre à un autre responsable de traitement.</li>
                </ul>

                <h4>Exercice de vos droits</h4>
                <p style={{margin:'10px 20px'}}>Vous pouvez exercer vos droits à tout moment en nous contactant :</p>
                <ul style={{margin:'10px 40px'}}>
                    <li>Par email : <a href="mailto:dpo@alvy-services.com">dpo@alvy-services.com</a></li>
                    <li>Par courrier : Alvy, 60 rue François 1er, 75008 Paris</li>
                </ul>
                <p style={{margin:'10px 20px'}}>Afin de garantir la confidentialité et la protection de vos données personnelles, nous pourrons vous demander de justifier de votre identité avant de traiter votre demande.</p>
            </section>

            <section>
                <h3>8. Cookies et Autres Traceurs</h3>
                <p style={{margin:'10px 20px'}}>Notre Site utilise des cookies et autres traceurs pour améliorer votre expérience de navigation, réaliser des statistiques d’audience, et vous proposer des publicités personnalisées.</p>
                <p style={{margin:'10px 20px'}}>Pour plus d’informations sur l’utilisation des cookies et sur la manière de les paramétrer, nous vous invitons à consulter notre <a href="/cookie-policy">Politique de Cookies</a>.</p>
            </section>

            <section>
                <h3>9. Modifications de la Politique</h3>
                <p style={{margin:'10px 20px'}}>Alvy se réserve le droit de modifier la présente Politique de Protection des Données à Caractère Personnel à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour. Nous vous encourageons à consulter régulièrement cette page pour rester informé(e) de nos pratiques en matière de protection des données personnelles.</p>
            </section>

            <section>
                <h3>10. Contact</h3>
                <p style={{margin:'10px 20px'}}>Pour toute question concernant cette politique ou le traitement de vos données personnelles, vous pouvez contacter notre Délégué à la Protection des Données (DPO) par email à l’adresse suivante : <a href="mailto:dpo@alvy-services.com">dpo@alvy-services.com</a> ou par courrier à l'adresse suivante : Alvy, 60 rue François 1er, 75008 Paris.</p>

                <p style={{margin:'10px 20px'}}>En acceptant cette Politique de Protection des Données à Caractère Personnel, vous reconnaissez avoir pris connaissance des informations qui y sont contenues et acceptez que vos données personnelles soient traitées dans les conditions définies ci-dessus.</p>
            </section>
        </div>
    </div>
  )
}

export default PrivacyPolicy