import { useState, useEffect } from 'react';
import { getAllDefaultServices } from '../services/defaultServicesService';


// Hook pour récupérer la liste de tous les services
export const useAllDefaultServices = () => {
    const [services, setDefaultServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const data = await getAllDefaultServices();
                setDefaultServices(data);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    return { services, loading, error };
};
