import React, { useState } from 'react';
// import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
// import { InfoTooltip } from '../Utils';
import { OpenInNew } from '../Icons';
import { openSurfaceBreakdownWindow } from './openWindows';
import ContactForm from './contactForm';


function FourthPage({ formData, onBack, onSubmit }) {
    // const [contactRequest, setContactRequest] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    
    console.log('formData', formData)

    return (
        <div className='flex col hcenter gap4' style={{ padding: 3, maxWidth: '650px'}}>

            <div className='fnt-1 flex row vcenter gap1 txt-center'>
                <h5>Pour vos locaux : {formData.sector} de {formData.totalArea} m², et 
                pour l'ensemble des services que vous avez selectionné
                <button onClick={
                    () => openSurfaceBreakdownWindow(formData.roomBreakdown, formData.results)
                    } style={{padding: 0, margin: "0 5px"}}>
                    <OpenInNew fill='#007bff'/>
                </button>
                , nous vous proposons : </h5>
            </div>

            <div style={{borderRadius: '4px', boxShadow: '8px 8px 15px #a3b1c6, -8px -8px 15px #efefef'}}>
                <Table sx={{ minWidth: 400, fontSize:'14px' }} aria-label="summary table">
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{fontSize:'12px'}}>Heures {formData.globalFrequency === '1x' ? '' : 'Mensuelles'}</TableCell>
                            <TableCell sx={{fontSize:'12px'}}>
                                {formData.HoursMonthly.toFixed(2)} Heures {formData.globalFrequency === '1x' ? '' : '/ mois'}
                            </TableCell>
                        </TableRow>
                        {/* Ligne "Heures Hebdomadaires", affichée uniquement si globalFrequency n'est pas égal à '1x' */}
                        {formData.globalFrequency !== '1x' && (
                            <TableRow>
                                <TableCell sx={{fontSize:'12px'}}>Heures Hebdomadaires</TableCell>
                                <TableCell sx={{fontSize:'12px'}}>{formData.HoursWeekly.toFixed(0)} Heures / semaine</TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell  sx={{fontSize:'12px'}}>Coût mensuel des produits et du matériel</TableCell>
                            <TableCell sx={{fontSize:'12px'}}>{formData.ConsumableCosts.toFixed(2)} €</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  sx={{fontSize:'12px'}}>Prix HT {formData.globalFrequency === '1x' ? '' : 'Mensuel'}</TableCell>
                            <TableCell  sx={{fontSize:'12px'}}>{(formData.PriceMonthly + formData.FixedCosts).toFixed(2)} € </TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell  sx={{fontSize:'12px'}}>Prix TTC {formData.globalFrequency === '1x' ? '' : 'Mensuel'}</TableCell>
                            <TableCell  sx={{fontSize:'12px'}}>{((formData.PriceMonthly + formData.FixedCosts)*1.2).toFixed(2)} € </TableCell>
                        </TableRow>

                        {formData.globalFrequency !== '1x' && (
                            <TableRow>
                                <TableCell  sx={{fontSize:'12px'}}>Prix Horaire</TableCell>
                                <TableCell  sx={{fontSize:'12px'}}>{formData.PriceHourly.toFixed(2)} € / Heures</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>

            
            {showContactForm ? (
                <ContactForm 
                formData={formData} 
                onClose={() => setShowContactForm(false)} 
                onSubmit={() => setShowContactForm(false)} 
                />
                ) : (
                    <div className='flex row'>
                        <button className='btn-1 tertiary all' onClick={onBack} >Précédent</button>
                        <button className='btn-1 primary all' onClick={() => setShowContactForm(true)} >
                            Recevoir notre proposition commerciale
                        </button>
                    </div>
                    )}
        </div>
    );
}

export default FourthPage;
