import React, { useState, useEffect } from 'react';
import { 
  Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, 
  CircularProgress, Autocomplete 
} from '@mui/material';
import { post, get } from '../../services/apiClient';

function ClientCreator({ open, onClose, client, onClientUpdated }) {
  const [userEmails, setUserEmails] = useState([]);
  const [missionIDs, setMissionIDs] = useState([]);
  const [loading, setLoading] = useState(false); // Indicateur de chargement pour la soumission
  const [error, setError] = useState(''); // Gestion des erreurs
  const [clientData, setClientData] = useState({
    ClientID: client?.ClientID || `C${Date.now().toString().slice(-6)}`,
    CreatedAt: client?.CreatedAt || new Date().toISOString(),
    CompanyName: client?.CompanyName || '',
    Siren: client?.Siren || '',
    Address: client?.Address || '',
    Representatives: client?.Representatives || [],
    Missions: client?.Missions || [],
  });

  useEffect(() => {
    if (userEmails.length === 0) {
      fetchUserEmails();
    }
    if (missionIDs.length === 0) {
      fetchMissionIDs();
    }
  }, [userEmails.length, missionIDs.length]);

  const fetchUserEmails = async () => {
    try {
      const response = await get('/admin/users');
      setUserEmails(response.filter(user => user.Role === 'Client').map(user => user.Email));
    } catch (error) {
      console.error('Erreur lors de la récupération des emails:', error);
    }
  };

  const fetchMissionIDs = async () => {
    try {
      const response = await get('/admin/missions');
      setMissionIDs(response.map(mission => mission.MissionID));
    } catch (error) {
      console.error('Erreur lors de la récupération des missions:', error);
    }
  };

  // Validation des champs essentiels
  const validateForm = () => {
    return clientData.CompanyName !== '' && clientData.Siren !== '';
  };

  // Gestion des changements dans les inputs
  const handleChange = (e) => {
    const { name, value } = e.target;
    setClientData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Gestion des changements pour les représentants dans l'Autocomplete
  const handleRepresentativesChange = (event, value) => {
    setClientData(prevState => ({
      ...prevState,
      Representatives: value // Mise à jour directe avec les valeurs sélectionnées
    }));
  };

  // Gestion des changements pour les missions dans l'Autocomplete
  const handleMissionsChange = (event, value) => {
    setClientData(prevState => ({
      ...prevState,
      Missions: value // Mise à jour directe avec les valeurs sélectionnées
    }));
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async () => {
    if (!validateForm()) {
      setError('Please fill in all required fields.');
      return;
    }

    setLoading(true); // Activer l'indicateur de chargement
    const endpoint = client ? '/admin/update-client' : '/admin/create-client';

    try {
      await post(endpoint, clientData);
      onClientUpdated();
      onClose();
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du client:', error);
      alert('Erreur lors de l\'enregistrement du client');
    } finally {
      setLoading(false); // Désactiver l'indicateur de chargement
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{client ? 'Edit Client' : 'Create Client'}</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Company Name"
          name="CompanyName"
          fullWidth
          value={clientData.CompanyName}
          onChange={handleChange}
          error={clientData.CompanyName === ''}
          helperText={clientData.CompanyName === '' ? 'Required' : ''}
        />
        <TextField
          margin="dense"
          label="Siren"
          name="Siren"
          fullWidth
          value={clientData.Siren}
          onChange={handleChange}
          error={clientData.Siren === ''}
          helperText={clientData.Siren === '' ? 'Required' : ''}
        />
        <TextField
          margin="dense"
          label="Address"
          name="Address"
          fullWidth
          value={clientData.Address}
          onChange={handleChange}
        />

        {/* Autocomplete pour les représentants */}
        <Autocomplete
          multiple
          options={userEmails}
          getOptionLabel={(option) => option}
          value={clientData.Representatives}
          onChange={handleRepresentativesChange}
          renderInput={(params) => (
            <TextField {...params} label="Representatives" margin="dense" size="small" />
          )}
        />

        {/* Autocomplete pour les missions */}
        <Autocomplete
          multiple
          options={missionIDs}
          getOptionLabel={(option) => option}
          value={clientData.Missions}
          onChange={handleMissionsChange}
          renderInput={(params) => (
            <TextField {...params} label="Missions" margin="dense" size="small" />
          )}
        />

        {error && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={!validateForm() || loading} // Désactiver tant que le formulaire n'est pas valide ou en cours
        >
          {loading ? <CircularProgress size={24} /> : client ? 'Update Client' : 'Create Client'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ClientCreator;
