// import React from 'react';
import React from 'react';
import { InView } from './Utils';
import { ContactIcon, ArrowPlay } from './Icons';
import { Link } from 'react-router-dom';

export const CTASection = (() => {
  const handleShowQuoteForm = (e) => {
    e.preventDefault(); // Empêche le rechargement de la page
    // setShowForm(true);
    window.open('/quote-form', '_blank'); // Ouvre le formulaire dans un nouvel onglet
  };
  const handleShowContactForm = (e) => {
      e.preventDefault(); // Empêche le rechargement de la page
      // setShowForm(true);
      window.open('/contact-form'); 
  };
  return (
    <section className='cta flex col vcenter hcenter gap4' style={{margin:'100px 5vw'}}>
      <InView className='header flex col hcenter gap6 txt-center'
      style={{maxWidth: "600px"}}>
        <h3>Prêt à commencer ?</h3>
        <h5>Transformez vos idées en réalité avec Alvy. Profitez de solutions sur mesure pour répondre à vos 
        besoins et d'un accompagnement personnalisé à chaque étape. Avec nos outils innovants, démarrez 
        rapidement et efficacement.</h5>
      </InView>

      <div className="flex row wrap hcenter gap2" style={{marginTop:"40px"}} >
          <div className='btn-start-container br-m' style={{width:'220px'}}>
              <p>Contactez-nous</p>

              <Link to="/contact-form" > 
              <div 
              className={`btn-start complementary pulse bs-3 `} 
              onClick={handleShowContactForm}>
                  <ContactIcon />
              </div>
              </Link>

          </div>         
          <div className='btn-start-container br-m'>
              <p>Obtenez votre devis</p>
              <Link to="/quote-form" > 
              <div className={`btn-start primary pulse bs-3 `} 
                  onClick={handleShowQuoteForm}>
                  <ArrowPlay />
              </div>
              </Link>
          </div>
      </div>


    </section>
  );
});

export default CTASection;


