import React, { useState, useEffect } from 'react';
import { get } from '../../services/apiClient';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, TextField, IconButton, Typography, Tooltip, Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import MissionCreator from '../users/MissionCreator'; 
import MissionSummary from './MissionSummary';  // Importing the new MissionSummary component

function MissionsTable() {
  const [missions, setMissions] = useState([]);
  const [selectedMission, setSelectedMission] = useState(null);
  const [filter, setFilter] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortColumn, setSortColumn] = useState('MissionID');
  const [open, setOpen] = useState(false);

  // Fetch missions from API
  const fetchMissions = async () => {
    try {
      const response = await get('/admin/missions');
      setMissions(response);
    } catch (error) {
      console.error('Error fetching missions:', error);
    }
  };

  useEffect(() => {
    fetchMissions();
  }, []);

  // Handle sorting when column header is clicked
  const handleSort = (column) => {
    const isAsc = sortColumn === column && sortOrder === 'asc';
    setSortOrder(isAsc ? 'desc' : 'asc');
    setSortColumn(column);
  };

  // Open modal for creating/editing mission
  const handleOpen = (mission = null) => {
    setSelectedMission(mission);
    setOpen(true);
  };

  // Copy item to clipboard
  const handleCopy = (name, item) => {
    navigator.clipboard.writeText(item);
    alert(`${name} copied to clipboard`);
  };

  return (
    <div className='flex col gap2'>
      <div className="flex col bs-3 br-l" style={{ padding: '20px', width: '1100px' }}>
        {/* Header with Add New Mission and Refresh Icons */}
        <div className="flex row hsb vcenter">
          <Typography variant="h4">Missions</Typography>

          <div className="flex row gap1 vcenter">
            {/* Filter input */}
            <TextField
              label="ClientID, MissionID, Address"
              variant="outlined"
              size="small"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              sx={{ width: '300px' }}
            />
            {/* Bouton "New Mission" sous forme d'icône */}
            <Tooltip title="New Mission">
              <IconButton color="primary" onClick={() => handleOpen()}>
                <AddIcon />
              </IconButton>
            </Tooltip>

            {/* Bouton de rafraîchissement */}
            <Tooltip title="Refresh">
              <IconButton color="primary" onClick={fetchMissions}>
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>

        {/* Table displaying filtered and sorted missions */}
        <TableContainer className="scrollbar-1" component={Paper} elevation={0} 
          sx={{ maxHeight: 300, backgroundColor: '#efefef' }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#efefef' }}>
                  <Button onClick={() => handleSort('MissionID')}>Mission ID</Button>
                </TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Address</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Client ID</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Quote ID</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Planning ID</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Created At</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Representatives</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Managers</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Agents</TableCell>
                <TableCell sx={{ backgroundColor: '#efefef' }}>Edit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {missions
                .filter(mission => 
                  mission.MissionID.includes(filter) ||
                  mission.Address.includes(filter) ||
                  mission.ClientID.includes(filter) ||
                  mission.QuoteID.includes(filter) ||
                  mission.PlanningID.includes(filter)
                )
                .map(mission => (
                  <TableRow hover
                    key={mission.MissionID} 
                    onClick={() => setSelectedMission(mission)}
                    selected={selectedMission && selectedMission.MissionID === mission.MissionID}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <div className="flex row">
                        {mission.MissionID}
                        <IconButton onClick={() => handleCopy('MissionID', mission.MissionID)} sx={{ margin: '0px 5px', padding: 0 }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>{mission.Address}</TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <div className="flex row">
                        {mission.ClientID}
                        <IconButton onClick={() => handleCopy('ClientID', mission.ClientID)} sx={{ margin: '0px 5px', padding: 0 }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <div className="flex row">
                        {mission.QuoteID}
                        <IconButton onClick={() => handleCopy('QuoteID', mission.QuoteID)} sx={{ margin: '0px 5px', padding: 0 }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: 'top' }}>
                      <div className="flex row">
                        {mission.PlanningID}
                        <IconButton onClick={() => handleCopy('PlanningID', mission.PlanningID)} sx={{ margin: '0px 5px', padding: 0 }}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </TableCell>                                   
                    <TableCell sx={{ verticalAlign: 'top' }}>{new Date(mission.CreatedAt).toLocaleDateString()}</TableCell>

                    <TableCell sx={{ verticalAlign: 'top', maxWidth: '100px' }}>
                      <div className='flex col hcenter gap1' style={{ maxWidth: '100px' }}>
                          {mission.Representatives?.length > 0 
                          ? mission.Representatives.map((rep, index) => (
                              <div key={index} 
                                onClick={() => handleCopy(rep)}
                                  style={{
                                      cursor:'pointer',
                                      padding: '2px 4px', 
                                      backgroundColor:"lightgray", 
                                      color:'GrayText', 
                                      borderRadius:'5px', 
                                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                      fontSize:'8px',
                                      maxWidth: '100px',           // Limite la largeur
                                      whiteSpace: 'nowrap',        // Empêche le retour à la ligne
                                      overflow: 'hidden',          // Masque le texte qui déborde
                                      textOverflow: 'ellipsis'     // Ajoute des points de suspension si le texte déborde
                                  }}> 
                                  {rep}
                              </div>
                              ))
                          : '--'}
                      </div>
                    </TableCell>





                    <TableCell sx={{ verticalAlign: 'top', maxWidth: '100px' }}>
                      <div className='flex col hcenter gap1' style={{ maxWidth: '100px' }}>
                          {mission.Managers?.length > 0 
                          ? mission.Managers.map((rep, index) => (
                              <div key={index} 
                                onClick={() => handleCopy(rep)}
                                  style={{
                                      cursor:'pointer',
                                      padding: '2px 4px', 
                                      backgroundColor:"lightgray", 
                                      color:'GrayText', 
                                      borderRadius:'5px', 
                                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                      fontSize:'8px',
                                      maxWidth: '100px',           // Limite la largeur
                                      whiteSpace: 'nowrap',        // Empêche le retour à la ligne
                                      overflow: 'hidden',          // Masque le texte qui déborde
                                      textOverflow: 'ellipsis'     // Ajoute des points de suspension si le texte déborde
                                  }}> 
                                  {rep}
                              </div>
                              ))
                          : '--'}
                      </div>
                    </TableCell>


                    <TableCell sx={{ verticalAlign: 'top', maxWidth: '100px' }}>
                      <div className='flex col hcenter gap1' style={{ maxWidth: '100px' }}>
                          {mission.Agents?.length > 0 
                          ? mission.Agents.map((rep, index) => (
                              <div key={index} 
                                onClick={() => handleCopy(rep)}
                                  style={{
                                      cursor:'pointer',
                                      padding: '2px 4px', 
                                      backgroundColor:"lightgray", 
                                      color:'GrayText', 
                                      borderRadius:'5px', 
                                      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
                                      fontSize:'8px',
                                      maxWidth: '100px',           // Limite la largeur
                                      whiteSpace: 'nowrap',        // Empêche le retour à la ligne
                                      overflow: 'hidden',          // Masque le texte qui déborde
                                      textOverflow: 'ellipsis'     // Ajoute des points de suspension si le texte déborde
                                  }}> 
                                  {rep}
                              </div>
                              ))
                          : '--'}
                      </div>
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpen(mission)} sx={{ margin: '0px 5px', padding: 0 }}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>


      </div>

      {/* Mission summary displayed when a mission is selected */}
      {selectedMission && (
        <div  className=''>
          <MissionSummary mission={selectedMission}/>
        </div>
        )}

      {/* MissionCreator Dialog for creating or editing missions */}
      {open && (
        <MissionCreator
          open={open}
          onClose={() => setOpen(false)}
          mission={selectedMission}  // Pass the selected mission for editing, or null for creating
          onMissionUpdated={fetchMissions}  // Refresh the mission list after an update
        />
      )}
    </div>
  );
}

export default MissionsTable;
