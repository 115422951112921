import React from "react";
import UsersTable from "../../tables/UsersTable";
import QuoteTable from "../../tables/QuoteTable";
import PlanningsTable from "../../tables/PlanningsTable";
import ClientsTable from "../../tables/ClientsTable";
import MissionsTable from "../../tables/MissionsTable";
const Clients = () => {
    return (
      <div className='flex col gap4' style={{padding:'10px 10px'}}>
        <h2>Clients </h2>


        <div className="flex col gap2 " style={{margin:'auto'}}>
          <ClientsTable/>
          <UsersTable Role='Client'/>
          <QuoteTable/>
          <PlanningsTable/>
          <MissionsTable/>
        </div>
      </div>
    );
  };
  
  export default Clients;