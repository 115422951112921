import React, { useState } from 'react';
import { TextField, Box, Grid, Paper, Checkbox, FormControlLabel } from '@mui/material';
import { submitContactForm } from '../services/contactService';
import BuildingImg from '../images/building-1.jpg';


function ContactForm() {
  const [contactFormData, setContactFormData] = useState({
    LastName: '',
    FirstName: '',
    JobPosition: '',
    Company: '',
    Address: '',
    Phone: '',
    Email: '',
    Message: '',
    ContactConsent: true, // Initialisé à true pour être coché par défaut
    DataConsent: false,
  });

  const [contactFormErrors, setContactFormErrors] = useState({
    LastName: false,
    FirstName: false,
    Company: false,
    Address: false,
    Email: false,
    JobPosition: false,
  });

  const handleContactFormChange = (e) => {
    const { name, value } = e.target;
    setContactFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCheckboxChange = (e) => {
    setContactFormData((prevData) => ({
      ...prevData,
      ContactConsent: e.target.checked
    }));
  };

  const validateContactForm = () => {
    const errors = {};
    if (!contactFormData.LastName) errors.LastName = true;
    if (!contactFormData.FirstName) errors.FirstName = true;
    if (!contactFormData.Company) errors.Company = true;
    if (!contactFormData.Address) errors.Address = true;
    if (!contactFormData.JobPosition) errors.JobPosition = true;
    if (!contactFormData.Email) errors.Email = true;
    setContactFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

//   const handleContactFormSubmit = async () => {
//     if (validateContactForm()) {
//       const dataToSend = {
//         ...contactFormData,
//         formData
//       };
//       try {
//         const response = await fetch('/api/send-quote', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json'
//           },
//           body: JSON.stringify(dataToSend)
//         });
//         const result = await response.json();
//         console.log('Formulaire envoyé avec succès:', result);
//         onSubmit();  // Fermer le formulaire de contact après soumission réussie
//       } catch (error) {
//         console.error('Erreur lors de l\'envoi du formulaire:', error);
//       }
//     }
//   };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateContactForm()) {
        try {
            const result = await submitContactForm(contactFormData);
            console.log('Formulaire soumis avec succès:', result);
            alert('Votre formulaire a bien été soumis !');
            // onClose(); // Fermer le formulaire après la soumission
        } catch (error) {
            console.error('Erreur lors de la soumission du formulaire:', error);
            alert('Erreur lors de la soumission. Veuillez réessayer.');
        }
    }
  };
//   style={{padding: '200px 5vw'}}
  return (
    <div className='flex col gap4 hcenter' style={{padding: '150px 5vw'}} > 

        <div className="bg-2 " 
        style={{backgroundImage: `url(${BuildingImg})`, width:'100%', height:'700px', position:'absolute', top:'0', zIndex:'-1'
        }}>
            <div style={{width:'100%', height:'100%', backgroundColor:'#000', opacity:'0.5'}}></div>
        </div>
        <div className='flex col gap2' style={{maxWidth:'800px', color:'#fff'}}>
            <h1 style={{color:'#fff', fontSize:'42px'}}>Partagez avec nous votre projet</h1>
            <h5 style={{color:'#fff'}}>Ravis de l'interêt que vous portez envers nos services et c'est avec entousiasme que nous vous accompagnerons.</h5>
            <h5 style={{color:'#fff'}}>Dites-nous en plus sur vous et votre projet pour que nous puissons vous répondre au mieux. </h5>
        </div>
        <Paper disableElevation  sx={{ padding: 4, margin: 'auto', maxWidth: 600, backgroundColor: '#efefef' }} >
            <Box component="form" noValidate sx={{ mt: 2 }}>
                <Grid container spacing={2}>
                    <h4 style={{padding:'10px 20px', width:'100%', marginTop:'20px'}}>Votre demande de contact concerne : </h4>
                        
                    <Grid item xs={12}>
                        <TextField
                        label="Raison sociale de l'entreprise"
                        name="Company"
                        value={contactFormData.Company}
                        onChange={handleContactFormChange}
                        error={contactFormErrors.Company}
                        helperText={contactFormErrors.Company && "Ce champ est obligatoire"}
                        fullWidth
                        required
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                        label="Adresse des locaux concernés"
                        name="Address"
                        value={contactFormData.Address}
                        onChange={handleContactFormChange}
                        error={contactFormErrors.Address}
                        helperText={contactFormErrors.Address && "Ce champ est obligatoire"}
                        fullWidth
                        required
                        />
                    </Grid>
                

                    {/* <h5 style={{width:'100%', marginTop:'20px'}}>Représenté par : </h5><br /> */}
                    <h4 style={{padding:'10px 20px', width:'100%', marginTop:'20px'}}>Représenté par : </h4><br />
                    <Grid item xs={12} sm={6}>
                        <TextField
                        label="Nom"
                        name="LastName"
                        value={contactFormData.LastName}
                        onChange={handleContactFormChange}
                        error={contactFormErrors.LastName}
                        helperText={contactFormErrors.LastName && "Ce champ est obligatoire"}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                    label="Prénom"
                    name="FirstName"
                    value={contactFormData.FirstName}
                    onChange={handleContactFormChange}
                    error={contactFormErrors.FirstName}
                    helperText={contactFormErrors.FirstName && "Ce champ est obligatoire"}
                    fullWidth
                    required
                    />
                </Grid>
                {/* <Grid item xs={12}>
                    <TextField
                    label="Raison sociale "
                    name="Company"
                    value={contactFormData.Company}
                    onChange={handleContactFormChange}
                    error={contactFormErrors.Company}
                    helperText={contactFormErrors.Company && "Ce champ est obligatoire"}
                    fullWidth
                    required
                    />
                </Grid> */}
                <Grid item xs={12}>
                    <TextField
                    label="Fonction"
                    name="JobPosition"
                    value={contactFormData.JobPosition}
                    onChange={handleContactFormChange}
                    error={contactFormErrors.JobPosition}
                    helperText={contactFormErrors.JobPosition && "Ce champ est obligatoire"}
                    fullWidth
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    label="N° de téléphone (optionnel)"
                    name="Phone"
                    value={contactFormData.Phone}
                    onChange={handleContactFormChange}
                    fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    label="Adresse Email "
                    name="Email"
                    type="Email"
                    value={contactFormData.Email}
                    onChange={handleContactFormChange}
                    error={contactFormErrors.Email}
                    helperText={contactFormErrors.Email && "Ce champ est obligatoire"}
                    fullWidth
                    required
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                    label="Message (optionnel)"
                    name="Message"
                    value={contactFormData.Message}
                    onChange={handleContactFormChange}
                    multiline
                    rows={4}
                    fullWidth
                    />
                </Grid>
                </Grid>

                <FormControlLabel
                control={
                    <Checkbox
                    checked={contactFormData.ContactConsent}
                    onChange={handleCheckboxChange}
                    name="ContactConsent"
                    />
                }
                label="Être recontacté"
                sx={{ mt: 2 }}
                />
                <FormControlLabel
                className='flex row vstart'
                control={
                    <Checkbox
                    checked={contactFormData.DataConsent}
                    onChange={(e) => setContactFormData({ ...contactFormData, DataConsent: e.target.checked })}
                    required
                    />
                }
                label={
                    <div>
                        J'accepte que mes données soient collectées et utilisées conformément à la{' '}
                        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            politique de confidentialité
                        </a>. *
                    </div>
                }
                />

                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                    <Button variant="outlined" onClick={onClose}>
                        Revenir
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleContactFormSubmit}>
                        Recevoir
                    </Button>
                </Box> */}


                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
                    <a href='/' className='btn-1 tertiary all' style={{fontSize: '0.8rem'}}>
                    Revenir</a>
                    <button type="submit" className='btn-1 primary all' onClick={handleSubmit}  style={{fontSize: '0.8rem'}}>
                    Soumettre</button>
                </Box>
            </Box>
        </Paper>
    </div>
  );
}

export default ContactForm;
