import React from 'react';
import { Box, Typography, Grid, Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { useArticles } from '../hooks/useArticles';

export const ArticleCards = ({theme='dark'}) => {
    const { articles, loading, error } = useArticles();

    if (loading) return <p>Chargement des articles...</p>;
    if (error) return <p>Erreur : {error}</p>;

    // Trier les articles par date (du plus récent au plus ancien) et prendre les 5 premiers
    const latestArticles = articles
        .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication))
        .slice(0, 5);

    if (latestArticles.length === 0) return <p>Aucun article disponible</p>;

    return (
        <div style={{padding: '0 0', maxWidth: '1000px'}}>

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box
                        component={Link}
                        to={`/articles/${latestArticles[0].id}`}
                        sx={{
                            display: 'block',
                            height: { xs: 'auto', md: '100%' },
                            textDecoration: 'none',
                            color: 'inherit',
                            borderRadius: '8px',
                            overflow: 'hidden',
                            boxShadow: 3,
                        }}
                    >
                        <Box
                            sx={{
                                height: { xs: 'auto', md: '100%' },
                                minHeight: { md: '450px' },
                                position: 'relative',
                                backgroundImage: `url(${latestArticles[0].imageCouverture})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                borderRadius: '8px',
                                '&:hover': {
                                    transform: 'scale(1.02)',
                                    transition: 'transform 0.3s ease-in-out',
                                },
                            }}
                        >
                            <Box 
                                className='flex col vsb'
                                sx={{
                                    bgcolor: 'rgba(0, 0, 0, 0.6)',
                                    height: '100%',
                                    width: '100%', 
                                    color: 'white',
                                    p: 2,
                                    borderRadius: '0 0 8px 8px',

                                }}
                            >
                                <Box sx={{ mt: 1 }}>
                                    {latestArticles[0].tags.split(',').map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag.trim()}
                                            sx={{
                                                mr: 1,
                                                mb: 1,
                                                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                color: 'white',
                                            }}
                                        />
                                    ))}
                                </Box>
                                <Typography variant="h4" component="h3" sx={{color:'#ffffff', height:'50%', fontSize:'36px'}}>
                                    {latestArticles[0].titre}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Grid>

                <Grid container item xs={12} md={6} spacing={2}>
                    {latestArticles.slice(1).map((article) => (
                        <Grid item xs={12} sm={6} key={article.id}>
                            <Box
                                component={Link}
                                to={`/articles/${article.id}`}
                                sx={{
                                    display: 'block',
                                    height: '100%',
                                    textDecoration: 'none',
                                    color: 'inherit',
                                    borderRadius: '8px',
                                    overflow: 'hidden',
                                    boxShadow: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        height: '100%',
                                        position: 'relative',
                                        backgroundImage: `url(${article.imageCouverture})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            transform: 'scale(1.02)',
                                            transition: 'transform 0.3s ease-in-out',
                                        },
                                    }}
                                >
                                    <Box
                                        className='flex col vsb'
                                        sx={{
                                            bgcolor: 'rgba(0, 0, 0, 0.6)',
                                            color: 'white',
                                            height: '100%',
                                            width: '100%', 
                                            p: 2,
                                            borderRadius: '0 0 8px 8px',

                                        }}
                                    >
                                        <Box sx={{ mt: 1 }}>
                                            {article.tags.split(',').map((tag, index) => (
                                                <Chip
                                                    key={index}
                                                    label={tag.trim()}
                                                    sx={{
                                                        mr: 1,
                                                        mb: 1,
                                                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                                                        color: 'white',
                                                    }}
                                                />
                                            ))}
                                        </Box>
                                        <Typography variant="h6" component="h3" sx={{color:'#ffffff'}}>
                                            {article.titre}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </div>

    );
};





export const ArticleCardsCol = ({ currentArticleId, theme = 'dark' }) => {
  const { articles, loading, error } = useArticles();

  if (loading) return <p>Chargement des articles...</p>;
  if (error) return <p>Erreur : {error}</p>;

  // Trier les articles par date (du plus récent au plus ancien) et prendre les 5 premiers
  const latestArticles = articles
    .sort((a, b) => new Date(b.datePublication) - new Date(a.datePublication))
    .slice(0, 7)
    .filter((article) => article.id !== currentArticleId); // Exclure l'article actuellement affiché

  if (latestArticles.length === 0) return <p>Aucun article disponible</p>;

  return (
    <div style={{ padding: '0 0', maxWidth: '1000px' }}>
      <div className='flex row wrap gap2'>
        {latestArticles.map((article) => (
          <Grid item xs={12} key={article.id} sx={{width:'400px'}}>
            <Box
              component={Link}
              to={`/articles/${article.id}`}
              sx={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
                borderRadius: '8px',
                overflow: 'hidden',
                boxShadow: 3,
                height: '175px', // Hauteur égale pour toutes les cartes
                width: '100%',
                backgroundImage: `url(${article.imageCouverture})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.3s ease-in-out',
                },
              }}
            >
              <Box
                className='flex col vsb'
                sx={{
                  bgcolor: 'rgba(0, 0, 0, 0.6)',
                  color: 'white',
                  height: '100%',
                  width: '100%',
                  p: 2,
                  borderRadius: '0 0 8px 8px',
                }}
              >
                <Box sx={{ mt: 1 }}>
                  {article.tags.split(',').map((tag, index) => (
                    <Chip
                      key={index}
                      label={tag.trim()}
                      sx={{
                        mr: 1,
                        mb: 1,
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        color: 'white',
                      }}
                    />
                  ))}
                </Box>
                <Typography variant="h5" component="h3" sx={{ color: '#ffffff' }}>
                  {article.titre}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </div>
    </div>
  );
};


