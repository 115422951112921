import React, { useState, useEffect } from 'react';
import { get } from '../../services/apiClient';
import { Link, Outlet } from 'react-router-dom';
import { Box, Drawer, List, ListItem, ListItemText, IconButton, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const AdminDashboard = () => {
  const [user, setUser] = useState(null); // Stocker les infos utilisateur
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Gérer l'état du Drawer (menu latéral)

  useEffect(() => {
    // Faire une requête au backend pour obtenir les infos utilisateur
    const fetchUserData = async () => {
      try {
        const response = await get('/user/me'); // Requête à l'API pour obtenir les infos utilisateur
        setUser(response); // Stocker les infos utilisateur dans le state
      } catch (error) {
        console.error('Erreur lors de la récupération des informations utilisateur:', error);
      }
    };
    fetchUserData();
  }, []);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen); // Ouvrir/fermer le menu latéral
  };

  return (
    <div style={{ display: 'flex'}}>
      {/* Bouton pour ouvrir le menu latéral */}
      <IconButton onClick={toggleDrawer} sx={{ position: 'fixed', top: 20, left: 20 }}>
        <MenuIcon />
      </IconButton>

      {/* Drawer - Menu latéral */}
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={toggleDrawer}
        sx={{ width: 250, flexShrink: 0 }}
        variant="temporary"
      >
        <Box sx={{ width: 250, padding: '20px' }}>
          {user && (
            <div>
              <Typography variant="h6">{user.firstName} {user.lastName}</Typography>
              <Typography variant="body1">{user.email}</Typography>
              <Typography variant="body2">{user.role}</Typography>
            </div>
          )}
          <List>
            <ListItem component={Link} to="home" onClick={toggleDrawer} sx={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary="Home" />
            </ListItem>   
            <ListItem component={Link} to="clients" onClick={toggleDrawer} sx={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary="Clients" />
            </ListItem>
            <ListItem component={Link} to="operations" onClick={toggleDrawer} sx={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary="Operations" />
            </ListItem>

            <ListItem component={Link} to="agents" onClick={toggleDrawer} sx={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary="Agents" />
            </ListItem>
            <ListItem component={Link} to="all-data" onClick={toggleDrawer} sx={{ textDecoration: 'none', color: 'inherit' }}>
              <ListItemText primary="All Data" />
            </ListItem>
          </List>

        </Box>
      </Drawer>

      {/* Contenu principal */}
      <Box sx={{ flexGrow: 1, padding: '40px' }}>
        <Outlet /> {/* Ceci permettra d'afficher les sous-pages */}
      </Box>
    </div>
  );
};

export default AdminDashboard;
