import React from 'react';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

import CTASection from '../components/CTASection';
// import TreeView from '../components/TreeView';
import { DataTable, FacilityDecomposition } from '../components/DataTable';
import { ContactIcon, ArrowPlay } from '../components/Icons';

import { InView, MovingElement } from '../components/Utils';
// import { Proposal } from '../components/Proposal';
import CleaningServicesTable from '../components/CleaningServices';
// import CleaningExecutionTable from '../components/CleaningExecutionTable';
import Napkin3 from '../images/illustrations/napkin-3.svg';
import Napkin4 from '../images/illustrations/napkin-4.svg';



import AGBg from "../images/industrie-agroalimentaire.png";
import CCBg from "../images/centre-commercial.jpeg";
import ESBg from "../images/sante.jpg"
import BUBg from "../images/bureaux.jpg"
import TRBg from "../images/transports.jpg"
import INBg from "../images/industries.jpeg"
import HRBg from "../images/hotel-restaurant.jpg"
import EVBg from "../images/events.jpg"


// import ChatImg from '../images/chat.jpg';
// import ClientImg from '../images/relation-client.jpg';
import FormationBird from '../images/formation-bird-1.jpg';
import Paris from '../images/paris-1.jpg';
const Services = () => {
    const { ref: Ref3, inView: Ref3Visible } = useInView();
    const { ref: Ref4, inView: Ref4Visible } = useInView();
    const { ref: Ref5, inView: Ref5Visible } = useInView();
    const { ref: Ref6, inView: Ref6Visible } = useInView();
    const { ref: Ref7, inView: Ref7Visible } = useInView();
    // const { ref: Ref8, inView: Ref8Visible } = useInView();
    // const { ref: Ref9, inView: Ref9Visible } = useInView();
    // const { ref: showCaseRef, inView: ShowCaseVisible } = useInView();
    // const { ref: teamRef, inView: teamVisible } = useInView();
    // const { ref: joinusRef, inView: joinusVisible } = useInView();
    // const { ref: ctaRef, inView: ctaVisible } = useInView();

    const handleShowQuoteForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        // setShowForm(true);
        window.open('/quote-form', '_blank'); // Ouvre le formulaire dans un nouvel onglet
      };
    const handleShowContactForm = (e) => {
        e.preventDefault(); // Empêche le rechargement de la page
        // setShowForm(true);
        window.open('/contact-form'); 
    };

  return (
    <div className='cleaning' style={{position: "relative", top:"-100px"}}>
        {/* <section id="section1" className='hero'
        style={{
            // background: "radial-gradient(circle, rgba(2,0,36,1) 50%, rgba(0,123,255,1) 75%, rgba(255,255,255,1) 100%)",
            backgroundImage:'',
            backgroundSize: "150%",
            backgroundPosition: "125%",
            paddingTop: "250px",
            paddingBottom: "100px",
            paddingLeft:"10vw"}}>
            
            <div className=' flex col gap5'>
                <h2 className='fnt-white' style={{maxWidth: "600px", letterSpacing: '1px'}}>
                La garantie d'espaces sains et captivants pour votre entreprise</h2>

                <div className='flex row wrap gap1'>
                    <h6 className='box-1'>PROPRETE</h6>
                    <h6 className='box-1'>GARDIENNAGE</h6>
                    <h6 className='box-1'>ESPACES VERTS</h6>
                </div>
            </div>
        </section> */}

        <div className="bg-2 " 
            style={{
                backgroundImage: `url(${Paris})`, 
                width:'100%', height:'30vw', minHeight:'500px',
                position:'relative', top:'0', left:'0', zIndex:'-1',
                backgroundAttachment:'fixed',
                backgroundPosition:'center'
            }}>
            <div style={{width:'100%', height:'100%', backgroundColor:'#000', opacity:'0.75'}}>
            </div>
        </div>
        <div className=' flex col gap5' style={{position:'absolute', top:'10vw', padding:'100px 10vw'}}>
            <h2 className='fnt-white' style={{maxWidth: "600px", letterSpacing: '1px'}}>
                La garantie d'espaces sains et captivants pour votre entreprise
            </h2>

            <div className='flex row wrap gap1'>
                <h6 className='box-1'>PROPRETE</h6>
                <h6 className='box-1'>GARDIENNAGE</h6>
                <h6 className='box-1'>ESPACES VERTS</h6>
            </div>
        </div>
        {/* 
        1. Prestations
        2. Secteurs 
        3. Avantages / Pourquoi nous? / Propositions 
        4. Show Case [#cleaning] 
        5. Témoignages 
        6. CTA section  
        */}


        <section  id="section2" className='our-cleaning-services flex col hcenter'
        style={{maxHeight:"700px", overflow:"hidden", padding:"100px 5vw"}}>
            <div className="header flex col hcenter pad0 gap4"
            style={{maxWidth: "500px"}}>
                <h3 className='txt-center'>Toutes nos solutions du bout des doigts</h3>
                <p className='txt-center fnt-2'>Un cachier des charges précis pour 
                coller au plus juste de vos besoins et flexible pour répondre à l'exigence 
                de votre business et vous apporter agilité</p>




                <div className="flex row hcenter wrap gap2" >
                    <div className='btn-start-container complementary br-m' style={{width:'220px'}}>
                        <p>Contactez-nous</p>
                        <Link to="/contact-form" > 
                        <div 
                        className={`btn-start complementary pulse infinite white left bs-3 ${Ref3Visible ? "visible" : "" } ${Ref4Visible ? "visible" : "" } ${Ref5Visible ? "visible" : "" } ${Ref6Visible ? "visible" : "" } ${Ref7Visible ? "visible" : "" }`} 
                        onClick={handleShowContactForm}>
                            <ContactIcon />
                        </div>
                        </Link>

                    </div>         
                    <div className='btn-start-container primary br-m'>
                        <p>Obtenez votre devis</p>
                        <Link to="/quote-form" > 
                        <div className={`btn-start primary pulse infinite white right bs-3 ${Ref3Visible ? "visible" : "" } ${Ref4Visible ? "visible" : "" } ${Ref5Visible ? "visible" : "" } ${Ref6Visible ? "visible" : "" } ${Ref7Visible ? "visible" : "" }`} 
                            onClick={handleShowQuoteForm}>
                            <ArrowPlay />
                        </div>
                        </Link>
                    </div>
                </div>

            </div>

            <div className='container bs-3 br-m'
            style={{marginTop: "5rem"}}>
                <DataTable className='mask'/>
            </div>
            

        </section>

        {/* <section id="section3" ref={Ref3} className='for-your-business'>
        </section> */}

        <section id="section3" ref={Ref3} className='by-sector flex row wrap hcenter vcenter gap2 bs'
        style={{padding:'100px 5vw'}}>
            <div className="header" style={{maxWidth: "500px"}}>
                <h3>Dédiés à votre industrie,<br /> à votre métier</h3>
                <h6>Nos experts métiers sectorisés et nos agents spécialisés
                sauront répondre aux spécificité de vos activités.</h6>
                <h5>Relevons <span className='fnt-blue' style={{fontSize:'16px'}}>ensemble</span> vos defis </h5>
            </div>

            <div className="_cards flex row wrap hcenter vcenter gap1"
            style={{maxWidth:"450px"}}>
                <div className='_card br-m  bg-2'  style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${AGBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Agro-alimentaire</p>
                </div>
                <div className='_card br-m  bg-2' style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${CCBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Surface Commerciale</p>
                </div>
                <div className='_card br-m  bg-2' style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${ESBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Etablissement de Santé</p>
                </div>
                <div className='_card br-m  bg-2' style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${BUBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Bureaux, Tertiare, Sièges Sociaux</p>
                </div>
                <div className='_card br-m  bg-2'  style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${TRBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Transport</p>
                </div>
                <div className='_card br-m  bg-2'  style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${INBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Industrie</p>
                </div>
                <div className='_card br-m  bg-2' style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${HRBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Hotelerie et Restauration</p>
                </div>
                <div className='_card br-m  bg-2' style={{ 
                    width:"100px", height: "150px",
                    backgroundImage: `url(${EVBg})`}}>
                    <p className='txt-center fnt-white opacity-2 pad2 br-m bs-3 flex col hcenter vcenter'>
                    Evénementiel</p>
                </div>
            </div>
        </section>

        <section id='section4' ref={Ref4} className='flex col hcenter'
            style={{padding: "10rem 2rem",
            width:"100%", overflow:"hidden"}}>
            
            <div className='flex col '
            style={{width:"80vw", overflow:"hidden"}}>
                <div className='flex col hend'>
                    <div className='flex col hstart gap1 pad2'>
                        <h3>L'innovation à votre service</h3>
                        <h5>Quand la <span className="fnt-blue" style={{fontSize:'16px'}}>simplicité</span> et la 
                        <span style={{fontSize:'16px'}} className="fnt-blue"> flexibilité </span> vont de pair.</h5>
                    </div>
                </div>
                <InView className='slide-right'
                style={{paddingLeft:"10vw"}}>
                    <FacilityDecomposition/>
                </InView>
                <div className='flex col hstart vend'
                style={{paddingTop:"15rem"}}>
                    <div className='flex col gap2' style={{maxWidth:"500px"}}>
                        <h5>Nous developpons pour vous les meilleurs outils pour gérer vos prestations. 
                        Conçu pour des prises de décisions éclairé et rapide.</h5>
                        <h4>Nous vous proposerons toujours ce que nous pouvons faire de mieux</h4>
                    </div>
                </div>

            </div>
            <div style={{width: "1200px"}}> 
                <MovingElement className="">
                    <CleaningServicesTable/>
                </MovingElement>
            </div>

        </section>

        

        {/* <section id="section4" ref={Ref4} className='section4 dark-section'>
            <FacilityDecomposition/>
            <div className='container'>
                <InView className='header'>
                    <h5>L'innovation à votre service</h5>
                    <p>Quand la <span className="fnt-blue">simplicité</span> et la <span className="fnt-blue">flexibilité </span> 
                    vont de pair <br />
                    Nous developpons pour vous les meilleurs outils pour gérer vos prestations <br />
                    Conçu pour des prises de décisions éclairé et rapide
                    </p>
                    <p>Nous vous proposerons toujours <span className='fnt-gray'>ce que nous pouvons faire de mieux</span></p>
                </InView>
            </div>
            <InView className="container">
                <CleaningServicesTable/>
            </InView>
            
        </section> */}


        

        {/* <section id="section5" ref={Ref5} className='section5 dark-section'>
            <InView className='header'>
                <h4>Vous n'aurez plus à vous soucier de rien</h4> */}
                {/* <p>Quand la <span className="fnt-blue">proximité</span> rhyme avec <span className="fnt-blue">suivi et qualité</span></p> */}
{/*                 
            </InView>
        </section> */}

        <section id="section5" ref={Ref5} className="flex col gap10 bg-dark-light bs dark-section"
        style={{padding: "200px 5vw", position:"relative",top:"-10rem"}}>
            <div className="flex row hcenter wrap gap4">
                <div className="flex col vcenter hstart gap1"
                style={{maxWidth:"400px"}}>
                    <h4 className='fnt-white'>Connectons nous par tout moyen à chaque instant </h4>
                    <p className='fnt-white'>Faites nous part de vos stratégies et de vos besoins de la plus simple et directe des manières. <br />
                    Echangez avec nos agents et responsables par le moyen qui vous est familier.</p>
                    <h5 className='fnt-white'><span className="fnt-blue" style={{fontSize:'14px'}}>Disponible à chaque instant</span></h5>
                </div>
                {/* <img src={ChatImg} alt="" 
                className='br-m bs'
                style={{width:"400px"}}/> */}

            <img src={Napkin3} alt="" style={{ maxWidth:'400px', margin:'0 0'}}/>
            </div>

            <div className="flex row hcenter wrap-reverse gap4">
                <div className='flex row hcenter' style={{ width:'400px', margin:'0 0'}}>
                    <img src={Napkin4} alt="" />
                </div>
                
                <div className="flex col vcenter hstart gap1"
                style={{maxWidth:"400px"}}>
                    <h4 className='fnt-white'>Des équipes dévouées</h4>
                    <p style={{color:'#fff'}}>De la parfaite mise en place des procédures à la gestion au quotidien du 
                    travail, nos équipes metterons tout en oeuvre pour vous assurer la qualité de service que vous attendez.</p>
                    <p style={{color:'#fff'}}>Notre proposition comprend la gestion intégrale des opérations, la réalisation 
                    de controles hébdomadaires, l'organisation de réunions mensuelles, et notre venue à votre demande.</p>
                    <h5 className='fnt-blue'>Présent à tout moment</h5>
                </div>

            </div>
        </section>
{/* 
        <section id="section6" ref={Ref6} className='section6 dark-section'>
            <InView className='header'>
                <div className='text'>
                    <h5>Connectons nous par tout moyen à chaque instant </h5>
                    <p>Pour les prestations récurrentes, nos responsables de site interviennent 
                    sur place jusqu'a ce que toutes les procédures opérationnelles soient parfaitement
                    mise en place. <br />
                    Puis une fois par semaine et à toute demande de votre part.</p>
                    <p><span className="fnt-blue">Disponible à chaque instant</span>, 24h/24</p>
                </div>
                <div className="img" style={{ 
                    backgroundImage: `url(${ChatImg})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat', 
                    }}>
                </div>
            </InView>
            <InView className='header'>
                <div className="img" style={{ 
                    backgroundImage: `url(${ClientImg})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat', 
                    }}>
                </div>
                <div className='text'>
                    <h5>Une équipe aux petits soins</h5>
                    <p>Pour les prestations récurrentes, nos responsables de site interviennent 
                    sur place jusqu'a ce que toutes les procédures opérationnelles soient parfaitement
                    mise en place.</p>
                    <p>Puis une fois par semaine et à toute demande de votre part</p>
                    <p><span className="fnt-blue">Présent à tout moment</span>, 7j/7</p>
                </div> */}
                
                {/* Screenshot de l'outil de gestion avec focus sur la zone Commentaire
                icons des outils de messagerie : whatsapp, SMS, Appel, Mail,  */}
            {/* </InView>
        </section> */}

        <section id="section6" ref={Ref6} className="flex col hcenter gap10 bg-dark bs dark-section"
        style={{padding: "200px 5vw", position:"relative",top:"-10rem"}}>
            <div className="header flex col hcenter txt-center" 
            style={{maxWidth: "800px"}}>
                <h3 className='fnt-white'>Anticiper vos besoins, alléger votre structure, libérer votre potentiel</h3>
                <h5 className='fnt-white'>Notre défis, vous permettre d'aller plus loin dans les votres</h5>
            </div>

            <div className="cards-155 flex row wrap hcenter gap1"
            style={{maxWidth:"1016px"}}>
                <div className='flex row wrap hcenter gap1'>
                    <div className="card dark flex col vsb"
                    style={{width:"400px", height:'200px'}}>
                        <div>
                            <h5 className='fnt-white'>01</h5>
                            <h4 className="fnt-blue">Rencontre</h4>
                        </div>
                        <ul>
                            <li style={{fontSize:'12px'}} className='fnt-white'>Elaboration immédiate de notre proposition.</li>
                            <li style={{fontSize:'12px'}} className='fnt-white'>Prise de contact et rencontre physique.</li>
                            <li style={{fontSize:'12px'}} className='fnt-white'>Validation de votre devis.</li>
                        </ul>
                    </div>
                    <div className="card dark flex col vsb"
                    style={{width:"400px", height:'200px'}}>
                        <div>
                            <h5 className='fnt-white'>02</h5>
                            <h4 className="fnt-blue">Conception</h4>
                        </div>
                        <ul>
                            <li style={{fontSize:'12px'}} className='fnt-white'>Réalisation du cachier des charges.</li>
                            <li style={{fontSize:'12px'}} className='fnt-white'>Accès et matériel.</li>
                            <li style={{fontSize:'12px'}} className='fnt-white'>Elaboration des procédures et des plannings.</li>
                        </ul>
                    </div>
                </div>

                <div className="card dark flex col vsb"
                    style={{width:"400px", height:'200px'}}>
                    <div>
                        <h5 className='fnt-white'>3.1</h5>
                        <h4 className="fnt-blue">Colaboration - Naturelle</h4>
                    </div>
                    <div>
                        <p className='fnt-white'>Gestion des collaborateurs simple, fluide et immédiate.</p>
                    </div>
                    <div className='flex row wrap gap1'>
                        <p className="box-1">Liste des taches</p>
                        <p className="box-1">Plannings</p>
                    </div>
                </div>       

                <div className="card dark flex col vsb"
                    style={{width:"400px", height:'200px'}}>
                    <div>
                        <h5 className='fnt-white'>3.2</h5>
                        <h4 className="fnt-blue">Colaboration - Facile</h4>
                    </div>
                    <div>
                        <p className='fnt-white'>Une externalisation totale des éléments de ressource humaine.</p>
                    </div>
                    <div className='flex row wrap gap1'>
                        <p className="box-1">Congés et abscence</p>
                        <p className="box-1">Pointage</p>
                    </div>
                </div>                       

                <div className="card dark flex col vsb"
                    style={{width:"400px", height:'200px'}}>
                    <div>
                        <h5 className='fnt-white'>3.3</h5>
                        <h4 className="fnt-blue">Colaboration - Efficace</h4>
                    </div>
                    <div>
                        <p className='fnt-white'> Mise en place de procédures managériale 
                        et opératiennelles qualitatives et quantitatives.</p>
                    </div>
                    <div className='flex row wrap gap1'>
                        <p className="box-1">Suivi</p>
                        <p className="box-1">Controle Qualité</p>
                    </div>
                </div>    
                <div className="card dark flex col vsb"
                    style={{width:"400px", height:'200px'}}>
                    <div>
                        <h5 className='fnt-white'>3.4</h5>
                        <h4 className="fnt-blue">Colaboration - Transparente</h4>
                    </div>
                    <div>
                        <p className='fnt-white'>Notifications et rapports 
                        des excécutions. Facturation détaillée et tracabilité des intrants.</p>
                    </div>
                    <div className='flex row wrap gap1'>
                        <p className="box-1">Reporting</p>
                        <p className="box-1">Tracabilité</p>
                    </div>
                </div> 
            </div>
        </section>

        {/* <section id="section7" ref={Ref7} className='section7 dark-section'> */}
            {/* 
            Notifications, Repporting, enregistrement des passages et feedback par les agents,
            Screenshot de l'outil de gestion avec focus sur :
                - la liste des taches réalisées
                - le pointage  */}
            
            {/* <InView className='header'>
                <h5>Anticiper vos besoins, alléger votre structure, libérer votre potentiel</h5>
                <p>Nos défis, vous permettre d'aller plus loin dans les votres</p>
            </InView>

    
                
            <InView className='process'>
                <div className='row'>
                    <div className="container empty"/>
                    <div className='container'>
                        <div className='title'>
                            <div>01</div>
                            <div className="fnt-blue">Rencontre</div>
                        </div>
                        <p>Elaboration immédiate de votre devis</p>
                        <p>Prise de contact et rencontre physique</p>
                    </div>
                </div>
                <div className='row'>
                    <div className="container empty"/>
                    <div className='container'>
                        <div className='title'>
                            <div>02</div>
                            <div className="fnt-blue">Design</div>
                        </div>
                        <p>Elaboration des procédures</p>
                        <p>Conception des plannings</p>
                    </div>
                </div>
                <div className='row'>
                    <div className="container empty"/>
                    <div className='container'>
                        <div className='title'>
                            <div>03.1</div>
                            <div className="fnt-blue">Collaboration / Naturelle</div>
                        </div>
                        <div className="text">
                            <p>Gestion des collaborateurs simple et fluide</p>
                            <div className="fnt-gray">Liste des taches</div>
                            <div className="fnt-gray">Plannings</div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="container empty"/>
                    <div className='container'>
                        <div className='title'>
                            <div>03.2</div>
                            <div className="fnt-blue">Collaboration / Facile</div>
                        </div>
                        <div className="text">
                            <p>Une délégation parfaite des éléments de ressource humaine</p>
                            <div className="fnt-gray">Congés et abscence</div>
                            <div className="fnt-gray">Pointage</div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className="container empty"/>
                    <div className='container'>
                        <div className='title'>
                            <div>03.3</div>
                            <div className="fnt-blue">Collaboration / Efficace </div>
                        </div>
                        <div className="text">
                            <p>Application d'une organisation et de procédures managériale 
                            à la fois qualitative, quantitative et transparentes</p>
                            <div className="fnt-gray">Suivi</div>
                            <div className="fnt-gray">Controle qualité</div>
                        </div>
                    </div>
                </div>
            </InView>
   
            


            
        </section> */}

        {/* <section id="section8" ref={Ref8} className='section8 dark-section'>
            <InView className="header">
                <h5>Le suivi du travail réalisé n'aura jamais été aussi facile</h5>
                <p>Observez l'exécution des taches en temps réel <br />
                Recevez des rapports détaillés au rythme qui vous conviendra</p>

            </InView>
            <div className="container">
                <CleaningExecutionTable/>
            </div>
            
        </section> */}

        

        <section id="section7" ref={Ref7} className='flex row wrap hcenter vcenter gap4'
        style={{padding:' 100px 5vw'}}>
            <div className="header"
            style={{maxWidth: "400px"}}>
                <h4>Chez Alvy, nous plaçons l'humain au coeur de nos valeurs</h4>
                <p>Parceque nous savons l'importance de la réalisation des aspirations personnelles, 
                nos agents sont formés en continu aux spécificités métier et aux technologies<br /><br />
                Nous assurons la valorisation de nos équipes pour garantir l'exigence que nos clients mérites</p>
                <div className="btns">
                  <a href="/about" className='btn-1 primary'>Chez Alvy</a>
                  <a href="/commitments" className='btn-1 tertiary'>Nos Engagements</a>
                </div>
            </div>

            <img src={FormationBird} className="bg-2 br-m bs" alt="" 
                style={{height:'300px'}}/>
        </section>

        {/* <Proposal id="section5" ref={Ref5} className="dark-section proposal"/> */}

        {/* <section className="why-us"></section>
        <section className='show-case'></section>
        <section className='testimonials'></section> */}

        <CTASection/>
        
    </div>
  )
}

export default Services