import React, { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';

export const InView = ({ children, className = "" , style}) => {
  const { ref, inView } = useInView({
    // triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div ref={ref} className={`${className} ${inView ? 'in-view' : ''}`} style={style}>
      {children}
    </div>
  );
};



export const MovingElement = ({ children }) => {
  const [position, setPosition] = useState(-10); // Position initiale à -10%
  const elementRef = useRef(null);
  const [isInView, setIsInView] = useState(false);

  const handleScroll = () => {
    if (!isInView) return;

    const section = elementRef.current.parentElement;
    const sectionHeight = section.offsetHeight;
    const sectionTop = section.getBoundingClientRect().top;
    const viewportHeight = window.innerHeight;

    // Calcul de la progression du scroll dans la section
    const scrollRatio = Math.min(
      Math.max((viewportHeight - sectionTop) / (viewportHeight + sectionHeight), 0),
      1
    );

    // Calcul de la position entre -10% et +10%
    const newPosition = -10 + scrollRatio * 50;
    setPosition(newPosition);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsInView(entry.isIntersecting);
      },
      { threshold: [0, 1] }
    );

    if (elementRef.current) {
      observer.observe(elementRef.current.parentElement);
    }

    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current.parentElement);
      }
    };
  }, []);

  useEffect(() => {
    if (isInView) {
      window.addEventListener('scroll', handleScroll);
    } else {
      window.removeEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [isInView]);

  return (
    <div
      ref={elementRef}
      style={{
        position: 'relative',
        top: '300px',
        left: `${70 + position*-1}%`,
        transform: 'translate(-50%, -50%)',
        transition: 'left 0.2s ease-out'
        }}
    >
      {children}
    </div>
  );
};




export const InfoToolTip = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div className="info-tooltip-container">
      {/* Icône de point d'interrogation */}
      <span
        className="question-mark"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        ?
      </span>

      {/* Fenêtre d'information */}
      {showTooltip && (
        <div className="tooltip-content">
          {children}
        </div>
      )}
    </div>
  );
};




export function ProgressHeader({ currentPage, onPageClick, pages, className='', style='' }) {
  // const pages = ['Informations de base', 'Décomposition des locaux', 'Services', "Produits", "Résultat"];
  
  return (
    <div className={className} style={style}>
      {pages.map((page, index) => (
        <div 
          className='flex row hcenter vcenter bs-1'
          key={index}
          onClick={() => onPageClick(index)}  // Appelle la fonction onPageClick avec l'index de la page
          style={{
            // height: "40px",
            width: '150px',
            // clipPath: 'polygon(95% 0, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%)',
            marginBottom: '8px',
            padding: '10px 20px',
            borderRadius: '4px',
            backgroundColor: currentPage === index ? '#007bff' : '#efefef',
            color: currentPage === index ? '#fff' : '#000',
            fontWeight: currentPage === index ? 'bold' : 'normal',
            fontSize: '12px',
            cursor: 'pointer', 
            boxShadow:'8px 8px 15px #a3b1c6, -8px -8px 15px #efefef',
          }}
        >
          {page}
        </div>
      ))}
    </div>
  );
}

export function convertFrequencyToMonthly(frequency) {
  const weekPerMonth = 52/12;
  const dayPerMonth = 365/12;
  const frequencyMap = {
      "1 fois par jour": dayPerMonth, // approximating 30 days in a month
      "2 fois par jour": dayPerMonth * 2,
      "3 fois par jour": dayPerMonth * 3,
      "1 fois par semaine": weekPerMonth,
      "2 fois par semaine": weekPerMonth * 2,
      "3 fois par semaine": weekPerMonth * 3,
      "4 fois par semaine": weekPerMonth * 4,
      "5 fois par semaine": weekPerMonth * 5,
      "6 fois par semaine": weekPerMonth * 6,
      "10 fois par semaine": weekPerMonth * 10,
      "1 fois par mois": 1,
      "2 fois par mois": 2,
      "3 fois par mois": 3,
      "1 fois par trimestre": 0.33,  // approximately 1/3 of a month
      "2 fois par trimestre": 0.67,  // approximately 2/3 of a month
      "1 fois par semestre": 0.17,   // approximately 1/6 of a month
      "2 fois par semestre": 0.33,   // approximately 1/3 of a month
      "1 fois par an": 0.083         // approximately 1/12 of a month
  };
  
  return frequencyMap[frequency] || 0;
}


export function Loader({ text="Loadibg..." }) {
  return (
    <div className="loader-container">
      <div className="loadingspinner">
        <div id="square1"></div>
        <div id="square2"></div>
        <div id="square3"></div>
        <div id="square4"></div>
        <div id="square5"></div>
      </div>
      <div>{text}</div>
    </div>
  )
}





export const VideoBackground = ({ videoSources }) => {
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Fonction pour changer de vidéo lorsque la vidéo actuelle se termine
  const handleVideoEnd = () => {
    setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videoSources.length);
  };

  useEffect(() => {
    const videoElement = document.getElementById("background-video");

    if (videoElement) {
      videoElement.play();  // Lancer la vidéo au changement
    }
  }, [currentVideoIndex]);

  return (
    <div className="video-background" style={{  position: 'absolute',
      top: '0',
      left: '0',
      width: '100vw',
      height: '650px',
      overflow: 'hidden'}}>
      <video
        id="background-video"
        key={currentVideoIndex} // Clé pour changer de vidéo à chaque rendu
        src={videoSources[currentVideoIndex]}
        autoPlay
        muted
        playsInline
        loop={false}  // On désactive la boucle car on gère l'enchaînement des vidéos
        onEnded={handleVideoEnd}
        className="video"
        style={{ width: "100%", height: "100%", objectFit: "cover", position: "absolute", top: 0, left: 0, zIndex: -10 }}      />
    </div>
  );
};

