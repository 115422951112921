import React, { useState, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import axios from 'axios';

const GoogleMapComponent = ({ address }) => {
  const [coordinates, setCoordinates] = useState({ lat: 0, lng: 0 });

  // Fonction pour géocoder l'adresse en coordonnées latitude et longitude
  const geocodeAddress = async (address) => {
    const apiKey = 'AIzaSyDXflSpn2-v9MLelPmuxCdd0z4gG7-yo8A'; // Remplace par ta clé API Google
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`
      );
      const { lat, lng } = response.data.results[0].geometry.location;
      setCoordinates({ lat, lng });
    } catch (error) {
      console.error('Erreur lors du géocodage de l’adresse:', error);
    }
  };

  useEffect(() => {
    if (address) {
      geocodeAddress(address);
    }
  }, [address]);

  const mapContainerStyle = {
    height: '100%',
    width: '100%',
  };

  const center = {
    lat: coordinates.lat,
    lng: coordinates.lng,
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDXflSpn2-v9MLelPmuxCdd0z4gG7-yo8A">
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        center={center}
        zoom={14}
      >
        {coordinates && <Marker position={center} />}
      </GoogleMap>
    </LoadScript>
  );
};

export default GoogleMapComponent;
