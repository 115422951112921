


import React from 'react'

function LegalNotice() {
  return (
    <div className='flex col hcenter' style={{margin: '7.5vw 5vw'}}>
      <div className='flex col gap2'  style={{maxWidth: '800px'}}>
        <h2>Mentions Légales</h2>

        <div>
          <p style={{margin:'10px 20px'}}>
              L’ensemble des sites de Alvy relève de la législation française et internationale sur le droit d’auteur 
              et de la propriété intellectuelle.
          </p>
          <p style={{margin:'10px 20px'}}>
              Tous les droits de reproduction sont réservés, y compris pour les documents téléchargeables et les représentations 
              iconographiques et photographiques.
          </p>
          <p style={{margin:'10px 20px'}}>
              La reproduction de tout ou partie de ce site sur quelque support que ce soit quel qu’il soit est interdite sauf 
              autorisation préalable (nous contacter).
          </p>
        </div>


        <div className='flex col '>
          <div>Alvy SAS</div>
          <div>60 rue François 1er 75008, Paris</div>
          <div>Tél : 07 82 70 77 69</div>
          <div>Siren : 931 782 148 R.C.S. Paris</div>
        </div>

      </div>
    </div>
  )
}

export default LegalNotice